import React, {useState} from 'react';
import {
    TitleLine,
    SysContainer,
    FieldGroup,
    InputItem,
    FormBorderb,
    BottomBtnsRight,
    Textbox,
    EditorWrapper,
    Input,
    BtnMd,
    BtnMdSubmit,
    Fz20Bold,
    Select,
} from '@/css/admin/style';
import FormCKEditor from '@/components/FormCKEditor';
import { useCreateFaqMutation } from '@services/faq';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function FaqNewForm() {
    const [category, setCategory] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const navigate = useNavigate();

    const [createFaq] = useCreateFaqMutation();

    const handleCreateFaq = async (e) => {
        e.preventDefault();
        if (!category) {
            toast.error('분류를 선택해주세요.');
            return;
        }
        if (!title) {
            toast.error('제목을 입력해주세요.');
            return;
        }
        if (!content) {
            toast.error('내용을 입력해주세요.');
            return;
        }
        try {
            confirmAlert({
                message: '등록하시겠습니까?',
                buttons: [
                    {
                        label: '확인',
                        onClick: async () => {
                            await createFaq({
                              category: category,
                              title: title,
                              body: content
                            });
                            toast.success('등록되었습니다.');
                            navigate('/faqs');
                        }
                    },
                    {
                        label: '취소',
                    }
                ]
            });
        } catch (error) {
            toast.error('등록에 실패했습니다.');
        }
    }

    const handleCategoryChange = (e) => {
      setCategory(e.target.value);
    }

    return (
        <>
            <SysContainer>
                <form onSubmit={handleCreateFaq}>
                    <Fz20Bold>FAQ</Fz20Bold>
                    <TitleLine />
                    <FieldGroup>                        
                        <InputItem>
                            <span>분류</span>
                            <Select onChange={handleCategoryChange}>
                                <option value={''}>선택</option>
                                <option value={'common'}>공통</option>
                                <option value={'user'}>고객</option>
                                <option value={'designer'}>디자이너</option>
                                <option value={'shop'}>매장</option>
                                <option value={'etc'}>기타</option>
                            </Select>
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>질문</span>
                            <Input type="text" defaultValue={title} placeholder={'질문을 입력해 주세요.'} onChange={e=>setTitle(e.target.value)}/>
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>답변</span>
                            <EditorWrapper>
                                <FormCKEditor
                                    data={content}
                                    handleChange={setContent}
                                />
                            </EditorWrapper>
                        </InputItem>
                    </FieldGroup>
                    <FormBorderb />
                    <BottomBtnsRight>
                        <BtnMd $bg="#fff" $bc="#00BF9D" $col="#00BF9D">
                            목록
                        </BtnMd>
                        <BtnMdSubmit>등록</BtnMdSubmit>
                    </BottomBtnsRight>
                </form>
            </SysContainer>
        </>
    );
}

export default FaqNewForm;
