import React, { useEffect, useState } from 'react';
import { ShortMap } from '@/css/style';
// import { Container as NaverMap, Marker, useNavermaps } from 'react-naver-maps';
import { Container as MapDiv, Marker, NaverMap, useNavermaps } from 'react-naver-maps';

const Map = (props) => {
  const { address_map, isMarker } = props;

  console.log(address_map, isMarker, 'address_map, isMarker')

  const navermaps = useNavermaps();
  const [markerPosition, setMarkerPosition] = useState(null);

  useEffect(() => {
    if (address_map) {
      setMarkerPosition(new navermaps.LatLng(address_map.y, address_map.x));  
    }
  }, [address_map, navermaps]);
  
  return (
    <MapDiv>
      <ShortMap>
        <NaverMap defaultCenter={markerPosition}>
          {markerPosition && (
            <Marker
                position={markerPosition}
                icon={{
                    content:
                        '<div style="background-color: #FF3062; width: 18px;min-width:18px; height: 18px; border:3px solid #fff; border-radius: 50%;"></div>',
                    anchor: new navermaps.Point(10, 10),
                }}
            />
          )}
        </NaverMap>
      </ShortMap>
    </MapDiv>
  );
};

export default Map;
