import { useSelector } from "react-redux"
import { selectUser } from "@redux/slice/authSlice"
import { useLocation, Navigate } from "react-router-dom"

function PrivateRoute(props) {
  const user = useSelector(selectUser)
  const location = useLocation()

  return (
    user ? props.children : <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default PrivateRoute;
