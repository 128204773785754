import React, { useEffect, useState, useRef } from 'react';
import {
  TitleLine,
  SysContainer,
  Input,
  FieldGroup,
  InputItem,
  FormBorderb,
  BottomBtnsRight,
  BtnMd,
  BtnSm,
  BtnMdSubmit,
  LinkBtnSm,
  Fz20Bold,
  Mb30,
  Spacing30,
} from '@/css/admin/style';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateManagerMutation, useGetManagerQuery } from '@services/manager';
import { useCheckRegionQuery } from '@services/region';
import Moment from 'react-moment';

function Manager() {
  const inputRegion = useRef(null);
  const inputTel = useRef(null);

  const [region, setRegion] = useState('');
  const [commissionRate, setCommissionRate] = useState('');
  const [name, setName] = useState('');
  const [nickName, setNickName] = useState('');
  const [password, setPassword] = useState('');
  const [tel, setTel] = useState('');
  const [bank, setBank] = useState({
    name: '',
    account: '',
    holder: ''
  })
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isCheckRegion, setIsCheckRegion] = useState(true);

  const { id } = useParams();

  const navigate = useNavigate();

  const { data, isLoading, error } = useGetManagerQuery(id, { refetchOnMountOrArgChange: true });

  const { data: regionData, isLoading: regionIsLoading, error: regionError } = useCheckRegionQuery(region, { skip: isLoading || !data || !region || isCheckRegion });

  const [ updateManager, { data: updateManagerData, isLoading: updateIsLoading, error: updateError }] = useUpdateManagerMutation();

  useEffect(() => {
    if (data) {
      inputRegion.current.value = data.region?.name;
      setCommissionRate(data.region?.commission_rate || 0);
      setName(data.name);
      setNickName(data.nickname);
      setTel(data.region.tel)
      setBank(data.region.bank || {
        name: '',
        account: '',
        holder: '',
      })
    }
  }, [data]);

  const handleUpdateManager = async (e) => {
    e.preventDefault();

    try {
      if (!inputRegion.current.value) {
        toast.error('대리점명을 입력해주세요.');
        inputRegion.current.focus();
        return;
      }
  
      if (!isCheckRegion) {
        toast.error('대리점명 중복체크를 해주세요.');
        return;
      }

      if (confirmPassword && !password) {
        toast.error('비밀번호를 입력해주세요.');
        return;
      }
  
      if (confirmPassword && password !== confirmPassword) {
        toast.error('비밀번호가 일치하지 않습니다.');
        return;
      }

      if (!tel) {
        toast.error('고객과 소통할 대표번호를 입력해주세요.');
        inputTel.current.focus();
        return;
      }  

      const data = {
        region_name: region?.trim(),
        commission_rate: commissionRate,
        name: name?.trim(),
        nickname: nickName?.trim(),
        tel: tel.trim(),
        bank: bank
      };
   
      if (password) {
        data.password = password;
        data.confirm_password = confirmPassword;
      }

      await updateManager({id, data });

      toast.success('대리점 정보를 업데이트 하였습니다.');

    } catch (e) {
      console.log(e);
      toast.error('대리점 정보 업데이트에 실패하였습니다.');
    }
  }

  useEffect(() => {
    if(regionError?.data?.message) {
      inputRegion.current.focus();
      inputRegion.current.value = '';
      toast.error(regionError?.data?.message);
    } else if(regionData) {
      setIsCheckRegion(true);
      toast.success('사용 가능한 대리점입니다.');
    }
  }, [regionData, regionError]);

  useEffect(() => {
    if(updateError?.data?.message) {
      console.log(updateError?.data?.message)
      toast.error('대리점 정보 업데이트에 실패하였습니다.');
    }
  }, [updateError]);

  const handleCheckRegion = () => {
    if (!inputRegion.current.value) {
      inputRegion.current.focus();
      toast.error('대리점명을 입력해주세요.');
      return;
    } else if (inputRegion.current.value === data.region.name) {
      setIsCheckRegion(true);
      return;
    }
    setRegion(inputRegion.current.value.trim());
  }

  return (
    <>
      <SysContainer>
          <form onSubmit={handleUpdateManager}>
              <Fz20Bold>대리점 정보</Fz20Bold>
              <TitleLine />
              <FieldGroup $mb="6rem">
                <InputItem>
                  <span>대리점명</span>
                  <Input type="text" ref={inputRegion} defaultValue={region} onChange={()=>setIsCheckRegion(false)} disabled={regionIsLoading}/>
                  <BtnSm $bg={isCheckRegion?"#fff":"#00BF9D"} $col={isCheckRegion?"#00BF9D":"#fff"} onClick={handleCheckRegion}>
                    {isCheckRegion?"확인완료":"중복체크"}
                  </BtnSm>
                </InputItem>
                <InputItem>
                  <span>정산비율</span>
                  <Input type="number" defaultValue={commissionRate} onChange={e=>setCommissionRate(e.target.value)} min={0} max={100} step={1} style={{width: '80px'}}/>&nbsp;%
                </InputItem>
              </FieldGroup>
              <Fz20Bold>담당자 정보</Fz20Bold>
              <TitleLine />
              <FieldGroup $mb="3rem">
                  <Mb30>마지막 접속일 : <Moment format="YYYY.MM.DD HH:mm">{data?.last}</Moment></Mb30>
                  <InputItem $wd="100%"/>
                  <InputItem>
                      <span>등록일</span>
                      <Moment format="YYYY.MM.DD HH:mm">{data?.created}</Moment>
                  </InputItem>
                  <InputItem>
                      <span>상태</span>
                      {data?.deleted ? <ColRed>탈퇴</ColRed> : '정상'}
                  </InputItem>
                  <InputItem>
                      <span>이메일(ID)</span>
                      {data?.user_id}
                  </InputItem>
                  <InputItem>
                      <span>휴대폰 번호</span>
                      {data?.phone}
                  </InputItem>
                  <InputItem>
                      <span>성명</span>
                      <Input type="text" defaultValue={name} onChange={e=>setName(e.target.value)} placeholder="이름"/>
                  </InputItem>
                  <InputItem>
                      <span>닉네임</span>
                      <Input type="text" defaultValue={nickName} onChange={e=>setNickName(e.target.value)} placeholder="닉네임"/>
                  </InputItem>
                  <InputItem>
                    <span>대표 번호</span>
                    <Input type="text" ref={inputTel} defaultValue={tel} onChange={e=>setTel(e.target.value)} placeholder="디자이너 페이지에 노출되는 연락번호 입니다."/>
                  </InputItem>
                  <InputItem $wd="100%">
                    <span>은행</span>
                    <Input type="text" defaultValue={bank.name} onChange={e=>setBank({
                      ...bank,
                      name: e.target.value
                    })} placeholder="은행명"/>
                    <Input type="text" defaultValue={bank.account} onChange={e=>setBank({
                      ...bank,
                      account: e.target.value
                    })} placeholder="계좌번호"/>
                    <Input type="text" defaultValue={bank.holder} onChange={e=>setBank({
                      ...bank,
                      holder: e.target.value
                    })} placeholder="예금주명"/>
                  </InputItem>
                  <InputItem>
                      <span>패스워드</span>
                      <Input type="password" onChange={e=>setPassword(e.target.value)}/>
                  </InputItem>
                  <InputItem>
                      <span>패스워드 확인</span>
                      <Input type="password" onChange={e=>setConfirmPassword(e.target.value)}/>
                  </InputItem>
              </FieldGroup>
              <FormBorderb />

              <BottomBtnsRight>
                <BtnMd $bg="#fff" $bc="#00BF9D" $col="#00BF9D" onClick={()=>navigate('/managers')}>
                    목록
                </BtnMd>
                <BtnMdSubmit>저장</BtnMdSubmit>
                {/* <BtnMd $bg="#6946B1">탈퇴</BtnMd> */}
              </BottomBtnsRight>
              <Fz20Bold>디자이너 회원</Fz20Bold>
              <TitleLine />
              <FieldGroup $mb="7.6923rem">
                  <InputItem $wd="30%">
                      <span>운영 회원</span>
                      <Input type="text" value={data?.activeDesignerCount} disabled />
                  </InputItem>
                  <InputItem $wd="30%">
                      <span>승인 대기 회원</span>
                      <Input type="text" value={data?.inactiveDesignerCount} disabled />
                  </InputItem>
                  <Spacing30 />
                  <LinkBtnSm to="/designers">관리 디자이너 전체 보기</LinkBtnSm>
              </FieldGroup>
              <Fz20Bold>관리 매장</Fz20Bold>
              <TitleLine />
              <FieldGroup>
                  <InputItem>
                      <span>관리 매장</span>
                      <Input type="text" value={data?.activeShopCount} disabled />
                  </InputItem>
                  <InputItem>
                      <span>등록중 매장</span>
                      <Input type="text" value={data?.inactiveShopCount} disabled />
                  </InputItem>
                  <Spacing30 />
                  <InputItem>
                  <LinkBtnSm to="/shops" $ml="10px">관리 매장 전체 보기</LinkBtnSm>
                  </InputItem>
              </FieldGroup>
          </form>
      </SysContainer>
    </>
  );
}

export default Manager;
