import { apiService } from "./api";

export const managerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getManagers: builder.query({
      query: ({ page, limit, searchText, searchStatus }) => ({
        url: `/managers?page=${page}&text=${searchText || ''}&status=${searchStatus || ''}&limit=${limit}`,
        method: 'GET',
      })
    }),
    getManager: builder.query({
      query: id => ({
        url: `/managers/${id}`,
        method: 'GET',
      })
    }),
    createManager: builder.mutation({
      query: manager => ({
        url: '/managers',
        method: 'POST',
        body: manager,
      })
    }),
    updateManager: builder.mutation({
      query: ({ id, data }) => ({
        url: `/managers/${id}`,
        method: 'PUT',
        body: data,
      })
    }),
    deleteManager: builder.mutation({
      query: id => ({
        url: `/managers/${id}`,
        method: 'DELETE',
      })
    }),
  }),
});

export const { useGetManagersQuery, useGetManagerQuery, useCreateManagerMutation, useUpdateManagerMutation, useDeleteManagerMutation } = managerApi;