import { useState } from 'react'
import { Link } from 'react-router-dom';
import {
    BtnSm,
    SysHead,
    SquareGreen,
    SquarePink,
    SquareBlack,
    Fz15,
    Nowrap,
} from '../css/admin/style';
import { Gap20, Gap30, FlxMiddle, Fz16Medium } from '../css/style';
import { useSelector } from 'react-redux';
import { useLogoutMutation } from '@services/auth';
import { useDispatch } from 'react-redux';
import { selectUser, setLogout, setLastActivity, selectLastActivity } from '@redux/slice/authSlice';
import { useGetCountQuery } from '@services/dashboard';
import { useEffect } from 'react';

const LOGOUT_TIMEOUT = 1000 * 60 * 10;

export default function Header() {
    const [logout] = useLogoutMutation();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const lastActivity = useSelector(selectLastActivity);
    const [countdown, setCountdown] = useState('');
    const { data, refetch } = useGetCountQuery();

    useEffect(() => {
       refetch();
    }, [refetch]);

    useEffect(() => {
        const timer = setInterval(() => {
          const currentTime = Date.now();
          const remainingTime = LOGOUT_TIMEOUT - (currentTime - lastActivity);
    
          if (remainingTime <= 0) {
            handleLogout();
          } else {
            const minutes = Math.floor(remainingTime / 60000);
            const seconds = Math.floor((remainingTime % 60000) / 1000);
            setCountdown({
                time: `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
                isRed: remainingTime <= 60000, // 1분 이내이면 true
            });
          }
        }, 1000);
    
        return () => {
          clearInterval(timer);
        };
      }, [lastActivity]);

    const handleLogout = async () => {
        try {
            await logout();
            dispatch(setLogout());
        } catch (error) {
            console.log(error);
        }
    };

    const handleExtendLogin = () => {
      dispatch(setLastActivity(Date.now()));
    };

    return (
        <SysHead>
            {/* 왼쪽 */}
            <Nowrap as={FlxMiddle}>
                {user.role === 'ADMIN' ? (
                    <>
                        <SquarePink />
                        <Fz15>User : {data?.userCount || 0}</Fz15>
                        <Gap30 />
                    </>
                ) : null}
                <SquareGreen />
                <Fz15>Designer : {data?.designerCount || 0}</Fz15>
                <Gap30 />
                {user.role === 'ADMIN' ? (
                    <>
                        <SquareGreen />
                        <Fz15>Manager : {data?.managerCount || 0}</Fz15>
                        <Gap30 />
                    </>
                ) : null}
                <SquareBlack />
                <Fz15>Shop : {data?.shopCount || 0}</Fz15>
            </Nowrap>
            {/* //왼쪽 */}
            {/* 오른쪽 */}
            <FlxMiddle>
                <Fz16Medium color="#000" as={Nowrap}>
                    {user.name}님 안녕하세요.
                </Fz16Medium>
                <Gap20 />
                <Fz16Medium color={countdown.isRed ? 'red' : '#000'} as={Nowrap}>
                {countdown.time}
                </Fz16Medium>
                <Gap20 />
                <BtnSm as="a" $col="#757575" $bc="#757575" onClick={handleExtendLogin}>
                연장
                </BtnSm>
                <Gap20 />
                <BtnSm as="a" $col="#757575" $bc="#757575" onClick={() => handleLogout()}>
                    로그아웃
                </BtnSm>
            </FlxMiddle>
            {/* //오른쪽 */}
        </SysHead>
    );
}
