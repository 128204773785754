import React, { useState, useEffect } from 'react';
import Chkbox from '@components/Chkbox';
import {
    Block50,
    SysContainer,
    Fz30Bold,
    Fz15,
    InputShort,
    BtnSearch,
    BtnMd,
    ListTableWrapper,
    BtnTable,
    BottomBtns,
    TxtDivider2,
    FlxMiddleMb20,
    Gap30,
    ListInputs,
    ColGray1,
    ColRed,
    ColPurple,
    ColAccent,
    ChkAndTxt,
    TdBtn,
} from '@/css/admin/style';
import icSearch from '@/assets/img/ic_search.svg';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useGetUsersQuery } from '@services/user';
import ClipLoader from 'react-spinners/ClipLoader';

function UserList() {
    const searchInput = React.createRef();
    const [checkList, setCheckList] = useState([]);

    const [searchText, setSearchText] = useState(null);
    const [searchStatus, setSearchStatus] = useState('');
    const [page, setPage] = useState(1);

    const [pageLimit, setPageLimit] = useState(10);

    const { data, isLoading, error, refetch } = useGetUsersQuery(
        { page, limit: pageLimit, searchText, searchStatus },
        { refetchOnMountOrArgChange: true }
    );

    const handleSearchClick = () => {
        const text = searchInput.current.value;
        setPage(1);
        setSearchText(text);
    };

    const handlePageClick = (e) => {
        const { selected } = e;
        setPage(selected + 1);
    };

    const handleSearchStatus = (status) => {
        setPage(1);
        setSearchStatus(status);
    };

    const handleDelete = async () => {
        if (checkList.length === 0) {
            toast.error('탈퇴할 회원을 선택해주세요.');
            return;
        }

        try {
            // await Promise.all(checkList.map((id) => deleteUser(id)));
            // toast.success('회원을 삭제하였습니다.');
            refetch();
        } catch (e) {
            // toast.error('회원 삭제에 실패하였습니다.');
        }
    };

    return (
        <SysContainer>
            <Fz30Bold $mb="3.077rem">일반회원 관리</Fz30Bold>
            <FlxMiddleMb20>
                <Fz15>상태</Fz15>
                <Gap30 />
                <ChkAndTxt>
                    <Chkbox
                        checked={searchStatus === ''}
                        onChange={() => {
                            handleSearchStatus('');
                        }}
                    />
                    전체
                </ChkAndTxt>
                {/* <ChkAndTxt>
                  <Chkbox checked={searchStatus==='joined'} onChange={()=>handleSearchStatus('joined')}/>
                  정상
              </ChkAndTxt> */}
                <ChkAndTxt>
                    <Chkbox
                        checked={searchStatus === 'leaved'}
                        onChange={() => handleSearchStatus('leaved')}
                    />
                    탈퇴
                </ChkAndTxt>
                {/* <ChkAndTxt>
                  <Chkbox />
                  정지
              </ChkAndTxt> */}
            </FlxMiddleMb20>
            <ListInputs>
                <Fz15>ID & 이름/닉네임</Fz15>
                <InputShort placeholder="아이디 또는 이름/닉네임" ref={searchInput} onKeyUp={
                    (e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                          handleSearchClick();
                        }
                    }}/>
                <BtnSearch onClick={handleSearchClick}>
                    <img src={icSearch} alt="" />
                    검색
                </BtnSearch>
            </ListInputs>
            <Block50>
                <ListTableWrapper>
                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                {/* <th>체크</th> */}
                                <th>상태</th>
                                <th>성명/닉네임</th>
                                <th>ID/E.mail</th>
                                <th>HP</th>
                                <th>가입일</th>
                                <th>접속일</th>
                                <th>편집</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.docs.length ? (
                                data.docs.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {data.totalDocs - (page - 1) * pageLimit - index}
                                            </td>
                                            {/* <td>
                                    {!item.deleted ? <Chkbox
                                        checked={checkList.includes(item.id)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setCheckList([...checkList, item.id]);
                                            } else {
                                                setCheckList(checkList.filter((id) => id !== item.id));
                                            }
                                        }}
                                    /> : null}
                                </td> */}
                                            <td>{item.deleted ? <ColRed>탈퇴</ColRed> : '정상'}</td>
                                            <td>
                                                {item.name}
                                                {item.nickname ? ` / ${item.nickname}` : ''}
                                            </td>
                                            <td>{item.user_id}</td>
                                            <td>{item.phone}</td>
                                            <td>
                                            <Moment format="YYYY.MM.DD HH:mm">{item.created}</Moment>
                                            </td>
                                            <td>
                                            <Moment format="YYYY.MM.DD HH:mm">{item.last}</Moment>
                                            </td>
                                            <TdBtn>
                                                <Link to={`/users/${item.id}/edit`}>
                                                    <BtnTable>편집</BtnTable>
                                                </Link>
                                            </TdBtn>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="8">
                                        {isLoading ? (
                                            <ClipLoader
                                                color={'#00bf9d'}
                                                size={35}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        ) : (
                                            '데이터가 없습니다.'
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </ListTableWrapper>
                {!isLoading && (
                    <ReactPaginate
                        className="paging"
                        breakLabel="..."
                        nextLabel={<span className="paging_end pl30"></span>}
                        onPageChange={handlePageClick}
                        forcePage={page - 1}
                        pageRangeDisplayed={3}
                        pageCount={data?.totalPages}
                        previousLabel={<span className="paging_first pl30"></span>}
                        renderOnZeroPageCount={null}
                        activeLinkClassName="paging_num paging_current"
                        pageLinkClassName="paging_num"
                        breakLinkClassName="paging_num"
                        previousLinkClassName="paging_num"
                        nextLinkClassName="paging_num"
                        breakClassName="paging_break"
                    />
                )}
            </Block50>
            <BottomBtns>
                {/* <BtnMd>신규 등록</BtnMd> */}
                {/* <BtnMd $bg="#F33535" onClick={handleDelete}>선택 탈퇴</BtnMd> */}
            </BottomBtns>
        </SysContainer>
    );
}

export default UserList;
