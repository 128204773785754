import React, { useEffect, useState } from 'react';
import {
    TitleLine,
    SysContainer,
    Input,
    FieldGroup,
    InputItem,
    FormBorderb,
    BottomBtnsRight,
    BtnMd,
    BtnMdSubmit,
    Fz20Bold,
    EditorWrapper,
    UploadFile,
    ColRed,
    PreviewList,
    PhotoItem,
    GridImg,
    BtnDelete
} from '@/css/admin/style';
import icClear from '@/assets/img/ic_clear.svg';
import Chkbox from '@/components/Chkbox';
import FormCKEditor from '@/components/FormCKEditor';
import { useUpdateArchiveMutation, useGetArchiveQuery } from '@services/archive';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import config from '@/config';

function ArchiveEditForm() {
  const [top, setTop] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const [deleteCoverImage, setDeleteCoverImage] = useState(false);
  const [selectedCoverImageFile, setSelectedCoverImageFile] = useState(null);
  const [selectedServerCoverImageFile, setSelectedServerCoverImageFile] = useState(null);

  const navigate = useNavigate();

  const { id } = useParams();

  const { data, isLoading, error } = useGetArchiveQuery(id, { refetchOnMountOrArgChange: true });

  console.log(data)

  const [updateArchive, { isLoading: updateIsLoading, error: updateError }] = useUpdateArchiveMutation();

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setContent(data.body);
      setTop(data.top);
      setSelectedServerCoverImageFile(data.cover_image);
    }
  }, [data]);
  
  const renderMainPhoto = (source) => {
    return source && <PhotoItem>
        <GridImg src={URL.createObjectURL(source)} alt="" key={source.name} />
        <BtnDelete>
          <img src={icClear} alt="삭제" onClick={e=>handleCoverImageDelete()}/>
        </BtnDelete>
      </PhotoItem>
  }

  const renderMainServerPhoto = (file) => {
    return file && <PhotoItem>
        <GridImg src={`${config.API_SERVER_HOST}/files/board/${file.filename}`} alt="" key={file.id} onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = require('@/assets/img/altimg.png');
                            }}/>
        <BtnDelete>
          <img src={icClear} alt="삭제" onClick={e=>{
            setSelectedServerCoverImageFile(null)
            setDeleteCoverImage(true)
          }}/>
        </BtnDelete>
      </PhotoItem>
  }

  const handleMainImageChange = (e) => {
    setSelectedCoverImageFile(e.target.files[0]);
    if(selectedServerCoverImageFile){
      setSelectedServerCoverImageFile(null);
    }
  }
  
  const handleCoverImageDelete = () => {
    setSelectedCoverImageFile(null);
  }

  const handleUpdateArchive = async (e) => {
      e.preventDefault();
      if (!title) {
          toast.error('제목을 입력해주세요.');
          return;
      }
      if (!content) {
          toast.error('내용을 입력해주세요.');
          return;
      }
      try {
          confirmAlert({
              message: '등록하시겠습니까?',
              buttons: [
                  {
                      label: '확인',
                      onClick: async () => {
                          const formData = new FormData();
                          formData.append('title', title);
                          formData.append('body', content);
                          formData.append('file', selectedCoverImageFile);
                          formData.append('delete_cover_image', deleteCoverImage);
                          formData.append('top', top);

                          const result = await updateArchive({id, data: formData});
                          if (result.error) {
                              toast.error('등록에 실패했습니다.');
                          } else {
                            toast.success('수정되었습니다.');
                            navigate('/archives');
                          }                          
                      }
                  },
                  {
                      label: '취소',
                      onClick: () => {}
                  }
              ]
          });
      } catch (err) {
          toast.error('등록에 실패했습니다.');
      }
  }

  const handleList= () => {
      navigate('/archives');
  }

  return (
      <>
        <SysContainer>
          <form onSubmit={handleUpdateArchive}>
                <Fz20Bold>아카이브</Fz20Bold>
                <TitleLine />
                <FieldGroup>
                    <InputItem $wd="100%">
                        <span>공지</span>
                        <Chkbox checked={top} onChange={e=>setTop(!top)}/>
                    </InputItem>
                    <InputItem $wd="100%">
                        <span>제목</span>
                        <Input type="text" defaultValue={title} placeholder={'제목을 입력해 주세요.'} onChange={e=>setTitle(e.target.value)}/>
                    </InputItem>
                    <InputItem $wd="100%">
                        <span>내용</span>
                        <EditorWrapper>
                          <FormCKEditor
                              data={content}
                              handleChange={setContent}
                          />
                        </EditorWrapper>
                    </InputItem>
                    <InputItem $wd="100%" $ai="flex-start">
                        <span>대표 이미지</span>
                        <div>
                            <UploadFile>
                                <div style={{width:'100%'}}>{selectedCoverImageFile?.name}</div>
                                <input type="file" accept="image/png, image/gif, image/jpeg" onChange={handleMainImageChange}/>
                            </UploadFile>
                            <PreviewList>
                            {renderMainPhoto(selectedCoverImageFile)}
                            {renderMainServerPhoto(selectedServerCoverImageFile)}   
                            </PreviewList>
                            <ColRed>
                                가로 720px, 세로 400px (최대 2000px 이하를 권장 드립니다.){' '}
                            </ColRed>
                        </div>
                    </InputItem>
                </FieldGroup>
                <FormBorderb />
                <BottomBtnsRight>
                  <BtnMd $bg="#fff" $bc="#00BF9D" $col="#00BF9D" onClick={()=>handleList()}>
                        목록
                    </BtnMd>
                    <BtnMdSubmit>수정</BtnMdSubmit>
                </BottomBtnsRight>
            </form>
        </SysContainer>
      </>
  );
}

export default ArchiveEditForm;
