import { apiService } from "./api";

export const subscriptionApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getSubscriptions: builder.query({
      query: ({ page, limit, searchText, searchStatus, from_date, to_date }) => ({
        url: `/subscriptions?page=${page}&text=${searchText || ''}&status=${searchStatus || ''}&from_date=${from_date || ''}&to_date=${to_date || ''}&limit=${limit}`,
        method: 'GET',
      })
    }),
    updateSubscription: builder.mutation({
      query: ({ id, memo }) => ({
        url: `/subscriptions/${id}`,
        method: 'Put',
        body: {
          memo
        },
      })
    }),
    updateSubscriptionStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `/subscriptions/${id}/status`,
        method: 'Put',
        body: data,
      })
    }),
  }),
});

export const { useGetSubscriptionsQuery, useUpdateSubscriptionMutation, useUpdateSubscriptionStatusMutation } = subscriptionApi;