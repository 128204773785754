import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  user: null,
  lastActivity: Date.now(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials(state, action) {
      state.token = action.payload.accessToken;
      state.user = action.payload.user;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setLogout(state) {
      state.token = null;
      state.user = null;
    },
    setLastActivity: (state, action) => {
      state.lastActivity = action.payload;
    },
  },
});


export const { setCredentials, setToken, setUser, setLogout, setLastActivity } = authSlice.actions;

export default authSlice.reducer;

export const selectLastActivity = (state) => state.auth.lastActivity;
export const selectUser = (state) => state.auth.user;
export const selectIsLoggedIn = (state) => !!state.auth.token;
export const selectToken = (state) => state.auth.token;