import React, { useEffect, useState } from 'react';
import Chkbox from '@/components/Chkbox';
import {
    Block50,
    SysContainer,
    Fz30Bold,
    Fz15,
    InputShort,
    BtnSearch,
    BtnMd,
    ListTableWrapper,
    BtnTable,
    BottomBtns,
    TxtDivider2,
    FlxMiddleMb20,
    Gap30,
    ListInputs,
    ColRed,
    ChkAndTxt,
    ColAccent,
    TdBtn,
} from '@/css/admin/style';
import icSearch from '@/assets/img/ic_search.svg';
import { Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { useGetShopsQuery } from '@services/shop';
import { useSelector } from 'react-redux';
import { selectUser } from '@redux/slice/authSlice';
function Shops() {
    const searchInput = React.createRef();

    const [checkList, setCheckList] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [searchStatus, setSearchStatus] = useState('');
    const [page, setPage] = useState(1);

    const user = useSelector(selectUser);

    const [pageLimit, setPageLimit] = useState(10);

    const { data, isLoading, error, refetch } = useGetShopsQuery(
        { page, limit: pageLimit, searchText, searchStatus },
        { refetchOnMountOrArgChange: true }
    );

    const handleSearchClick = () => {
        const text = searchInput.current.value;
        setPage(1);
        setSearchText(text);
        refetch();
    };

    const handlePageClick = (e) => {
        const { selected } = e;
        setPage(selected + 1);
    };

    const handleSearchStatus = (status) => {
        setPage(1);
        setSearchStatus(status);
    };

    return (
        <SysContainer>
            <Fz30Bold $mb="3.077rem">매장 관리</Fz30Bold>
            <FlxMiddleMb20>
                <Fz15>상태</Fz15>
                <Gap30 />
                <ChkAndTxt>
                    <Chkbox
                        checked={searchStatus === ''}
                        onChange={() => {
                            handleSearchStatus('');
                        }}
                    />
                    전체
                </ChkAndTxt>
                <ChkAndTxt>
                    <Chkbox checked={searchStatus === 'designer_request'}
                            onChange={() => handleSearchStatus('designer_request')}/>
                    디자이너 등록요청
                </ChkAndTxt>
                <ChkAndTxt>
                    <Chkbox checked={searchStatus === 'manager_request'}
                            onChange={() => handleSearchStatus('manager_request')}/>
                    매니저 등록요청
                </ChkAndTxt>
                <ChkAndTxt>
                    <Chkbox checked={searchStatus === 'review'}
                            onChange={() => handleSearchStatus('review')}/>
                    승인대기
                </ChkAndTxt>
                <ChkAndTxt>
                    <Chkbox checked={searchStatus === 'approved'}
                            onChange={() => handleSearchStatus('approved')}/>
                    승인
                </ChkAndTxt>
                {/* <ChkAndTxt $mr="0">
              <Chkbox />
              폐업
          </ChkAndTxt> */}
                {/* <TxtDivider2 $mg="0 3.846rem" />
          <Fz15>연동상태</Fz15>
          <Gap30 />
          <ChkAndTxt>
            <Chkbox />
            전체
          </ChkAndTxt>
          <ChkAndTxt>
            <Chkbox />
            완료
          </ChkAndTxt>
          <ChkAndTxt>
              <Chkbox />
              확인중
          </ChkAndTxt>
          <ChkAndTxt $mr="0">
            <Chkbox />
            미설정
          </ChkAndTxt> */}
            </FlxMiddleMb20>
            <ListInputs>
                {/* <Fz15>매장 KEY</Fz15>
          <InputShort placeholder="Code 번호를 입력해 주세요" /> */}
                <Fz15>검색어</Fz15>
                <InputShort placeholder="상호 / 네이버 예약 ID" ref={searchInput} onKeyUp={
                    (e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                          handleSearchClick();
                        }
                    }}
                 />
                {/* <Fz15>ID & 이름/닉네임</Fz15>
          <InputShort placeholder="아이디 또는 이름/닉네임" /> */}
                <BtnSearch onClick={handleSearchClick}>
                    <img src={icSearch} alt="" />
                    검색
                </BtnSearch>
            </ListInputs>
            <Block50>
                <ListTableWrapper>
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan={2}>No.</th>
                                {/* <th>체크</th> */}
                                <th rowSpan={2}>등록자</th>
                                <th rowSpan={2}>상태</th>
                                <th rowSpan={2}>대리점</th>
                                {user.role === 'ADMIN' && <th colSpan={3}>매니저</th>}
                                <th rowSpan={2}>상호</th>
                                <th rowSpan={2}>디자이너(명)</th>
                                <th rowSpan={2}>주소</th>
                                <th rowSpan={2}>
                                    네이버
                                    <br />
                                    연동상태
                                </th>
                                <th rowSpan={2}>등록일</th>
                                <th rowSpan={2}>편집</th>
                            </tr>
                            {user.role === 'ADMIN' && (
                                <tr>
                                    <th style={{ borderTop: '1px solid #757575' }}>아이디</th>
                                    <th
                                        style={{
                                            borderTop: '1px solid #757575',
                                            borderLeft: '1px solid #757575',
                                            borderRight: '1px solid #757575',
                                        }}
                                    >
                                        이름
                                    </th>
                                    <th style={{ borderTop: '1px solid #757575' }}>연락처</th>
                                </tr>
                            )}
                        </thead>
                        <tbody>
                            {data && data.docs.length ? (
                                data.docs.map((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                {data.totalDocs - (page - 1) * pageLimit - index}
                                            </td>
                                            <td>
                                                {item?.user?.role === 'ADMIN'
                                                    ? '관리자'
                                                    : item.user?.role === 'MANAGER'
                                                    ? '매니저'
                                                    : '디자이너'}
                                            </td>
                                            <td>
                                                {item.removed ? (
                                                    <ColRed>삭제</ColRed>
                                                ) : (
                                                    item.approved == true ? <ColAccent>
                                                      승인
                                                    </ColAccent> : <ColRed>대기</ColRed>
                                                )}
                                            </td>
                                            <td>{item?.manager?.region?.name || '-'}</td>
                                            {user.role === 'ADMIN' && (
                                                <>
                                                    <td>{item?.manager?.user_id || '-'}</td>
                                                    <td>{item?.manager?.name || '-'}</td>
                                                    <td>{item?.manager?.phone || '-'}</td>
                                                </>
                                            )}
                                            <td>{item.name}</td>
                                            <td>{item.designer_count}명</td>
                                            <td>{item.address}</td>
                                            <td>
                                                {item.approved &&
                                                item.naver_id &&
                                                item.naver_key ? (
                                                    '연결완료'
                                                ) : (
                                                    <ColRed>연결 미설정</ColRed>
                                                )}
                                            </td>
                                            <td>
                                                <Moment format="YYYY.MM.DD">{item.created}</Moment>
                                            </td>
                                            <TdBtn>
                                                <Link to={`/shops/${item.id}/edit`}>
                                                    <BtnTable>수정</BtnTable>
                                                </Link>
                                            </TdBtn>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="12">
                                        {isLoading ? (
                                            <ClipLoader
                                                color={'#00bf9d'}
                                                size={35}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        ) : (
                                            '데이터가 없습니다.'
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </ListTableWrapper>
                {!isLoading && (
                    <ReactPaginate
                        className="paging"
                        breakLabel="..."
                        nextLabel={<span className="paging_end pl30"></span>}
                        onPageChange={handlePageClick}
                        forcePage={page - 1}
                        pageRangeDisplayed={3}
                        pageCount={data?.totalPages}
                        previousLabel={<span className="paging_first pl30"></span>}
                        renderOnZeroPageCount={null}
                        activeLinkClassName="paging_num paging_current"
                        pageLinkClassName="paging_num"
                        breakLinkClassName="paging_num"
                        previousLinkClassName="paging_num"
                        nextLinkClassName="paging_num"
                        breakClassName="paging_break"
                    />
                )}
            </Block50>
            <BottomBtns>
                <Link to="/shops/new">
                    <BtnMd>신규 등록</BtnMd>
                </Link>
                {/* <BtnMd $bg="#3D5873">선택 운영</BtnMd>
          <BtnMd $bg="#6946B1">선택 대기</BtnMd>
          <BtnMd $bg="#F33535">선택 폐업</BtnMd> */}
            </BottomBtns>
        </SysContainer>
    );
}

export default Shops;
