import { apiService } from "./api";

export const noticeApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getNotices: builder.query({
      query: ({ page, limit, searchText }) => ({
        url: `/boards/notice?page=${page}&text=${searchText || ''}&limit=${limit}`,
        method: 'GET',
      })
    }),
    getNotice: builder.query({
      query: id => ({
        url: `/boards/notice/${id}`,
        method: 'GET',
      })
    }),
    createNotice: builder.mutation({
      query: notice => ({
        url: '/boards/notice',
        method: 'POST',
        body: notice,
      })
    }),
    updateNotice: builder.mutation({
      query: ({ id, data }) => ({
        url: `/boards/notice/${id}`,
        method: 'PUT',
        body: data,
      })
    }),
    deleteNotice: builder.mutation({
      query: id => ({
        url: `/boards/notice/${id}`,
        method: 'DELETE',
      })
    }),
  }),
});

export const { useGetNoticesQuery, useGetNoticeQuery, useCreateNoticeMutation, useUpdateNoticeMutation, useDeleteNoticeMutation } = noticeApi;