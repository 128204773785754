import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import arrowDownGray from '@/assets/img/arrow_down_big_gray.svg';

//CSS
const flxCent = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const inlineFlxCent = css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;
const horizonCent = css`
    position: absolute;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
`;
const VertCent = css`
    position: absolute;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
`;
const btn = css`
    border-radius: 8px;
    font-weight: 700;
    white-space: nowrap;
`;
const hide = css`
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    line-height: 0;
    font-size: 0;
    text-indent: -9999px;
    text-shadow: none;
    color: transparent;
`;
const fz15 = css`
    font-size: 1.154rem;
    letter-spacing: -0.07rem;
`;
const fz15bold = css`
    font-size: 1.154rem;
    font-weight: 700;
    letter-spacing: -0.07rem;
`;
const fz16medium = css`
    font-size: 1.23rem;
    font-weight: 500;
    letter-spacing: -0.07rem;
`;
const fz18medium = css`
    font-size: 1.385rem;
    font-weight: 500;
    letter-spacing: -0.07rem;
`;
const txtcolor = css`
    color: ${(props) => props.color || '#757575'};
`;

//여백
const Mb10 = styled.div`
    margin-bottom: 0.77rem;
`;
const Mb15 = styled.div`
    margin-bottom: 1.154rem;
`;
const Mb20 = styled.div`
    margin-bottom: 1.538rem;
`;
const Mb30 = styled.div`
    margin-bottom: 2.3rem;
`;
const Mb40 = styled.div`
    margin-bottom: 3.077rem;
`;
const Spacing10 = styled.div`
    width: 100%;
    height: 0.77rem;
`;
const Spacing20 = styled.div`
    width: 100%;
    height: 1.538rem;
`;
const Spacing30 = styled.div`
    width: 100%;
    height: 2.3077rem;
`;
const Spacing36 = styled.div`
    width: 0;
    height: 3rem;
`;
const Spacing50 = styled.div`
    width: 0;
    height: 3.846rem;
`;
const Spacing70 = styled.div`
    width: 0;
    height: 5.385rem;
`;
const Spacing100 = styled.div`
    width: 0;
    height: 7.6923rem;
`;
const Gap10 = styled.div`
    width: 0.77rem;
    height: 0;
`;
const Gap15 = styled.div`
    width: 1.154rem;
    height: 0;
`;
const Gap20 = styled.div`
    width: 1.538rem;
    height: 0;
`;
const Gap30 = styled.div`
    width: 2.3077rem;
    height: 0;
`;
const PadVert10 = styled.div`
    padding: 0.77rem 0;
`;
const PadVert20 = styled.div`
    padding: 1.538rem 0;
`;
const PadVert30 = styled.div`
    padding: 2.3077rem 0;
`;
const Pad20 = styled.div`
    padding: 1.538rem;
`;
//텍스트
const Nowrap = styled.span`
    white-space: nowrap;
`;
const Paragraph = styled.p`
    line-height: 1.4666;
    letter-spacing: -0.07rem;
    font-size: ${(props) => props.fontSize || '1rem'};
    text-align: ${(props) => props.$ta || 'unset'};
    color: ${(props) => props.color || '#000'};
`;
const Fz15 = styled.span`
    font-size: 1.154rem;
    letter-spacing: -0.07rem;
    color: ${(props) => props.color || '#000'};
`;
const Fz15Bold = styled(Fz15)`
    font-weight: 700;
`;
const Fz16Medium = styled.h4`
    margin-bottom: ${(props) => props.$mb || '0'};
    font-size: 1.23rem;
    font-weight: 500;
    letter-spacing: -0.07rem;
    ${txtcolor}
`;
const Fz18Medium = styled.h3`
    margin-bottom: ${(props) => props.$mb || '0'};
    font-size: 1.385rem;
    font-weight: 500;
    letter-spacing: -0.07rem;
    color: ${(props) => props.color || '#000'};
`;
const Fz18Bold = styled(Fz18Medium)`
    font-weight: 700;
`;
const Fz20Bold = styled.h3`
    margin-bottom: ${(props) => props.$mb || '0'};
    font-size: 1.538rem;
    font-weight: bold;
    color: ${(props) => props.color || '#000'};
`;
const Fz30Bold = styled.h3`
    margin-bottom: ${(props) => props.$mb || '0'};
    font-size: 2.3077rem;
    font-weight: 700;
    color: ${(props) => props.color || '#000'};
`;
const ColAccent = styled.span`
    color: #00bf9d;
`;
const ColAccent2 = styled.span`
    color: #ff3062;
`;
const ColRed = styled.span`
    color: #f33535;
`;
const ColPurple = styled.span`
    color: #6946b1;
`;
const ColGray1 = styled.span`
    color: #a1a1a1;
`;
const ColGray2 = styled.span`
    color: #555;
`;
const ColBlack = styled.span`
    color: #000;
`;

//레이아웃 정의
const Flx = styled.div`
    display: flex;
`;
const FlxColumn = styled.div`
    display: flex;
    flex-direction: column;
`;
const Flxbt = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: ${(props) => props.$ai || 'center'};
`;
const FlxbtFull = styled(Flxbt)`
    width: 100%;
`;
const FlxbtFullMb10 = styled(FlxbtFull)`
    margin-bottom: 0.77rem;
`;
const FlxbtFullMb15 = styled(FlxbtFull)`
    margin-bottom: 1.154rem;
    label {
        display: block;
    }
`;
const FlxbtFullMb20 = styled(FlxbtFull)`
    margin-bottom: 1.538rem;
`;
const FlxbtFullMb30 = styled(FlxbtFull)`
    margin-bottom: 2.3077rem;
`;
const FlxbtFullMb40 = styled(FlxbtFull)`
    margin-bottom: 3.077rem;
`;
const FlxMiddle = styled.div`
    display: flex;
    align-items: center;
`;
const FlxMiddleMb10 = styled(FlxMiddle)`
    margin-bottom: 0.77rem;
`;
const FlxMiddleMb20 = styled(FlxMiddle)`
    margin-bottom: 1.538rem;
`;
const FlxMiddleMb30 = styled(FlxMiddle)`
    margin-bottom: 2.3077rem;
`;
const FlxMiddleMb60 = styled(FlxMiddle)`
    margin-bottom: 4.6154rem;
`;
const List = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const LinkFlx = styled(Link)`
    display: flex;
`;
const LinkFlxMiddle = styled(Link)`
    display: flex;
    align-items: center;
`;
const LinkFlxbtFull = styled(LinkFlxMiddle)`
    justify-content: space-between;
`;
const NavFlxbtFull = styled(NavLink)`
    display: flex;
    justify-content: space-between;
    padding: 1.154rem 1.538rem;
    background-color: #333;
    font-weight: 400;
    color: #fff;
    img {
        visibility: hidden;
    }
    &.active {
        font-weight: 700;
        background-color: #fff;
        color: #00bf9d;
        img {
            visibility: visible;
        }
    }
`;
const ItemInfoCol2 = styled.div`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: ${(props) => props.$mb || 'unset'};
    padding-bottom: ${(props) => props.$pb || 'unset'};
`;
const SysWrap = styled.div`
    position: relative;
    background-color: #fff;
    color: #000;
`;
const SysContainer = styled.div`
    margin-top: 4.61538rem;
    margin-left: 19.538rem;
    padding: 6.154rem 11.538rem;
    @media screen and (max-width: 1800px) {
        margin-left: 18rem;
        padding: 5.154rem 6.538rem;
    }
`;
const ContainerPadding = styled.div`
    width: 90%;
    margin: 0 auto;
`;
const Block = styled.div`
    margin-bottom: 4.61538rem;
`;
const Block50 = styled.div`
    margin-bottom: 3.846rem;
`;

// 공통 컴포넌트
const InputSet = styled.div`
    display: block;
    margin-bottom: ${(props) => props.$mb || '0'};
    ${fz15};
    input {
        width: 100%;
        padding: 1.231rem 1.154rem;
        border: 1px solid #a1a1a1 !important;
        border-radius: 8px;
        background-color: #fff;
    }
`;
const UpperLabel = styled.label`
    display: block;
    margin-bottom: 1.154rem;
    font-weight: 700;
    color: #000;
`;
const Input = styled.input`
    width: ${(props) => props.$wd || '100%'};
    padding: 1.05rem 1.154rem;
    border: 1px solid #e8eaed !important;
    border-radius: 8px;
    background-color: #fff;
    &:disabled {
        background-color: #f8f8f8;
        border-color: #f8f8f8 !important;
    }
`;
const InputShort = styled.input.attrs({ type: 'text' })`
    width: 20.77rem;
    padding: 0.693rem 0.77rem;
    border-radius: 8px;
    border: 1px solid #e8eaed !important;
    background: #fff;
    font-size: 13px;
    & ~ span {
        white-space: nowrap;
    }
`;
const Textbox = styled.textarea`
    width: 100%;
    padding: 1.538rem;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ $active }) => ($active ? '#00BF9D' : '#e8eaed')};
    border-radius: 10px;
    ${fz15};
    color: #000;
    resize: none;
    &:disabled {
        background-color: #f8f8f8;
        border-color: #f8f8f8 !important;
    }
`;
const BtnXsm = styled.button.attrs({ type: 'button' })`
    ${inlineFlxCent};
    border-radius: 8px;
    white-space: nowrap;
    padding: 0.39rem;
    border-width: 1px;
    border-style: solid;
    margin-left: ${(props) => props.$ml || 0};
    border-color: ${(props) => props.$bc || '#A1A1A1'};
    background-color: ${(props) => props.$bg || '#fff'};
    color: ${(props) => props.$col || '#000'};
`;
const BtnSm = styled.button.attrs({ type: 'button' })`
    ${flxCent};
    ${btn};
    height: 3.406rem;
    padding: 0 0.77rem;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.$bc || '#00bf9d'};
    background-color: ${(props) => props.$bg || '#fff'};
    color: ${(props) => props.$col || '#00BF9D'};
`;
const BtnMd = styled(BtnSm)`
    height: 3.4615rem;
    padding: 0 2.3077rem;
    border-color: ${(props) => props.$bc || 'transparent'};
    background-color: ${(props) => props.$bg || '#00BF9D'};
    ${fz16medium};
    color: ${(props) => props.$col || '#fff'};
`;
const BtnMdSubmit = styled(BtnMd).attrs({ type: 'submit' })`
    padding: 1.117rem 2.3077rem;
    border: 0;
    background-color: ${(props) => props.$bg || '#00BF9D'};
    ${fz16medium};
    color: ${(props) => props.$col || '#fff'};
`;
const BtnSearch = styled.button.attrs({ type: 'button' })`
    ${flxCent};
    min-width: 10.615rem;
    height: 2.692308rem;
    padding: 0.25rem 0.77rem;
    column-gap: 5px;
    border-radius: 8px;
    background-color: #00bf9d;
    font-weight: 600;
    color: #fff;
`;
const BtnTable = styled(BtnSm)`
    display: inline-flex;
    min-width: 10.37rem;
    height: unset;
    padding: 0.655rem 0.77rem;
    border: 0;
    background-color: #333;
    color: #fff;
`;
const BtnDelete = styled.button.attrs({ type: 'button' })`
    position: absolute;
    top: 0.385rem;
    right: 0.385rem;
    z-index: 2;
`;
const BtnModalClose = styled.button.attrs({ type: 'button' })`
    position: absolute;
    top: 1.3rem;
    right: 1.3rem;
    z-index: 2;
`;
const BtnFullModal = styled.button`
    ${flxCent};
    ${fz16medium};
    width: 100%;
    padding: 1.154rem;
    border-radius: 10px;
    background-color: ${({ active }) => (active ? '#00BF9D' : '#E8EAED')};
    color: ${({ active }) => (active ? '#fff' : '#A1A1A1')};
`;
const BtnHalfModal = styled(BtnFullModal)`
    width: calc(50% - 0.77rem);
    background-color: ${(props) => props.$bg || '#00bf9d'};
    color: ${(props) => props.$col || '#fff'};
`;
const Select = styled.select`
    appearance: none;
    -webkit-appearance: none;
    width: ${(props) => props.$wd || '100%'};
    min-width: 6rem;
    padding: 1.05rem 1.154rem;
    margin-bottom: ${(props) => props.$mb || '0'};
    border: 1px solid #e8eaed;
    border-radius: 8px;
    background-color: #fff;
    &:disabled {
        background-color: #f8f8f8;
        border-color: #f8f8f8;
    }
    background: url(${arrowDownGray}) no-repeat calc(100% - 1.154rem) center;
`;
const SelectShort = styled.select`
    appearance: none;
    -webkit-appearance: none;
    width: 20.77rem;
    padding: 0.693rem 0.77rem;
    border-radius: 8px;
    border: 1px solid #e8eaed !important;
    background: #fff;
    &:disabled {
        background-color: #f8f8f8;
        border-color: #f8f8f8;
    }
    background: url(${arrowDownGray}) no-repeat calc(100% - 1.154rem) center;
`;
const ChkAndTxt = styled(FlxMiddle).attrs({ as: 'label' })`
    ${FlxMiddle};
    margin-right: ${(props) => props.$mr || '1.538rem'};
    input[type='radio'],
    input[type='checkbox'] {
        margin-right: 0.77rem;
    }
    line-height: 0;
`;
const UploadFile = styled.label`
    position: relative;
    display: block;
    width: ${(props) => props.$wd || '100%'};
    min-width: 345px;
    height: 3.4615rem;
    border: 1px solid #e8eaed;
    border-radius: 8px;
    background-color: #fff;
    div {
        display: inline-block;
        width: 75%;
        height: 100%;
        padding: 1.05rem 1.154rem;
    }
    input[type='file'] {
        ${hide}
    }
    &::before {
        content: '첨부파일';
        ${VertCent};
        right: 1.154rem;
        ${inlineFlxCent};
        height: 2.3077rem;
        padding: 0 0.77rem;
        border: 1px solid #00bf9d;
        ${btn};
        background-color: #fff;
        color: #00bf9d;
        line-height: 0;
    }
`;
const LinkBtnSm = styled(Link)`
    ${flxCent};
    ${btn};
    height: 3.406rem;
    padding: 0 0.77rem;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.$bc || '#00bf9d'};
    background-color: ${(props) => props.$bg || '#00bf9d'};
    color: ${(props) => props.$col || '#fff'};
`;
const LinkBtnMd = styled(LinkBtnSm)`
    height: 3.4615rem;
    padding: 0 2.3077rem;
    border-color: ${(props) => props.$bc || 'transparent'};
    background-color: ${(props) => props.$bg || '#00BF9D'};
    ${fz16medium};
    color: ${(props) => props.$col || '#fff'};
`;

const GroupDivider = styled.div`
    width: 100%;
    height: 1px;
    margin: 3.846rem auto;
    background-color: #a1a1a1;
`;
const ContentDivider = styled(GroupDivider)`
    margin: 2.3077rem auto;
    background-color: #e8eaed;
`;
const TxtDivider = styled.div`
    min-width: 1px;
    width: 2px;
    height: 1.54rem;
    margin: 0 1.92rem;
    background-color: #e8eaed;
`;
const TxtDivider2 = styled(TxtDivider)`
    width: 1px;
    height: 1.538rem;
    margin: ${(props) => props.$mg || '0 1.538rem'};
`;
const LinkFull = styled(Link)`
    ${flxCent};
    ${fz18medium};
    // width: 100%;
    padding: 1.154rem;
    border-radius: 10px;
    background-color: ${({ active }) => (active ? '#00BF9D' : '#E8EAED')};
    color: ${({ active }) => (active ? '#FFF' : '#A1A1A1')};
`;
const InfoLabel = styled.div`
    flex: 0 0 auto;
    width: 24%;
    color: #757575;
    font-weight: bold;
    white-space: nowrap;
`;
const InfoValue = styled.div`
    flex: 0 0 auto;
    width: 75%;
`;
const TxLabelRight = styled.p`
    padding-left: 0.77rem;
    white-space: nowrap;
`;
const TxLabelLeft = styled.p`
    padding-right: 0.77rem;
    white-space: nowrap;
`;
const ModalAbsolBtn = styled.div`
    bottom: ${(props) => props.$bo || '2.3077rem'};
    ${horizonCent};
    width: 90%;
`;
const TdSm = styled.td`
    width: 5rem;
`;
const TdBtn = styled.td`
    width: 15.4rem;
`;

// 헤더
const SysHead = styled.header`
    position: fixed;
    top: 0;
    left: 19.538rem;
    display: flex;
    width: calc(100% - 19.538rem);
    height: 4.61538rem;
    padding: 0 3.846rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e8eaed;
    background-color: #fff;
    color: #000;
    z-index: 19;
    @media screen and (max-width: 1800px) {
        left: 18rem;
        width: calc(100% - 18rem);
    }
`;
const SquareBlack = styled.span`
    display: inline-flex;
    width: 10px;
    min-width: 10px;
    height: 10px;
    margin-right: 0.77rem;
    background-color: #000;
`;
const SquarePink = styled(SquareBlack)`
    background-color: #ff3062;
`;
const SquareGreen = styled(SquareBlack)`
    background-color: #00bf9d;
`;

// SNB(사이드 내비게이션)
const Snb = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 19.538rem;
    height: 100vh;
    flex-direction: column;
    background-color: #333;
    ${fz15}
    & > a {
        display: grid;
        padding: 2.3077rem 0;
        place-content: center;
        background-color: #00bf9d;
        img {
            max-height: 10.538rem;
        }
    }
    @media screen and (max-width: 1800px) {
        width: 18rem;
    }
`;
const SnbMenu = styled.li`
    display: flex;
    flex-direction: column;
    font-weight: ${({ $active }) => ($active ? 700 : 400)};
    background-color: ${({ $active }) => ($active ? '#fff' : '#333')};
    color: ${({ $active }) => ($active ? '#00bf9d' : '#fff')};
    a {
        padding: 1.154rem 1.538rem;
    }
    img {
        visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};
    }
`;

//페이징
const PagingWrapper = styled.div`
    ${flxCent};
`;
const PagingArrow = styled.button.attrs({ type: 'button' })`
    ${inlineFlxCent};
    border-radius: 50%;
    width: 2.46rem;
    min-width: 2.46rem;
    height: 2.46rem;
    margin: 0 4px;
`;
const PagingNum = styled(PagingArrow)`
    width: 2.77rem;
    min-width: 2.77rem;
    height: 2.77rem;
    ${fz15};
    background-color: ${({ $active }) => ($active ? '#E5F6F3' : '#FFF')};
    color: ${({ $active }) => ($active ? '#00BF9D' : '#4E5969')};
    font-weight: ${({ $active }) => ($active ? '700' : '400')};
`;

// 로그인
const SysIndexContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    flex-flow: wrap row;
    background-color: #fff;
    & > div {
        ${flxCent};
        width: 50%;
        height: 100%;
    }
`;
// 목록
const ListInputs = styled(FlxMiddleMb30)`
    span,
    input,
    select {
        margin-right: 2.3077rem;
    }
`;
const ListTableWrapper = styled.div`
    margin-bottom: 2.3077rem;
    overflow-x: auto;
    table {
        width: 100%;
        min-width: 90rem;
        border-collapse: collapse;
        text-align: center;
    }
    td {
        border-bottom: 1px solid #e8eaed;
        &.title {
            text-align: left;
        }
    }
    th {
        min-width: 4rem;
        font-weight: 400;
        white-space: nowrap;
        color: #fff;
        ${fz15}
    }
    td {
        padding: 0.77rem;
    }
    thead th {
        padding: 0.77rem 1.538rem;
        background-color: #333;
    }
`;
const BottomBtns = styled.div`
    display: flex;
    justify-content: center;
    & > * {
        margin-right: 1.538rem;
        &:last-of-type {
            margin-right: 0;
        }
    }
`;
const SlashTxt = styled.td`
    span {
        &::after {
            content: '/';
            padding: 0 2px;
            color: #000;
        }
        &:last-of-type {
            &::after {
                display: none;
            }
        }
    }
`;
// 상세
const ShopItem = styled.div`
    display: flex;
    margin-bottom: 1.385rem;
    justify-content: space-between;
    label {
        display: block;
        width: calc(100% - 3.077rem);
    }
    h5 {
        padding-bottom: 1.154rem;
    }
`;
const ManagerItem = styled.div`
    display: flex;
    margin-bottom: 1.385rem;
    justify-content: space-between;
    label {
        display: block;
        width: calc(100% - 3.077rem);
    }
    h5 {
        padding-bottom: 1.154rem;
    }
`;
const DesignerItem = styled.div`
    display: flex;
    margin-bottom: 1.385rem;
    justify-content: space-between;
    label {
        display: block;
        width: calc(100% - 3.077rem);
    }
    h5 {
        padding-bottom: 1.154rem;
    }
`;
const TitleLine = styled.div`
    width: 100%;
    height: 1px;
    margin-top: ${(props) => props.$mt || '2.3077rem'};
    margin-bottom: ${(props) => props.$mb || '4.6154rem'};
    background-color: #a1a1a1;
`;
const FieldGroup = styled.fieldset`
    display: flex;
    max-width: 1200px;
    margin-bottom: ${(props) => props.$mb || '4.6154rem'};
    flex-flow: wrap row;
    justify-content: space-between;
`;
const InputItem = styled.div`
    position: relative;
    display: flex;
    width: ${(props) => props.$wd || '43%'};
    margin-bottom: 1.154rem;
    align-items: ${(props) => props.$ai || 'center'};
    span {
        display: inline-flex;
        min-width: 12rem;
        line-height: 150%;
        padding: 1rem 0;
        margin-right: 1.154rem;
    }
    & > button {
        left: calc(100% + 1.154rem);
        ${VertCent}
    }
`;
const SearchWrapper = styled.div`
    ${flxCent};
    justify-content: space-between;
    padding: 0.77rem 1rem;
    margin-bottom: ${(props) => props.$mb || '3.077rem'};
    border-radius: 8px;
    border: 1px solid #e8eaed;
    background-color: #fff;
    input {
        width: 90%;
    }
`;
const FormBorderb = styled.div`
    width: 100%;
    height: 1px;
    margin-bottom: 2.3077rem;
    background-color: #000;
`;
const BottomBtnsRight = styled(BottomBtns)`
    justify-content: right;
`;
const StatusColor = styled.label`
    ${flxCent};
    margin-right: 1.538rem;
    p {
        white-space: nowrap;
    }
    input[type='color'] {
        width: 1.73rem;
        min-width: 1.73rem;
        height: 1.73rem;
        border-radius: 3px;
        overflow: hidden;
        &::-webkit-color-swatch-wrapper {
            padding: 0;
        }
        &::-webkit-color-swatch {
            border: 0;
        }
    }
`;
const PreviewList = styled(List)`
    margin-top: 0.77rem;
    column-gap: 0.77rem;
    & > label {
        margin: 0 0.77rem 1.538rem 0;
    }
`;
const PhotoItem = styled.label`
    position: relative;
    ${flxCent};
    display: grid;
    width: 10.615rem;
    min-width: 10.615rem;
    height: 10.615rem;
    aspect-ratio: 1/1;
    border: 1px solid #e8eaed;
    border-radius: 8px;
    overflow: hidden;
    input[type='radio'] {
        ${hide}
    }
`;
const GridImg = styled.img`
    width: 100%;
    min-height: 100%;
`;

//배너 관리
const SchDatePickWrapper = styled.div`
    width: 10rem;
`;
const BannerList = styled(List)`
    & > div {
        width: calc(25% - 1.77rem);
        margin-right: 2.3077rem;
        margin-bottom: 2.3077rem;
        &:nth-of-type(4n) {
            margin-right: 0;
        }
    }
`;
const BannerItem = styled.div`
    padding: 1.154rem 1.154rem 1.923rem;
    margin-bottom: 1.923rem;
    margin-right: 2.3077rem;
    border: 1px solid #e8eaed;
    border-radius: 20px;
    background-color: #fff;
`;
const BannerImgWrapper = styled.div`
    ${flxCent};
    height: 12.692rem;
    margin-bottom: 1.154rem;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
    }
`;
const PreviewWrapper = styled.div`
    width: 330px;
    height: 650px;
    border: 1px solid #000;
    border-radius: 18px;
    padding-bottom: 2rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
        visibility: hidden;
    }
`;
const MyHeader = styled.div`
    padding: 2.3077rem 1.538rem;
    margin-bottom: 2.3077rem;
    border-bottom: 1px solid #e8eaed;
`;
const PostImg = styled.div`
    width: 100%;
    max-width: 100%;
    margin: 1rem 0;
    img {
        width: 100%;
    }
`;
const SlideImg = styled(PostImg)`
    height: 15.38rem;
    margin: 0;
    padding-right: 0.77rem;
    img {
        min-height: 100%;
    }
`;
const BottomToolBar = styled.div`
    position: fixed;
    bottom: 0;
    left: 19.538rem;
    ${flxCent};
    width: calc(100% - 19.538rem);
    height: 5.385rem;
    background-color: rgba(0, 0, 0, 0.7);
    column-gap: 1.538rem;
`;

// 글쓰기
const EditorWrapper = styled.div`
    width: 100%;
    * {
        border-color: #e8eaed !important;
    }
    .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
    .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
    .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;
const DaumWrapper = styled.div`
    position: absolute;
    top: 110%;
    left: 25%;
    width: 35rem;
    border: 1px solid #e8eaed;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    z-index: 2;
`;
export {
    BannerImgWrapper,
    BannerItem,
    BannerList,
    Block,
    Block50,
    BottomBtns,
    BottomBtnsRight,
    BottomToolBar,
    BtnDelete,
    BtnFullModal,
    BtnHalfModal,
    BtnModalClose,
    BtnSearch,
    BtnSm,
    BtnMd,
    BtnMdSubmit,
    BtnTable,
    BtnXsm,
    ChkAndTxt,
    ColAccent,
    ColAccent2,
    ColBlack,
    ColGray1,
    ColGray2,
    ColPurple,
    ColRed,
    ContainerPadding,
    ContentDivider,
    DaumWrapper,
    EditorWrapper,
    FieldGroup,
    Flx,
    Flxbt,
    FlxbtFull,
    FlxbtFullMb10,
    FlxbtFullMb15,
    FlxbtFullMb20,
    FlxbtFullMb30,
    FlxbtFullMb40,
    FlxColumn,
    FlxMiddle,
    FlxMiddleMb10,
    FlxMiddleMb20,
    FlxMiddleMb30,
    FlxMiddleMb60,
    FormBorderb,
    Fz15,
    Fz15Bold,
    Fz16Medium,
    Fz18Bold,
    Fz18Medium,
    Fz20Bold,
    Fz30Bold,
    Gap10,
    Gap15,
    Gap20,
    Gap30,
    GridImg,
    GroupDivider,
    InfoLabel,
    Input,
    InputItem,
    InputSet,
    InputShort,
    InfoValue,
    ItemInfoCol2,
    LinkBtnSm,
    LinkBtnMd,
    LinkFlx,
    LinkFlxbtFull,
    LinkFlxMiddle,
    LinkFull,
    List,
    ListInputs,
    ListTableWrapper,
    Mb10,
    Mb15,
    Mb20,
    Mb30,
    Mb40,
    ModalAbsolBtn,
    MyHeader,
    NavFlxbtFull,
    Pad20,
    PadVert10,
    PadVert20,
    PadVert30,
    PagingWrapper,
    PagingArrow,
    PagingNum,
    Paragraph,
    PhotoItem,
    PostImg,
    PreviewList,
    PreviewWrapper,
    SchDatePickWrapper,
    SearchWrapper,
    Select,
    SelectShort,
    ShopItem,
    ManagerItem,
    Nowrap,
    DesignerItem,
    SlashTxt,
    SlideImg,
    Snb,
    SnbMenu,
    Spacing10,
    Spacing20,
    Spacing30,
    Spacing36,
    Spacing50,
    Spacing70,
    Spacing100,
    SquareBlack,
    SquareGreen,
    SquarePink,
    StatusColor,
    SysContainer,
    SysHead,
    SysIndexContainer,
    SysWrap,
    TdBtn,
    TdSm,
    Textbox,
    TitleLine,
    TxtDivider,
    TxtDivider2,
    TxLabelRight,
    TxLabelLeft,
    UploadFile,
    UpperLabel,
};
