import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Chkbox from '@/components/Chkbox';
import {
    Block50,
    SysContainer,
    Fz30Bold,
    Fz15,
    InputShort,
    BtnSearch,
    ListTableWrapper,
    BtnXsm,
    FlxMiddleMb20,
    Gap10,
    Gap30,
    ListInputs,
    ChkAndTxt,
    Fz20Bold,
    ModalAbsolBtn,
    BtnModalClose,
    Block,
    BtnHalfModal,
    FlxbtFull,
    SchDatePickWrapper,
} from '@/css/admin/style';
import icSearch from '@/assets/img/ic_search.svg';
import icClose from '@/assets/img/ic_close_modal.svg';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { useNavigate, useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import {
    useGetSubscriptionsQuery,
    useUpdateSubscriptionMutation,
    useUpdateSubscriptionStatusMutation,
} from '@services/subscription';
import DatePicker from 'react-datepicker';
import { format as dateFormat } from 'date-fns';
import useDynamicRefs from '@/hooks/useDynamicRefs'

function LicenseHistory() {
    const searchInput = React.createRef();
    const [getRef, setRef] = useDynamicRefs();

    const searchParams = new URLSearchParams(useLocation().search);
    
    const [selectedItem, setSelectedItem] = useState(null);
    const [modalApproveIsOpen, setApproveIsOpen] = React.useState(false);
    const [searchText, setSearchText] = useState(searchParams.get('searchText') || '');
    const [searchStatus, setSearchStatus] = useState(searchParams.get('searchStatus') || '');
    const [startDate, setStartDate] = useState(searchParams.get('startDate') || '');
    const [endDate, setEndDate] = useState(searchParams.get('endDate') || '');
    const [page, setPage] = useState(searchParams.get('page') || 1);

    const [pageLimit, setPageLimit] = useState(searchParams.get('pageLimit') || 10);

    const navigate = useNavigate();

    const { data, isLoading, error, refetch } = useGetSubscriptionsQuery(
        { page, 
          limit: pageLimit, 
          from_date: startDate || '',
          to_date: endDate || '',
          searchText, 
          searchStatus 
        },
        { refetchOnMountOrArgChange: true }
    );

    const [updateSubscription, { isLoading: updateSubscriptionIsLoading, error: updateSubscriptionError }] = useUpdateSubscriptionMutation();
    const [updateSubscriptionStatus, { isLoading: updateSubscriptionStatusIsLoading, error: updateSubscriptionStatusError }] = useUpdateSubscriptionStatusMutation();

    useEffect(() => {
        const url = new URL(window.location);
        url.searchParams.set('page', page);
        url.searchParams.set('searchText', searchText);
        url.searchParams.set('searchStatus', searchStatus);
        url.searchParams.set('startDate', startDate || '');
        url.searchParams.set('endDate', endDate || '');
        window.history.pushState({}, '', url);
    }, [page, searchText, startDate, endDate, searchStatus]);

    const handleSearchClick = () => {
        const text = searchInput.current.value;
        setPage(1);
        setSearchText(text);
    };

    const handlePageClick = (e) => {
        const { selected } = e;
        setPage(selected + 1);
    };

    const handleSearchStatus = (status) => {
        setPage(1);
        setSearchStatus(status);
    };

    const openApproveModal = (item) => {
        setSelectedItem(item);
        setApproveIsOpen(true);
    };

    const closeApproveModal = () => {
        setSelectedItem(null);
        setApproveIsOpen(false);
    };

    const handleApprove = async (id) => {
        try {
            await updateSubscriptionStatus({
                id,
                data: {
                    status: 'confirmed',
                }
            });
            toast.success('정산 처리되었습니다.');
            refetch();
        } catch (e) {
            toast.error('정산 처리중 문제가 발생했습니다.');
        } finally {
            closeApproveModal();
        }
    };
    return (
        <>
            <SysContainer>
                <Fz30Bold $mb="3.077rem">라이센스 이력 및 정산 관리</Fz30Bold>
                <FlxMiddleMb20>
                    <Fz15>상태</Fz15>
                    <Gap30 />
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === ''}
                            onChange={() => {
                                handleSearchStatus('');
                            }}
                        />
                        전체
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === 'pending'}
                            onChange={() => handleSearchStatus('pending')}
                        />
                        정산대기
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === 'confirmed'}
                            onChange={() => handleSearchStatus('confirmed')}
                        />
                        정산완료
                    </ChkAndTxt>
                    <ChkAndTxt $mr="0">
                        <Chkbox
                            checked={searchStatus === 'canceled'}
                            onChange={() => handleSearchStatus('canceled')}
                        />
                        결제취소
                    </ChkAndTxt>
                </FlxMiddleMb20>
                <ListInputs>
                    <Fz15>기간</Fz15>
                    <SchDatePickWrapper>
                        <DatePicker
                            selected={startDate ? new Date(startDate) : null}
                            onChange={date=>setStartDate(date ? dateFormat(date, 'yyyy-MM-dd') : '')}
                            selectsStart
                            startDate={startDate ? new Date(startDate) : null}
                            endDate={endDate ? new Date(endDate) : null}
                            locale="ko"
                            dateFormat="yyyy-MM-dd"
                            placeholderText="시작일"
                        />
                    </SchDatePickWrapper>
                    <Gap10 />~<Gap10 />
                    <SchDatePickWrapper>
                        <DatePicker
                            selected={endDate ? new Date(endDate) : null}
                            onChange={date=>setEndDate(date ? dateFormat(date, 'yyyy-MM-dd') : '')}
                            selectsEnd
                            startDate={startDate ? new Date(startDate) : null}
                            endDate={endDate ? new Date(endDate) : null}
                            minDate={startDate ? new Date(startDate) : null}
                            locale="ko"
                            dateFormat="yyyy-MM-dd"
                            placeholderText="종료일"
                        />
                    </SchDatePickWrapper>
                    <Gap30 />
                    <Fz15>검색어</Fz15>
                    <InputShort placeholder="대리점명" ref={searchInput} onKeyUp={
                        (e) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                            handleSearchClick();
                            }
                        }}/>
                    <BtnSearch onClick={handleSearchClick}>
                        <img src={icSearch} alt="" />
                        검색
                    </BtnSearch>
                </ListInputs>
                <Block50>
                    <ListTableWrapper>
                        <table>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>No.</th>
                                    <th>구분</th>
                                    <th>대리점명</th>
                                    <th>매니저</th>
                                    <th>디자이너</th>
                                    <th>매장</th>
                                    <th>딜러</th>
                                    <th>결제</th>
                                    <th>정산율</th>
                                    <th>정산</th>
                                    <th>
                                      정산상태
                                    </th>
                                    <th>결제일</th>
                                </tr>
                                <tr>
                                    <th colSpan={12} style={{ borderTop: '1px solid #757575' }}>메모</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.docs.length ? (
                                    data.docs.map((item, index) => {
                                      return (
                                        <>
                                          <tr key={item._id}>
                                              <td rowSpan={2}>
                                                  {data.totalDocs -
                                                      (page - 1) * pageLimit -
                                                      index}
                                              </td>
                                              <td>
                                                  {item.product.type === 'free' ? '무료' : '유료'}
                                              </td>
                                              <td>{item.designer?.manager?.region?.name}</td>
                                              <td>{item.designer?.manager?.name}</td>
                                              <td>{item.designer?.title}</td>
                                              <td>{item.designer?.shop?.name}</td>
                                              <td>{item.designer?.dealer?.name}</td>
                                              <td>{item.product.type !== 'free' ? item.price.toLocaleString() : ''}</td>
                                              <td>{item.product.type !== 'free' ? item.commission_percent : ''}</td>
                                              <td>{item.product.type !== 'free' ? item.commission.toLocaleString() : ''}</td>
                                              <td>
                                                {item.product.type !== 'free' ? item.status === 'confirmed' ? '승인' : item.status === 'canceled' ? '취소' : '대기' : ''}
                                                {item.product.type !== 'free' && item.status === 'pending' && <BtnXsm
                                                    $ml="0.77rem"
                                                    $bg="#3D5873"
                                                    $bc="#6946B1"
                                                    $col="#fff"
                                                    onClick={() => {
                                                       openApproveModal(item);
                                                    }}
                                                >
                                                    정산
                                                </BtnXsm>}
                                              </td>
                                              <td>
                                                <Moment format="YYYY.MM.DD HH:mm">
                                                  {item.created}
                                                </Moment>
                                              </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={12} style={{textAlign:'left'}}>
                                              <InputShort placeholder="메모" defaultValue={item.memo} ref={setRef(`${item._id}_memo`)}/>
                                              <BtnXsm
                                                    $ml="0.77rem"
                                                    $bg="#3D5873"
                                                    $bc="#6946B1"
                                                    $col="#fff"
                                                    onClick={() => {
                                                         // save memo
                                                        updateSubscription({
                                                           id: item._id,
                                                           memo: getRef(`${item._id}_memo`).current.value,
                                                        });
                                                    }}
                                                >
                                                    저장
                                                </BtnXsm>
                                            </td>
                                          </tr>
                                          </>
                                      );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="13">
                                            {isLoading ? (
                                                <ClipLoader
                                                    color={'#00bf9d'}
                                                    size={35}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            ) : (
                                                '데이터가 없습니다.'
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </ListTableWrapper>
                    {!isLoading && (
                        <ReactPaginate
                            className="paging"
                            breakLabel="..."
                            nextLabel={<span className="paging_end pl30"></span>}
                            onPageChange={handlePageClick}
                            forcePage={page - 1}
                            pageRangeDisplayed={3}
                            pageCount={data?.totalPages}
                            previousLabel={<span className="paging_first pl30"></span>}
                            renderOnZeroPageCount={null}
                            activeLinkClassName="paging_num paging_current"
                            pageLinkClassName="paging_num"
                            breakLinkClassName="paging_num"
                            previousLinkClassName="paging_num"
                            nextLinkClassName="paging_num"
                            breakClassName="paging_break"
                        />
                    )}
                </Block50>
            </SysContainer>
            <Modal style={msgStyle} isOpen={modalApproveIsOpen} onRequestClose={closeApproveModal}>
                <BtnModalClose onClick={closeApproveModal}>
                    <img src={icClose} alt="모달 닫기" />
                </BtnModalClose>
                <Block>
                    <Fz20Bold $mb="1.538rem">정산처리 하시겠습니까?</Fz20Bold>
                </Block>
                <ModalAbsolBtn>
                    <FlxbtFull>
                        <BtnHalfModal $bg="#F33535" onClick={closeApproveModal}>
                            아니요
                        </BtnHalfModal>
                        <BtnHalfModal onClick={() => handleApprove(selectedItem?._id)}>
                            예
                        </BtnHalfModal>
                    </FlxbtFull>
                </ModalAbsolBtn>
            </Modal>
        </>
    );
}
const msgStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,.8)',
        zIndex: 20,
    },
    content: {
        overflowY: 'auto',
        top: '50%',
        bottom: 'unset',
        left: '50%',
        width: '100%',
        maxWidth: 550,
        height: 'auto',
        transform: 'translate(-50%,-50%)',
        transition: 'left 1s',
        padding: '3.846rem 3.077rem',
        border: 0,
        zIndex: 20,
        textAlign: 'center',
    },
};
export default LicenseHistory;
