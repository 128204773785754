import React, { useEffect, useState } from 'react';
import Chkbox from '@/components/Chkbox';
import {
    Block50,
    SysContainer,
    Fz30Bold,
    Fz15,
    InputShort,
    BtnSearch,
    BtnMd,
    ListTableWrapper,
    BtnTable,
    BottomBtns,
    Gap30,
    FlxbtFullMb30,
    ColRed,
    ColPurple,
    ColAccent,
    FlxMiddle,
    ChkAndTxt,
    TdBtn,
    TdSm,
} from '@/css/admin/style';
import icSearch from '@/assets/img/ic_search.svg';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useGetFaqsQuery, useDeleteFaqMutation } from '@services/faq';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function Faqs() {
    const searchInput = React.createRef();

    const [checkList, setCheckList] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [searchCategories, setSearchCategories] = useState([]);
    const [page, setPage] = useState(1);

    const [pageLimit, setPageLimit] = useState(10);

    const navigate = useNavigate();

    const { data, isLoading, error, refetch } = useGetFaqsQuery(
        { page, limit: pageLimit, searchText, searchCategories: searchCategories.join(',') },
        { refetchOnMountOrArgChange: true }
    );
    const [deleteFaq] = useDeleteFaqMutation();

    useEffect(() => {
        console.log(searchCategories);
    }, [searchCategories]);

    const handleSearch = () => {
        const text = searchInput.current.value;
        setPage(1);
        setSearchText(text);
    };

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
    };

    const handleChange = (e) => {
        const { value, checked } = e.target;

        if (checked) {
            setSearchCategories(
                value ? [...searchCategories, value] : ['common', 'user', 'designer', 'shop', 'etc']
            );
        } else {
            setSearchCategories(
                value ? searchCategories.filter((category) => category !== value) : []
            );
        }
    };

    const handleNew = () => {
        navigate('/faqs/new');
    };

    const handleEdit = (id) => {
        navigate(`/faqs/${id}/edit`);
    };

    const handleDelete = (id) => {
        confirmAlert({
            message: '정말 삭제하시겠습니까?',
            buttons: [
                {
                    label: '삭제',
                    onClick: () => {
                        deleteFaq(id)
                            .unwrap()
                            .then(() => {
                                toast.success('삭제되었습니다.');
                                refetch();
                            })
                            .catch((err) => {
                                toast.error(err.data.message);
                            });
                    },
                },
                {
                    label: '취소',
                    onClick: () => {},
                },
            ],
        });
    };

    return (
        <SysContainer>
            <Fz30Bold $mb="3.077rem">FAQ</Fz30Bold>
            <FlxbtFullMb30>
                <FlxMiddle>
                    <Fz15>상태</Fz15>
                    <Gap30 />
                    <ChkAndTxt>
                        <Chkbox
                            value=""
                            onChange={handleChange}
                            checked={searchCategories.length === 5}
                        />
                        전체
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            value="common"
                            onChange={handleChange}
                            checked={searchCategories.includes('common')}
                        />
                        공통
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            value="user"
                            onChange={handleChange}
                            checked={searchCategories.includes('user')}
                        />
                        고객
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            value="designer"
                            onChange={handleChange}
                            checked={searchCategories.includes('designer')}
                        />
                        디자이너
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            value="shop"
                            onChange={handleChange}
                            checked={searchCategories.includes('shop')}
                        />
                        매장
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            value="etc"
                            onChange={handleChange}
                            checked={searchCategories.includes('etc')}
                        />
                        기타
                    </ChkAndTxt>
                </FlxMiddle>
                <FlxMiddle>
                    <InputShort placeholder="제목 / 내용을 입력해 주세요." ref={searchInput} onKeyUp={
                    (e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                          handleSearch();
                        }
                    }}/>
                    <Gap30 />
                    <BtnSearch onClick={handleSearch}>
                        <img src={icSearch} alt="" />
                        검색
                    </BtnSearch>
                </FlxMiddle>
            </FlxbtFullMb30>
            <Block50>
                <ListTableWrapper>
                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>체크</th>
                                <th>분류</th>
                                <th>제목</th>
                                <th>등록일</th>
                                <th>조회</th>
                                <th>편집</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.docs.length ? (
                                data.docs.map((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                {data.totalDocs - (page - 1) * pageLimit - index}
                                            </td>
                                            <TdSm>
                                                <Chkbox
                                                    checked={checkList.includes(item.id)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setCheckList([...checkList, item.id]);
                                                        } else {
                                                            setCheckList(
                                                                checkList.filter(
                                                                    (id) => id !== item.id
                                                                )
                                                            );
                                                        }
                                                    }}
                                                    disabled={item.deleted}
                                                />
                                            </TdSm>
                                            <td>
                                                {item.category === 'common' && '공통'}
                                                {item.category === 'user' && '고객'}
                                                {item.category === 'designer' && '디자이너'}
                                                {item.category === 'shop' && '매장'}
                                                {item.category === 'etc' && '기타'}
                                            </td>
                                            <td className="title">{item.title}</td>
                                            <td>
                                                <Moment format="YYYY-MM-DD HH:mm:ss">
                                                    {item.createdAt}
                                                </Moment>
                                            </td>
                                            <TdSm>{item.view_count}</TdSm>
                                            <TdBtn>
                                                <BtnTable onClick={() => handleEdit(item.id)}>
                                                    수정
                                                </BtnTable>
                                            </TdBtn>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="7">
                                        {isLoading ? (
                                            <ClipLoader
                                                color={'#00bf9d'}
                                                size={35}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        ) : (
                                            '데이터가 없습니다.'
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {!isLoading && (
                        <ReactPaginate
                            className="paging"
                            breakLabel="..."
                            nextLabel={<span className="paging_end pl30"></span>}
                            onPageChange={handlePageClick}
                            forcePage={page - 1}
                            pageRangeDisplayed={3}
                            pageCount={data?.totalPages}
                            previousLabel={<span className="paging_first pl30"></span>}
                            renderOnZeroPageCount={null}
                            activeLinkClassName="paging_num paging_current"
                            pageLinkClassName="paging_num"
                            breakLinkClassName="paging_num"
                            previousLinkClassName="paging_num"
                            nextLinkClassName="paging_num"
                            breakClassName="paging_break"
                        />
                    )}
                </ListTableWrapper>
            </Block50>
            <BottomBtns>
                <BtnMd onClick={handleNew}>신규 등록</BtnMd>
                <BtnMd $bg="#F33535" onClick={handleDelete}>
                    선택 삭제
                </BtnMd>
            </BottomBtns>
        </SysContainer>
    );
}

export default Faqs;
