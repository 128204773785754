import { apiService } from "./api";

const boardApi = apiService.injectEndpoints({
  endpoints: builder => ({
    uploadEditorImage: builder.mutation({
      query: (data) => ({
        url: '/files/editor',
        method: 'POST',
        body: data
      })
    }),
  }),
});

export const { useUploadEditorImageMutation } = boardApi;