import React, { useEffect, useRef, useState } from 'react';
import {
  TitleLine,
  SysContainer,
  Input,
  FieldGroup,
  InputItem,
  FormBorderb,
  BottomBtnsRight,
  BtnSm,
  BtnMd,
  Fz20Bold,
} from '@/css/admin/style';
import { Link, useNavigate } from 'react-router-dom';
import { useCheckEmailQuery, useCheckPhoneQuery } from '@services/auth';
import { useCheckRegionQuery } from '@services/region';
import { useCreateManagerMutation } from '@services/manager';
import { toast } from 'react-toastify';

function Manager() {
  const inputRegion = useRef(null);
  const inputUserId = useRef(null);
  const inputPhone = useRef(null);
  const inputTel = useRef(null);

  const [region, setRegion] = useState('');
  const [userId, setUserId] = useState('');
  const [isCheckRegion, setIsCheckRegion] = useState(false);
  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [commissionRate, setCommissionRate] = useState(0);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [nickName, setNickName] = useState('');
  const [phone, setPhone] = useState('');
  const [tel, setTel] = useState('');
  const [bank, setBank] = useState({
    name: '',
    account: '',
    holder: ''
  })
  const [isCheckPhone, setIsCheckPhone] = useState(false);
  // const [approved, setApproved] = useState(false);
  
  const navigate = useNavigate();

  const { data: emailData, isLoading: emailIsLoading, error: emailError } = useCheckEmailQuery(userId, { skip: !userId });

  const { data: phoneData, isLoading: phoneIsLoading, error: phoneError } = useCheckPhoneQuery(phone, { skip: !phone });

  const { data: regionData, isLoading: regionIsLoading, error: regionError } = useCheckRegionQuery(region, { skip: !region });

  const [createManager, { isLoading, error: createManagerError }] = useCreateManagerMutation();

  const handleCreateManager = async () => {
    if (!inputRegion.current.value) {
      toast.error('대리점명을 입력해주세요.');
      inputRegion.current.focus();
      return;
    }

    if (!isCheckRegion) {
      toast.error('대리점명 중복체크를 해주세요.');
      return;
    }

    if (!inputUserId.current.value) {
      toast.error('아이디를 입력해주세요.');
      inputUserId.current.focus();
      return;
    }

    if (!isCheckEmail) {
      toast.error('아이디 중복체크를 해주세요.');
      return;
    }

    if (!password) {
      toast.error('비밀번호를 입력해주세요.');
      return;
    }

    if (!confirmPassword) {
      toast.error('비밀번호 확인을 입력해주세요.');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('비밀번호가 일치하지 않습니다.');
      return;
    }

    if (!name) {
      toast.error('성명을 입력해주세요.');
      return;
    }

    if (!phone) {
      toast.error('휴대폰 번호를 입력해주세요.');
      inputPhone.current.focus();
      return;
    }

    if (!tel) {
      toast.error('고객과 소통할 대표번호를 입력해주세요.');
      inputTel.current.focus();
      return;
    }

    if (!isCheckPhone) {
      toast.error('휴대폰 번호 중복체크를 해주세요.');
      return;
    }

    await createManager({
      region_name: region.trim(),
      commission_rate: commissionRate,
      user_id: userId.trim(),
      password: password.trim(),
      confirm_password: confirmPassword.trim(),
      name: name.trim(),
      nickname: nickName.trim(),
      phone: phone.trim(),
      tel: tel.trim(),
      bank: bank
    });

    toast.success('대리점을 추가하였습니다.');
    navigate('/managers');
  }

  useEffect(() => {
    if(emailError?.data?.message) {
      inputUserId.current.focus();
      inputUserId.current.value = '';
      toast.error(emailError?.data?.message);
    } else if(emailData) {
      setIsCheckEmail(true);
      toast.success('사용 가능한 이메일입니다.');
    }
  }, [emailData, emailError]);


  useEffect(() => {
    if(phoneError?.data?.message) {
      inputPhone.current.focus();
      inputPhone.current.value = '';
      toast.error(phoneError?.data?.message);
    } else if(phoneData) {
      setIsCheckPhone(true);
      toast.success('사용 가능한 휴대폰 번호입니다.');
    }
  }, [phoneData, phoneError]);

  useEffect(() => {
    if(regionError?.data?.message) {
      inputRegion.current.focus();
      inputRegion.current.value = '';
      toast.error(regionError?.data?.message);
    } else if(regionData) {
      setIsCheckRegion(true);
      toast.success('사용 가능한 대리점입니다.');
    }
  }, [regionData, regionError]);

  useEffect(() => {
    if(createManagerError?.data?.message) {
      toast.error(createManagerError?.data?.message);
    }
  }, [createManagerError, ]);

  const handleCheckEmail = () => {
    if (!inputUserId.current.value) {
      toast.error('아이디를 입력해주세요.');
      inputUserId.current.focus();
      return;
    }
    setUserId(inputUserId.current.value.trim());
  }

  const handleCheckPhone = () => {
    if (!inputPhone.current.value) {
      inputPhone.current.focus();
      toast.error('휴대폰 번호를 입력해주세요.');
      return;
    }
    setPhone(inputPhone.current.value.trim());
  }

  const handleCheckRegion = () => {
    if (!inputRegion.current.value) {
      inputRegion.current.focus();
      toast.error('대리점명을 입력해주세요.');
      return;
    }
    setRegion(inputRegion.current.value.trim());
  }

  return (
    <>
      <SysContainer>
          <form>
              <Fz20Bold>대리점 정보</Fz20Bold>
              <TitleLine />
              <FieldGroup $mb="6rem">
                <InputItem>
                  <span>대리점명</span>
                  <Input type="text" ref={inputRegion} onChange={()=>setIsCheckRegion(false)}/>
                  <BtnSm $bg={isCheckRegion?"#fff":"#00BF9D"} $col={isCheckRegion?"#00BF9D":"#fff"} onClick={handleCheckRegion}>
                    {isCheckRegion?"확인완료":"중복체크"}
                  </BtnSm>
                </InputItem>
                <InputItem>
                  <span>정산비율</span>
                  <Input type="text" onChange={e=>setCommissionRate(e.target.value)} min={0} max={100} step={1}/>%
                </InputItem>
              </FieldGroup>
              <Fz20Bold>담당자 정보</Fz20Bold>
              <TitleLine />
              <FieldGroup $mb="3rem">
                  <InputItem>
                    <span>이메일(ID)</span>
                    <Input type="email" ref={inputUserId} onChange={()=>setIsCheckEmail(false)}/>
                    <BtnSm $bg={isCheckEmail?"#fff":"#00BF9D"} $col={isCheckEmail?"#00BF9D":"#fff"} onClick={handleCheckEmail}>
                      {isCheckEmail?"확인완료":"중복체크"}
                    </BtnSm>
                  </InputItem>
                  <InputItem>
                    <span>휴대폰 번호</span>
                    <Input type="text" ref={inputPhone} onChange={()=>setIsCheckPhone(false)} placeholder="디자이너 가입시 안내번호 입니다."/>
                    <BtnSm $bg={isCheckPhone?"#fff":"#00BF9D"} $col={isCheckPhone?"#00BF9D":"#fff"} onClick={handleCheckPhone}>
                      {isCheckPhone?"확인완료":"중복체크"}
                    </BtnSm>
                  </InputItem>
                  <InputItem>
                    <span>성명</span>
                    <Input type="text" onChange={e=>setName(e.target.value)} placeholder="이름"/>
                  </InputItem>
                  <InputItem>
                    <span>닉네임</span>
                    <Input type="text" onChange={e=>setNickName(e.target.value)} placeholder="닉네임"/>
                  </InputItem>
                  <InputItem>
                    <span>대표 번호</span>
                    <Input type="text" ref={inputTel} onChange={e=>setTel(e.target.value)} placeholder="디자이너 페이지에 노출되는 연락번호 입니다."/>
                  </InputItem>
                  <InputItem $wd="100%">
                    <span>은행</span>
                    <Input type="text" onChange={e=>setBank({
                      ...bank,
                      name: e.target.value
                    })} placeholder="은행명"/>
                    <Input type="text" onChange={e=>setBank({
                      ...bank,
                      account: e.target.value
                    })} placeholder="계좌번호"/>
                    <Input type="text" onChange={e=>setBank({
                      ...bank,
                      holder: e.target.value
                    })} placeholder="예금주명"/>
                  </InputItem>
                  <InputItem>
                    <span>패스워드</span>
                    <Input type="password" onChange={e=>setPassword(e.target.value)} placeholder="이름"/>
                  </InputItem>
                  <InputItem>
                    <span>패스워드 확인</span>
                    <Input type="password" onChange={e=>setConfirmPassword(e.target.value)} placeholder="이름"/>
                  </InputItem>
                  {/* <InputItem>
                    <span>상태</span>
                    <Select onChange={e=>setApproved(e.target.value)}>
                      <option value={true} selected={approved}>승인</option>
                      <option value={false} selected={!approved}>대기</option>
                    </Select>
                  </InputItem>                   */}
              </FieldGroup>
              <FormBorderb />
              <BottomBtnsRight>
                <Link to="/managers">
                  <BtnMd $bg="#fff" $bc="#00BF9D" $col="#00BF9D">목록</BtnMd>
                </Link>
                <BtnMd type="button" onClick={handleCreateManager}>추가</BtnMd>
              </BottomBtnsRight>
          </form>
      </SysContainer>
    </>
  );
}

export default Manager;
