const email = (value) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex.test(value);
}

const password = (value) => {
  const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  return regex.test(value);
}

const passwordConfirm = (value, password) => {
  return value === password;
}

const name = (value) => {
  const regex = /^[가-힣]{2,4}$/;
  return regex.test(value);
}

const phone = (value) => {
  const regex = /^\d{3}-\d{3,4}-\d{4}$/;
  return regex.test(value);
}

const birth = (value) => {
  const regex = /^\d{6}$/;
  return regex.test(value);
}

const code = (value) => {
  const regex = /^\d{6}$/;
  return regex.test(value);
}

const number = (value) => {
  const regex = /^\d+$/;
  return regex.test(value);
}

export default {
  email,
  password,
  passwordConfirm,
  name,
  phone,
  birth,
  code,
  number
}