import React from 'react';
import {
    TitleLine,
    SysContainer,
    Input,
    FieldGroup,
    InputItem,
    FormBorderb,
    BottomBtnsRight,
    Textbox,
    BtnMd,
    BtnMdSubmit,
    Fz20Bold,
    GroupDivider,
} from '@/css/admin/style';

function QnaWrite() {
    return (
        <>
            <SysContainer>
                <form>
                    <Fz20Bold>문의 관리</Fz20Bold>
                    <TitleLine />
                    <FieldGroup $mb="0">
                        <InputItem>
                            <span>작성일</span>
                            <Input type="text" $wd="26.538rem" value="23.12.28 18:12:13" disabled />
                        </InputItem>
                        <InputItem>
                            <span>작성자</span>
                            <Input type="text" disabled />
                        </InputItem>
                        <InputItem>
                            <span>분류</span>
                            <Input type="text" disabled />
                        </InputItem>
                        <InputItem>
                            <span>조회</span>
                            <Input type="text" disabled />
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>제목</span>
                            <Input type="text" disabled />
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>내용</span>
                            <Textbox rows="10" disabled />
                        </InputItem>
                    </FieldGroup>
                    <GroupDivider />
                    <FieldGroup>
                        {/* 메모: 기획에는 상태값 변경이 있는데 불필요한듯하여 뺌
                        <InputItem>
                            <span>상태</span>
                            <Select>
                                <option>답변대기</option>
                                <option>답변완료</option>
                            </Select>
                        </InputItem> */}
                        <InputItem>
                            <span>작성일</span>
                            <Input type="text" $wd="26.538rem" value="23.12.28 18:12:13" disabled />
                        </InputItem>
                        <InputItem>
                            <span>작성자</span>
                            <Input type="text" disabled />
                        </InputItem>
                        <InputItem>
                            <span>조회</span>
                            <Input type="text" disabled />
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>답변 제목</span>
                            <Input type="text" />
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>내용</span>
                            <Textbox rows="10" />
                        </InputItem>
                    </FieldGroup>

                    <FormBorderb />
                    <BottomBtnsRight>
                        <BtnMd $bg="#fff" $bc="#00BF9D" $col="#00BF9D">
                            목록
                        </BtnMd>
                        <BtnMdSubmit>수정</BtnMdSubmit>
                    </BottomBtnsRight>
                </form>
            </SysContainer>
        </>
    );
}

export default QnaWrite;
