import { apiService } from "./api";

export const regionApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getRegions: builder.query({
      query: ({ page, limit, searchText }) => ({
        url: `/regions?page=${page}&text=${searchText || ''}&limit=${limit}`,
        method: 'GET',
      })
    }),
    getRegion: builder.query({
      query: id => ({
        url: `/regions/${id}`,
        method: 'GET',
      })
    }),
    createRegion: builder.mutation({
      query: region => ({
        url: '/regions',
        method: 'POST',
        body: region,
      })
    }),
    updateRegion: builder.mutation({
      query: ({ id, data }) => ({
        url: `/regions/${id}`,
        method: 'PUT',
        body: data,
      })
    }),
    deleteRegion: builder.mutation({
      query: id => ({
        url: `/regions/${id}`,
        method: 'DELETE',
      })
    }),
    checkRegion: builder.query({
      query: name => ({
        url: `/regions/${name}/check`,
        method: 'GET',
      })
    }),
  }),
});

export const { useGetRegionsQuery, useGetRegionQuery, useCreateRegionMutation, useUpdateRegionMutation, useDeleteRegionMutation, useCheckRegionQuery } = regionApi;