import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Chkbox from '@/components/Chkbox';
import RadioBox from '@/components/RadioBox';
import {
    Block50,
    SysContainer,
    Fz30Bold,
    Fz15,
    InputShort,
    BtnSearch,
    BtnMd,
    Select,
    ListTableWrapper,
    BottomBtns,
    BtnXsm,
    TxtDivider2,
    FlxMiddleMb20,
    Gap30,
    ListInputs,
    ColGray1,
    ColRed,
    ColPurple,
    ColAccent,
    ChkAndTxt,
    InputItem,
    Fz20Bold,
    SearchWrapper,
    ShopItem,
    ModalAbsolBtn,
    BtnFullModal,
    BtnModalClose,
    ContentDivider,
    Fz16Medium,
    Block,
    BtnHalfModal,
    FlxbtFull,
} from '@/css/admin/style';
import icSearch from '@/assets/img/ic_search.svg';
import icClose from '@/assets/img/ic_close_modal.svg';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { useNavigate, useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { FaExternalLinkAlt } from 'react-icons/fa';
import {
    useGetDesignersQuery,
    useApproveDesignerMutation,
    useDisapproveDesignerMutation,
    useApplyFreeSubscriptionMutation,
    useUpdateDesignerDealerMutation
} from '@services/designer';
import Config from '@/config';

const FreeSubscriptionDayList = [
  { value: 30, label: '1개월' },
  { value: 60, label: '2개월' },
  { value: 90, label: '3개월' },
  { value: 120, label: '4개월' },
  { value: 150, label: '5개월' },
  { value: 180, label: '6개월' },
]

function Designers() {
    const searchInput = React.createRef();
    const searchParams = new URLSearchParams(useLocation().search);

    const [selectedDesigner, setSelectedDesigner] = useState(null);
    const [modalApproveIsOpen, setApproveIsOpen] = React.useState(false);
    const [modalDisapproveIsOpen, setDisapproveIsOpen] = React.useState(false);
    const [modalApplyFreeSubscription, setApplyFreeSubscription] = React.useState(false);
    const [freeSubscriptionDays, setFreeSubscriptionDays] = useState(30);
    const [searchText, setSearchText] = useState(searchParams.get('searchText') || '');
    const [searchStatus, setSearchStatus] = useState(searchParams.get('searchStatus') || '');
    const [page, setPage] = useState(searchParams.get('page') || 1);

    const [pageLimit, setPageLimit] = useState(searchParams.get('pageLimit') || 10);

    const navigate = useNavigate();

    const { data, isLoading, error, refetch } = useGetDesignersQuery(
        { page, limit: pageLimit, searchText, searchStatus },
        { refetchOnMountOrArgChange: true }
    );
    const [approveDesigner, { isLoading: approveIsLoading, error: approveError }] =
        useApproveDesignerMutation();
    const [disapproveDesigner, { isLoading: disapproveIsLoading, error: disapproveError }] =
        useDisapproveDesignerMutation();
    const [applyFreeSubScription, { isLoading: applyFreeSubscriptionIsLoading, error: applyFreeSubscriptionError }] = 
        useApplyFreeSubscriptionMutation();
    const [updateDesignerDealer, { isLoading: updateDesignerDealerIsLoading, error: updateDesignerDealerError }] = 
        useUpdateDesignerDealerMutation();

    useEffect(() => {
        const url = new URL(window.location);
        url.searchParams.set('page', page);
        url.searchParams.set('searchText', searchText);
        url.searchParams.set('searchStatus', searchStatus);
        window.history.pushState({}, '', url);
    }, [page, searchText, searchStatus]);

    const handleSearchClick = () => {
        const text = searchInput.current.value;
        setPage(1);
        setSearchText(text);
    };

    const handlePageClick = (e) => {
        const { selected } = e;
        setPage(selected + 1);
    };

    const handleSearchStatus = (status) => {
        setPage(1);
        setSearchStatus(status);
    };

    const handleApprove = async (id) => {
        try {
            await approveDesigner(id);
            toast.success('디자이너를 승인하였습니다.');
            refetch();
        } catch (e) {
            toast.error('디자이너 승인에 실패하였습니다.');
        } finally {
            closeApproveModal();
        }
    };

    const handleDisapprove = async (id) => {
        try {
            await disapproveDesigner(id);
            toast.success('디자이너를 승인거절하였습니다.');
            refetch();
        } catch (e) {
            toast.error('디자이너 승인거절에 실패하였습니다.');
        } finally {
            closeDisapproveModal();
        }
    };

    const handleFreeSubscription = async (designer) => {
        try {
            await applyFreeSubScription({
                id: designer._id,
                days: freeSubscriptionDays
            });
            toast.success('디자이너 무료라이센스 적용에 성공하였습니다.');
            refetch();
        } catch (e) {
            toast.error('디자이너 무료라이센스 적용에 실패하였습니다.');
        } finally {
            closeApplyFreeSubscription();
        }
    };

    const handleUpdateDealer = async (designer_id, dealer_id) => {
        try {
            await updateDesignerDealer({
                id: designer_id,
                dealer_id
            });
            toast.success('딜러를 변경하였습니다.');
            refetch();
        } catch (e) {
            toast.error('딜러 변경에 실패하였습니다.');
        }
    }

    const openApproveModal = (designer) => {
        setSelectedDesigner(designer);
        setApproveIsOpen(true);
    };

    const openDisapproveModal = (designer) => {
        setSelectedDesigner(designer);
        setDisapproveIsOpen(true);
    };

    const openApplyFreeSubscription = (designer) => {
        setSelectedDesigner(designer);
        setApplyFreeSubscription(true);
    };

    const closeApproveModal = () => {
        setSelectedDesigner(null);
        setApproveIsOpen(false);
    };

    const closeDisapproveModal = () => {
        setSelectedDesigner(null);
        setDisapproveIsOpen(false);
    };

    const closeApplyFreeSubscription = () => {
        setSelectedDesigner(null);
        setApplyFreeSubscription(false);
        setFreeSubscriptionDays(30);
    };

    return (
        <>
            <SysContainer>
                <Fz30Bold $mb="3.077rem">디자이너 관리</Fz30Bold>
                <FlxMiddleMb20>
                    <Fz15>상태</Fz15>
                    <Gap30 />
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === ''}
                            onChange={() => {
                                handleSearchStatus('');
                            }}
                        />
                        전체
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === 'review'}
                            onChange={() => handleSearchStatus('review')}
                        />
                        심사
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === 'approved'}
                            onChange={() => handleSearchStatus('approved')}
                        />
                        승인
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === 'actived'}
                            onChange={() => handleSearchStatus('actived')}
                        />
                        판매
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === 'inactived'}
                            onChange={() => handleSearchStatus('inactived')}
                        />
                        숨김
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === 'leaved'}
                            onChange={() => handleSearchStatus('leaved')}
                        />
                        탈퇴
                    </ChkAndTxt>
                    {/* <ChkAndTxt>
                <Chkbox />
                정지
            </ChkAndTxt> */}
                    {/* <TxtDivider2 $mg="0 3.846rem" />
            <Fz15>매장상태</Fz15>
            <Gap30 />
            <ChkAndTxt>
                <Chkbox />
                전체
            </ChkAndTxt>
            <ChkAndTxt>
                <Chkbox />
                등록완료
            </ChkAndTxt>
            <ChkAndTxt>
                <Chkbox />
                미등록
            </ChkAndTxt>
            <ChkAndTxt>
                <Chkbox />
                수정요청
            </ChkAndTxt>
            <ChkAndTxt $mr="0">
                <Chkbox />
                거절
            </ChkAndTxt> */}
                </FlxMiddleMb20>
                <ListInputs>
                    {/* <Fz15>디자이너 KEY</Fz15>
            <InputShort placeholder="Code 번호를 입력해 주세요" /> */}
                    <Fz15>검색어</Fz15>
                    <InputShort placeholder="대리점 / 매장상호 / ID / 이름 / 닉네임" ref={searchInput} onKeyUp={
                    (e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                          handleSearchClick();
                        }
                    }}/>
                    <BtnSearch onClick={handleSearchClick}>
                        <img src={icSearch} alt="" />
                        검색
                    </BtnSearch>
                </ListInputs>
                <Block50>
                    <ListTableWrapper>
                        <table>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    {/* <th>체크</th> */}
                                    <th>상태</th>
                                    <th>노출</th>
                                    {/* <th>디자이너 KEY</th> */}
                                    <th>대리점</th>
                                    <th>딜러</th>
                                    <th>매장<br/><span style={{lineHeight:2, fontSize:'12px'}}>(주소)</span></th>
                                    <th>성명 / 타이틀</th>
                                    <th>ID/E.mail</th>
                                    <th>HP</th>
                                    <th>라이센스</th>
                                    <th>가입일</th>
                                    <th>접속일</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.docs.length ? (
                                    data.docs.map((item, index) => {
                                        return (
                                            <tr key={item._id}>
                                                <td>
                                                    {data.totalDocs -
                                                        (page - 1) * pageLimit -
                                                        index}
                                                </td>
                                                {/* <td>
                          {!item.deleted  && !item.approved && item?.shop?.approved ?  <Chkbox
                            checked={checkItem === item._id}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setCheckItem(item._id);
                              } else {
                                setCheckItem(null);
                              }
                            }}
                          /> : null}
                        </td> */}
                                                <td>
                                                    {/* 메모: 상태에 따라 색상 분기
                        정상
                        <ColAccent>심사</ColAccent>
                        <ColGray1>탈퇴</ColGray1>
                        <ColRed>정지</ColRed> */}
                                                    {item.deleted ? (
                                                        <ColRed>탈퇴</ColRed>
                                                    ) : !item.approved ? (
                                                        <>
                                                            <ColAccent>심사</ColAccent>
                                                            {item?.shop?.approved && (
                                                                <BtnXsm
                                                                    $ml="0.77rem"
                                                                    $bg="#3D5873"
                                                                    $bc="#6946B1"
                                                                    $col="#fff"
                                                                    onClick={() => {
                                                                        openApproveModal(item);
                                                                    }}
                                                                >
                                                                    승인
                                                                </BtnXsm>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            승인
                                                            <BtnXsm
                                                                $ml="0.77rem"
                                                                $bg="#6946B1"
                                                                $bc="#6946B1"
                                                                $col="#fff"
                                                                onClick={() => {
                                                                    openDisapproveModal(item);
                                                                }}
                                                            >
                                                                심사
                                                            </BtnXsm>
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    {!item.actived ? <ColRed>숨김</ColRed> : '판매'}
                                                </td>
                                                {/* <td width="70">
                            메모:승인거절이나 수정요청이 있을시 표시
                                <ColRed>수정요청</ColRed>
                                <ColPurple>승인거절</ColPurple>
                        </td> */}
                                                <td>{item.manager?.region?.name}</td>
                                                <td>
                                                    {item.dealers.length > 0 ? <Select defaultValue={item.dealer?._id} onChange={e=>handleUpdateDealer(item._id, e.target.value)}>
                                                        <option value=''>미선택</option>
                                                        {item.dealers?.map((dealer, index) => <option value={dealer._id} key={index}>{dealer.name}</option>)}
                                                    </Select> : null}
                                                </td>
                                                <td>
                                                    {item?.shop?.approved ? (
                                                        <>{item.shop.name}<br/><span style={{lineHeight:2, fontSize:'12px'}}>({item.shop.address} {item.shop.address_detail})</span></>
                                                    ) : item?.shop?.request &&
                                                      !item?.shop?.request_completed ? (
                                                        <>
                                                            <ColGray1>등록요청</ColGray1>
                                                            <BtnXsm
                                                                $ml="0.77rem"
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/shops/${item.shop._id}/edit`
                                                                    );
                                                                }}
                                                            >
                                                                확인
                                                            </BtnXsm>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <ColGray1>승인요청</ColGray1>
                                                            <BtnXsm
                                                                $ml="0.77rem"
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/shops/${item.shop._id}/edit`
                                                                    );
                                                                }}
                                                            >
                                                                확인
                                                            </BtnXsm>
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    {item.user.name}
                                                    {' / '}
                                                    <a href={`${Config.HOST}/designer/${item._id}`} target='_blank'>{item.title}&nbsp;<FaExternalLinkAlt/></a>
                                                </td>
                                                <td>{item.user.user_id}</td>
                                                <td>{item.user.phone}</td>
                                                <td>
                                                    {item.subscription && <Moment format="YYYY.MM.DD HH:mm">
                                                        {item.subscription_exp}
                                                    </Moment>}
                                                    {item.approved && <BtnXsm
                                                        $ml="0.77rem"
                                                        $bg="#3D5873"
                                                        $bc="#6946B1"
                                                        $col="#fff"
                                                        onClick={() => {
                                                            openApplyFreeSubscription(item);
                                                        }}
                                                    >
                                                        {item.subscription ? '무료연장' : '무료적용'}
                                                    </BtnXsm>}
                                                </td>
                                                <td>
                                                    <Moment format="YYYY.MM.DD HH:mm">
                                                        {item.created}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    <Moment format="YYYY.MM.DD HH:mm">
                                                        {item.user.last}
                                                    </Moment>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="10">
                                            {isLoading ? (
                                                <ClipLoader
                                                    color={'#00bf9d'}
                                                    size={35}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            ) : (
                                                '데이터가 없습니다.'
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </ListTableWrapper>
                    {!isLoading && (
                        <ReactPaginate
                            className="paging"
                            breakLabel="..."
                            nextLabel={<span className="paging_end pl30"></span>}
                            onPageChange={handlePageClick}
                            forcePage={page - 1}
                            pageRangeDisplayed={3}
                            pageCount={data?.totalPages}
                            previousLabel={<span className="paging_first pl30"></span>}
                            renderOnZeroPageCount={null}
                            activeLinkClassName="paging_num paging_current"
                            pageLinkClassName="paging_num"
                            breakLinkClassName="paging_num"
                            previousLinkClassName="paging_num"
                            nextLinkClassName="paging_num"
                            breakClassName="paging_break"
                        />
                    )}
                </Block50>
                <BottomBtns>
                    {/* <BtnMd>신규 등록</BtnMd>
            <BtnMd $bg="#6946B1">선택 정지</BtnMd>
            <BtnMd $bg="#F33535">선택 삭제</BtnMd> */}
                    {/* <BtnMd>신규 등록</BtnMd> */}
                    {/* <BtnMd $bg="#3D5873">선택 승인</BtnMd> */}
                    {/* <BtnMd $bg="#6946B1">선택 심사</BtnMd> */}
                    {/* <BtnMd $bg="#F33535">선택 삭제</BtnMd> */}
                </BottomBtns>
            </SysContainer>
            {/* 매장 수정 요청 모달 */}
            <Modal style={msgStyle} isOpen={modalApproveIsOpen} onRequestClose={closeApproveModal}>
                <BtnModalClose onClick={closeApproveModal}>
                    <img src={icClose} alt="모달 닫기" />
                </BtnModalClose>
                <Fz16Medium $mb="1.538rem" color="#000">
                    <ColAccent>{selectedDesigner?.title}</ColAccent>님 승인 처리
                </Fz16Medium>
                <ContentDivider />
                <Block>
                    <ColRed>{selectedDesigner?.shop?.name}매장</ColRed> 소속으로 승인하시겠습니까?
                    <div style={{ marginTop: '10px' }}>(승인시 디자이너는 노출처리 됩니다.)</div>
                </Block>
                <ModalAbsolBtn>
                    <FlxbtFull>
                        <BtnHalfModal $bg="#F33535" onClick={closeApproveModal}>
                            아니요
                        </BtnHalfModal>
                        <BtnHalfModal onClick={() => handleApprove(selectedDesigner?._id)}>
                            예
                        </BtnHalfModal>
                    </FlxbtFull>
                </ModalAbsolBtn>
            </Modal>
            <Modal
                style={msgStyle}
                isOpen={modalDisapproveIsOpen}
                onRequestClose={closeDisapproveModal}
            >
                <BtnModalClose onClick={closeDisapproveModal}>
                    <img src={icClose} alt="모달 닫기" />
                </BtnModalClose>
                <Fz16Medium $mb="1.538rem" color="#000">
                    <ColAccent>{selectedDesigner?.title}</ColAccent>님 심사 처리
                </Fz16Medium>
                <ContentDivider />
                <Block>
                    심사로 변경하시겠습니까?
                    <div style={{ marginTop: '10px' }}>
                        (심사로 변경시 디자이너는 숨김처리 됩니다.)
                    </div>
                </Block>
                <ModalAbsolBtn>
                    <FlxbtFull>
                        <BtnHalfModal $bg="#F33535" onClick={closeDisapproveModal}>
                            아니요
                        </BtnHalfModal>
                        <BtnHalfModal onClick={() => handleDisapprove(selectedDesigner?._id)}>
                            예
                        </BtnHalfModal>
                    </FlxbtFull>
                </ModalAbsolBtn>
            </Modal>
            {/* //디자이너 무료라이센스 적용 모달 */}
            <Modal style={msgStyle} isOpen={modalApplyFreeSubscription} onRequestClose={closeApplyFreeSubscription}>
                <BtnModalClose onClick={closeApplyFreeSubscription}>
                    <img src={icClose} alt="모달 닫기" />
                </BtnModalClose>
                <Fz16Medium $mb="1.538rem" color="#000">
                    <ColAccent>{selectedDesigner?.title}</ColAccent>님 무료라이센스 적용
                </Fz16Medium>
                <ContentDivider />
                <Block>
                    <InputItem $wd="100%">
                        {/* <ChkAndTxt htmlFor="freeSubscriptionDays_30">
                            <RadioBox id="freeSubscriptionDays_30" onChange={e=>setFreeSubscriptionDays(e.target.value)} checked={freeSubscriptionDays==30} value={30}/>
                                1개월
                        </ChkAndTxt>
                        <ChkAndTxt htmlFor="freeSubscriptionDays_60">
                            <RadioBox id="freeSubscriptionDays_60" onChange={e=>setFreeSubscriptionDays(e.target.value)} checked={freeSubscriptionDays==60} value={60}/>
                                2개월
                        </ChkAndTxt>
                        <ChkAndTxt htmlFor="freeSubscriptionDays_90">
                            <RadioBox id="freeSubscriptionDays_90" onChange={e=>setFreeSubscriptionDays(e.target.value)} checked={freeSubscriptionDays==90} value={90}/>
                                3개월
                        </ChkAndTxt>
                        <ChkAndTxt htmlFor="freeSubscriptionDays_120">
                            <RadioBox id="freeSubscriptionDays_120" onChange={e=>setFreeSubscriptionDays(e.target.value)}  checked={freeSubscriptionDays==120} value={120}/>
                                4개월
                        </ChkAndTxt> */}
                        {
                            FreeSubscriptionDayList.map((item, index) => {
                                return (
                                    <ChkAndTxt key={index} htmlFor={`freeSubscriptionDays_${item.value}`}>
                                        <RadioBox id={`freeSubscriptionDays_${item.value}`} onChange={e=>setFreeSubscriptionDays(e.target.value)} checked={freeSubscriptionDays==item.value} value={item.value}/>
                                        {item.label}
                                    </ChkAndTxt>
                                )
                            })
                        }
                    </InputItem>
                </Block>
                <Block>
                    <ColRed>{FreeSubscriptionDayList.find(i=>i.value==freeSubscriptionDays)?.label}</ColRed> 무료라이센스 적용하시겠습니까?
                </Block>
                <ModalAbsolBtn>
                    <FlxbtFull>
                        <BtnHalfModal $bg="#F33535" onClick={closeApplyFreeSubscription}>
                            아니요
                        </BtnHalfModal>
                        <BtnHalfModal onClick={() => handleFreeSubscription(selectedDesigner)}>
                            예
                        </BtnHalfModal>
                    </FlxbtFull>
                </ModalAbsolBtn>
            </Modal>
        </>
    );
}
const msgStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,.8)',
        zIndex: 20,
    },
    content: {
        overflowY: 'auto',
        top: '50%',
        bottom: 'unset',
        left: '50%',
        width: '100%',
        maxWidth: 550,
        height: 'auto',
        transform: 'translate(-50%,-50%)',
        transition: 'left 1s',
        padding: '3.846rem 3.077rem',
        border: 0,
        zIndex: 20,
        textAlign: 'center',
    },
};
export default Designers;
