import chkOff from '@/assets/img/chk_green_off.svg';
import chkOn from '@/assets/img/chk_green_on.svg';

const Chkbox = (props) => {
    const chkboxStyle = {
        width: '1.73rem',
        minWidth: '1.73rem',
        height: '1.73rem',
        border: 0,
        backgroundImage: props.checked ? `url(${chkOn})` : `url(${chkOff})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };

    return (
        <>
            <input
                type="checkbox"
                value={props.value}
                onChange={props.onChange}
                style={chkboxStyle}
                checked={props.checked}
                disabled={props.disabled}
            />
        </>
    );
};

export default Chkbox;
