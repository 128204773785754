import React from "react";
import { Outlet } from "react-router-dom";
import Header from "@/components/Header";
import Snb from "@/components/Snb";
import { SysWrap } from "@/css/admin/style";

const Layout = ({ children }) => {
  return (
    <SysWrap>
      <Header />
      <Snb />
      <Outlet />
    </SysWrap>
  );
};

export default Layout;
