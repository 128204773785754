import React, { useEffect, useState } from 'react';
import Chkbox from '@/components/Chkbox';
import {
    Block50,
    SysContainer,
    Fz30Bold,
    Fz15,
    InputShort,
    BtnSearch,
    BtnMd,
    ListTableWrapper,
    BtnTable,
    BottomBtns,
    TxtDivider2,
    FlxMiddleMb20,
    Gap30,
    SlashTxt,
    ListInputs,
    ColGray1,
    ColRed,
    ColPurple,
    ColAccent,
    ChkAndTxt,
    TdBtn,
} from '@/css/admin/style';
import icSearch from '@/assets/img/ic_search.svg';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { useGetManagersQuery } from '@services/manager';
import { Link, useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

function ManagerList() {
    const navigate = useNavigate();
    const searchInput = React.createRef();
    const [checkList, setCheckList] = useState([]);

    const [searchText, setSearchText] = useState(null);
    const [searchStatus, setSearchStatus] = useState('');
    const [page, setPage] = useState(1);

    const [pageLimit, setPageLimit] = useState(10);

    const { data, isLoading, error, refetch } = useGetManagersQuery(
        { page, limit: pageLimit, searchText, searchStatus },
        { refetchOnMountOrArgChange: true }
    );

    const handleSearchClick = () => {
        const text = searchInput.current.value;
        setPage(1);
        setSearchText(text);
    };

    const handlePageClick = (e) => {
        const { selected } = e;
        setPage(selected + 1);
    };

    const handleSearchStatus = (status) => {
        setPage(1);
        setSearchStatus(status);
    };

    return (
        <SysContainer>
            <Fz30Bold $mb="3.077rem">대리점 관리</Fz30Bold>
            <FlxMiddleMb20>
                <Fz15>상태</Fz15>
                <Gap30 />
                <ChkAndTxt>
                    <Chkbox
                        checked={searchStatus === ''}
                        onChange={() => {
                            handleSearchStatus('');
                        }}
                    />
                    전체
                </ChkAndTxt>
                {/* <ChkAndTxt>
              <Chkbox checked={searchStatus==='joined'} onChange={()=>handleSearchStatus('joined')}/>
              정상
          </ChkAndTxt> */}
                <ChkAndTxt>
                    <Chkbox
                        checked={searchStatus === 'leaved'}
                        onChange={() => handleSearchStatus('leaved')}
                    />
                    탈퇴
                </ChkAndTxt>
                {/* <ChkAndTxt>
              <Chkbox />
              정지
          </ChkAndTxt> */}
            </FlxMiddleMb20>
            <ListInputs>
                {/* <Fz15>디자이너 KEY</Fz15>
            <InputShort placeholder="Code 번호를 입력해 주세요" /> */}
                {/* <Fz15>담당지역</Fz15>
          <SelectShort>
              <option disabled selected>
                  전체
              </option>
              <option>서울</option>
          </SelectShort> */}
                <Fz15>검색어</Fz15>
                <InputShort placeholder="대리점명 / 담당자 성명,아이디,휴대폰" ref={searchInput} onKeyUp={
                    (e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                          handleSearchClick();
                        }
                    }}/>
                <BtnSearch onClick={handleSearchClick}>
                    <img src={icSearch} alt="" />
                    검색
                </BtnSearch>
            </ListInputs>
            <Block50>
                <ListTableWrapper>
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan={2}>No.</th>
                                {/* <th>체크</th> */}
                                <th rowSpan={2}>상태</th>
                                {/* <th>디자이너 KEY</th> */}
                                <th rowSpan={2}>대리점명</th>
                                <th rowSpan={2}>정산비율</th>
                                <th colSpan={3}>담당자</th>
                                <th rowSpan={2}>디자이너</th>
                                <th rowSpan={2}>매장</th>
                                <th rowSpan={2}>등록일</th>
                                <th rowSpan={2}>편집</th>
                            </tr>
                            <tr>
                                <th style={{ borderTop: '1px solid #757575' }}>성명</th>
                                <th
                                    style={{
                                        borderTop: '1px solid #757575',
                                        borderLeft: '1px solid #757575',
                                        borderRight: '1px solid #757575',
                                    }}
                                >
                                    이메일(ID)
                                </th>
                                <th style={{ borderTop: '1px solid #757575' }}>HP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.docs.length ? (
                                data.docs.map((item, index) => {
                                    return (
                                        <tr key={item._id}>
                                            <td>
                                                {data.totalDocs - (page - 1) * pageLimit - index}
                                            </td>
                                            <td>{item.deleted ? <ColRed>탈퇴</ColRed> : '정상'}</td>
                                            <td>{item.region?.name}</td>
                                            <td>{item.region?.commission_rate || 0}%</td>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>{item.user_id}</td>
                                            <td>{item.phone}</td>
                                            <SlashTxt>
                                                {/* 메모: 왼쪽부터 정상회원, 승인대기회원, 매장정보 수정 요청 회원 */}
                                                <span>{item.activeDesignerCount}명</span>
                                                <ColAccent>
                                                    {item.inactiveDesignerCount}명
                                                </ColAccent>
                                                {/* <ColPurple>1명</ColPurple> */}
                                            </SlashTxt>
                                            <SlashTxt>
                                                {/* 메모: 왼쪽부터 등록된 매장 수, 등록중 대기중인 매장의 수 */}
                                                <span>{item.activeShopCount}</span>
                                                <span style={{ color: '#FF0000' }}>
                                                    {item.inactiveShopCount}
                                                </span>
                                            </SlashTxt>
                                            <td>
                                                <Moment format="YYYY.MM.DD">{item.created}</Moment>
                                            </td>
                                            <TdBtn>
                                                {!item.deleted && <Link to={`/managers/${item._id}/edit`}>
                                                    <BtnTable>수정</BtnTable>
                                                </Link>}
                                            </TdBtn>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="9">
                                        {isLoading ? (
                                            <ClipLoader
                                                color={'#00bf9d'}
                                                size={35}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        ) : (
                                            '데이터가 없습니다.'
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </ListTableWrapper>
                {!isLoading && (
                    <ReactPaginate
                        className="paging"
                        breakLabel="..."
                        nextLabel={<span className="paging_end pl30"></span>}
                        onPageChange={handlePageClick}
                        forcePage={page - 1}
                        pageRangeDisplayed={3}
                        pageCount={data?.totalPages}
                        previousLabel={<span className="paging_first pl30"></span>}
                        renderOnZeroPageCount={null}
                        activeLinkClassName="paging_num paging_current"
                        pageLinkClassName="paging_num"
                        breakLinkClassName="paging_num"
                        previousLinkClassName="paging_num"
                        nextLinkClassName="paging_num"
                        breakClassName="paging_break"
                    />
                )}
            </Block50>
            <BottomBtns>
                <BtnMd onClick={() => navigate('/managers/new')}>신규 등록</BtnMd>
                {/* <BtnMd $bg="#3D5873">선택 승인</BtnMd> */}
                {/* <BtnMd $bg="#6946B1">선택 정지</BtnMd> */}
                {/* <BtnMd $bg="#F33535">선택 탈퇴</BtnMd> */}
            </BottomBtns>
        </SysContainer>
    );
}

export default ManagerList;
