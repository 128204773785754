import { apiService } from "./api";

const faqApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getFaqs: builder.query({
      query: ({ page, limit, searchText, searchCategories }) => ({
        url: `/boards/faq?page=${page}&text=${searchText || ''}&limit=${limit}&categories=${searchCategories}`,
        method: 'GET',
      })
    }),
    getFaq: builder.query({
      query: id => ({
        url: `/boards/faq/${id}`,
        method: 'GET',
      })
    }),
    createFaq: builder.mutation({
      query: faq => ({
        url: '/boards/faq',
        method: 'POST',
        body: faq,
      })
    }),
    updateFaq: builder.mutation({
      query: ({ id, data }) => ({
        url: `/boards/faq/${id}`,
        method: 'PUT',
        body: data,
      })
    }),
    deleteFaq: builder.mutation({
      query: id => ({
        url: `/boards/faq/${id}`,
        method: 'DELETE',
      })
    })
  }),
});

export const { useGetFaqsQuery, useGetFaqQuery, useCreateFaqMutation, useUpdateFaqMutation, useDeleteFaqMutation } = faqApi;