import { apiService } from "./api";

export const userApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query({
      query: ({ page, limit, searchText, searchStatus }) => ({
        url: `/users?page=${page}&text=${searchText || ''}&status=${searchStatus || ''}&limit=${limit}`,
        method: 'GET',
      }),
    }),
    getUser: builder.query({
      query: id => ({
        url: `/users/${id}`,
        method: 'GET',
      })
    }),
    createUser: builder.mutation({
      query: user => ({
        url: '/users',
        method: 'POST',
        body: user,
      })
    }),
    updateUser: builder.mutation({
      query: ({id, data}) => {
        console.log('data', data)
        return ({
          url: `/users/${id}`,
          method: 'PUT',
          body: data,
        })
      }
    }),
    deleteUser: builder.mutation({
      query: id => ({
        url: `/users/${id}`,
        method: 'DELETE',
      })
    }),
  }),
});

export const { useGetUsersQuery, useGetUserQuery, useCreateUserMutation, useUpdateUserMutation, useDeleteUserMutation } = userApi;