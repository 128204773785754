import { apiService } from "./api";

export const advertisementApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getAdvertisements: builder.query({
      query: ({ page, limit, type, searchText, searchStatus, from_date, to_date }) => ({
        url: `/advertisements?type=${type}&page=${page}&text=${searchText || ''}&status=${searchStatus || ''}&from_date=${from_date || ''}&to_date=${to_date || ''}&limit=${limit}`,
        method: 'GET',
      })
    }),
    getAdvertisement: builder.query({
      query: id => ({
        url: `/advertisements/${id}`,
        method: 'GET',
      })
    }),
    createAdvertisement: builder.mutation({
      query: advertisement => ({
        url: '/advertisements',
        method: 'POST',
        body: advertisement,
      })
    }),
    updateAdvertisement: builder.mutation({
      query: ({ id, data }) => ({
        url: `/advertisements/${id}`,
        method: 'PUT',
        body: data,
      })
    }),
    deleteAdvertisement: builder.mutation({
      query: id => ({
        url: `/advertisements/${id}`,
        method: 'DELETE',
      })
    }),    
  }),
});

export const { useGetAdvertisementsQuery, useGetAdvertisementQuery, useCreateAdvertisementMutation, useUpdateAdvertisementMutation, useDeleteAdvertisementMutation } = advertisementApi;