import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '@/css/datepicker_custom.css';
import {
    TitleLine,
    SysContainer,
    Input,
    FieldGroup,
    InputItem,
    FormBorderb,
    BottomBtnsRight,
    BtnMdSubmit,
    Select,
    UploadFile,
    SearchWrapper,
    Fz20Bold,
    Textbox,
    ColRed,
    FlxbtFull,
    Gap10,
    FlxMiddle,
    Mb15,
    Fz15,
    PreviewWrapper,
    MyHeader,
    Fz18Bold,
    ItemInfoCol2,
    InfoLabel,
    InfoValue,
    ContainerPadding,
    PostImg,
    FlxbtFullMb40,
    PreviewList,
    PhotoItem,
    GridImg,
    BtnDelete,
    BtnModalClose,
    ModalAbsolBtn,
    BtnFullModal,
    DesignerItem,
    ShopItem
} from '@/css/admin/style';
import RadioBox from '@/components/RadioBox';
import icClear from '@/assets/img/ic_clear.svg';
import icSearch from '@/assets/img/ic_search_gray.svg';
import { FaSearch} from 'react-icons/fa';
import { useGetShopsQuery } from '@services/shop';
import { useGetDesignersQuery } from '@services/designer';
import { useUpdateAdvertisementMutation, useGetAdvertisementQuery } from '@services/advertisement';
import { toast } from 'react-toastify';
import Map from '@components/Map';
import Moment from 'react-moment';
import { useNavigate, useParams } from 'react-router-dom';
import config from '@config';
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko)

function AdvertisementNewForm() {
  const shopSearchInputRef = React.createRef();
  const designerSearchInputRef = React.createRef();

  const [shop, setShop] = useState({
    id: '',
    name: '',
    title: '',
    description: '',
  });
  const [designer, setDesigner] = useState({
    id: '',
    name: '',
  });
  const [orderSeq, setOrderSeq] = useState(null);
  const [price, setPrice] = useState(null);
  const [actived, setActived] = useState(null);
  const [IsShopModalOpen, setIsShopModalOpen] = useState(false);
  const [IsDesignerModalOpen, setIsDesignerModalOpen] = useState(false);
  const [designerSearchText, setDesignerSearchText] = useState('');
  const [shopSearchText, setShopSearchText] = useState('');

  const [deleteDetailImage, setDeleteDetailImage] = useState(false);

  const [selectedMainFile, setSelectedMainFile] = useState(null);
  const [selectedDetailFile, setSelectedDetailFile] = useState(null);
  const [selectedServerMainFile, setSelectedServerMainFile] = useState(null);
  const [selectedServerDetailFile, setSelectedServerDetailFile] = useState(null);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const navigate = useNavigate();
  const { type, id } = useParams();

  const { data: shops, isLoading: shopLoading, isFetching: shopIsFetching, error: shopError, refetch: shopRefetch } = useGetShopsQuery({ page: 1, limit: 100, searchText: shopSearchText, approved: true }, { refetchOnMountOrArgChange: true, skip: type === 'designer' || shopSearchText === ''});
  const { data: designers, isLoading: designerLoading, isFetching: designerIsFetching, error: designerError, refetch: designerRefetch } = useGetDesignersQuery({ page: 1, limit: 100, searchStatus: 'approved', searchText: designerSearchText }, { refetchOnMountOrArgChange: true, skip: type === 'shop' || designerSearchText === ''});
  const { data: advertisement, isLoading: advertisementLoading, error: advertisementError } = useGetAdvertisementQuery(id, { refetchOnMountOrArgChange: true });
  const [ updateAdvertisement ] = useUpdateAdvertisementMutation();

  useEffect(() => {
    if (advertisement) {
      setShop({
        id: advertisement?.shop?._id,
        name: advertisement?.shop?.name,
        title: advertisement.title,
        description: advertisement?.description,
      });
      setDesigner({
        id: advertisement?.designer?._id,
        name: advertisement?.designer?.title,
      });
      setOrderSeq(advertisement.seq);
      setPrice(advertisement.price);
      setActived(advertisement.actived);
      setStartDate(new Date(advertisement.from_date));
      setEndDate(new Date(advertisement.to_date));
      setSelectedServerMainFile(advertisement.main_image);
      setSelectedServerDetailFile(advertisement.detail_image);
    }
  }, [advertisement]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('type', type);

    if (type === 'shop') {
      if (!shop.name) {
        toast.error('매장을 선택해주세요');
        return;
      }

      if (!shop.title) {
        toast.error('제목을 입력해주세요');
        return;
      }

      // if (!shop.description) {
      //   toast.error('설명을 입력해주세요');
      //   return;
      // }

      if (!selectedServerMainFile && !selectedMainFile) {
        toast.error('대표 이미지를 선택해주세요');
        return;
      }

      // if (!selectedDetailFile) {
      //   toast.error('상세 이미지를 선택해주세요');
      //   return;
      // }

      if(!shop.description && (!selectedServerDetailFile && selectedDetailFile)){
        toast.error('상세 이미지 또는 상세 TEXT 설명 둘 중 하나는 필수 입력입니다.');
        return;
      }

      // if (!price) {
      //   toast.error('광고비를 입력해주세요');
      //   return;
      // }

      // if (!orderSeq) {
      //   toast.error('노출 순서를 입력해주세요');
      //   return;
      // }

      formData.append('shop', shop.id);
      formData.append('title', shop.title);
      formData.append('description', shop.description);
      formData.append('main_image', selectedMainFile);
      formData.append('detail_image', selectedDetailFile);
      formData.append('delete_detail_image', deleteDetailImage);
    } else {
      if (!designer.name) {
        toast.error('디자이너를 선택해주세요');
        return;
      }

      // if (!price) {
      //   toast.error('광고비를 입력해주세요');
      //   return;
      // }

      // if (!orderSeq) {
      //   toast.error('노출 순서를 입력해주세요');
      //   return;
      // }

      formData.append('designer', designer.id);
    }

    formData.append('price', price);
    formData.append('from_date', startDate);
    formData.append('to_date', endDate);
    formData.append('actived', actived);
    formData.append('seq', orderSeq);

    updateAdvertisement({
      id,
      data: formData
    })

    toast.success('광고를 수정하였습니다.');
    
    navigate(`/advertisements/${type}`, { replace: true });
  }

  const renderMainPhoto = (source) => {
    return source && <PhotoItem>
        <GridImg src={URL.createObjectURL(source)} alt="" key={source.name} />
        <BtnDelete>
          <img src={icClear} alt="삭제" onClick={e=>handleMainImageDelete()}/>
        </BtnDelete>
      </PhotoItem>
  }

  const renderMainServerPhoto = (file) => {
    return file && <PhotoItem>
        <GridImg src={`${config.API_SERVER_HOST}/files/advertisement/${file.filename}`} alt="" key={file.id} onError={(e) => {
              e.target.onerror = null;
              e.target.src = require('@/assets/img/altimg.png');
          }}/>
        <BtnDelete>
          <img src={icClear} alt="삭제" onClick={e=>setSelectedServerMainFile(null)}/>
        </BtnDelete>
      </PhotoItem>
  }

  const renderDetailPhoto = (source) => {
    return source && <PostImg style={{position:'relative'}}>
        <GridImg src={URL.createObjectURL(source)} alt="" key={source.name} />
        <BtnDelete>
          <img src={icClear} alt="삭제" onClick={e=>handleDetailImageDelete()}/>
        </BtnDelete>
      </PostImg>
  }

  const renderDetailServerPhoto = (file) => {
    return file && <PostImg style={{position:'relative'}}>
        <GridImg src={`${config.API_SERVER_HOST}/files/advertisement/${file.filename}`} alt="" key={file.id} onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = require('@/assets/img/altimg.png');
                            }}/>
        <BtnDelete>
          <img src={icClear} alt="삭제" onClick={e=>{
            setSelectedServerDetailFile(null)
            setDeleteDetailImage(true)
           }}/>
        </BtnDelete>
      </PostImg>
  }

  const handleDesignersSearch = (e) => {
    e.preventDefault();
    if(designerSearchInputRef.current.value === ''){
      designerSearchInputRef.current.focus();
      toast.error('검색어를 입력해주세요.');
      return;
    }
    setDesignerSearchText(designerSearchInputRef.current?.value);
  };

  const handleShopsSearch = (e) => {
    e.preventDefault();
    if(shopSearchInputRef.current.value === ''){
      shopSearchInputRef.current.focus();
      toast.error('검색어를 입력해주세요.');
      return;
    }
    setShopSearchText(shopSearchInputRef.current?.value);
  };

  const handleMainImageChange = (e) => {
    setSelectedMainFile(e.target.files[0]);
    if(selectedServerMainFile){
      setSelectedServerMainFile(null);
    }
  }

  const handleDetailImageChange = (e) => {
    setSelectedDetailFile(e.target.files[0]);
    if(selectedServerDetailFile){
      setSelectedServerDetailFile(null);
      setDeleteDetailImage(true);
    }
  }

  const handleMainImageDelete = () => {
    setSelectedMainFile(null);
  }

  const handleDetailImageDelete = () => {
    setSelectedDetailFile(null);
  }

  const handleShopModalOpen = () => {
    setIsShopModalOpen(true);
  }

  const handleDesignerModalOpen = () => {
    setIsDesignerModalOpen(true);
  }

  const handleShopModalClose = () => {
    setIsShopModalOpen(false);
  }

  const handleDesignerModalClose = () => {
    setIsDesignerModalOpen(false);
  }

  return (
    <>
      <SysContainer>
        <form onSubmit={handleSubmit}>
          <FlxbtFull>
              <Fz20Bold>광고관리</Fz20Bold>
          </FlxbtFull>
          <TitleLine />
          <FlxbtFullMb40 $ai="flex-start">
            <FieldGroup $mb="0" style={{ flexDirection: 'column' }}>
              <InputItem $wd="100%">
                <span>{type === 'shop' ? '매장' : '디자이너'}</span>
                {type === 'shop' ? shop.name : designer.name}
              </InputItem>
              <InputItem>
                <span>기간</span>
                <FlxMiddle>
                  <DatePicker
                    className="detail__datepicker"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    locale="ko"
                    dateFormat="yyyy/MM/dd"
                    placeholderText="시작일"
                  />
                  <Gap10 />~<Gap10 />
                  <DatePicker
                    className="detail__datepicker"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    locale="ko"
                    dateFormat="yyyy/MM/dd"
                    placeholderText="종료일"
                  />
                </FlxMiddle>
              </InputItem>
              {type === 'shop' && <>
              <InputItem $wd="100%" $ai="flex-start">
                <span>대표 이미지</span>
                <div>
                  <UploadFile>
                      <div>파일명 여기</div>
                      <input type="file" accept="image/png, image/gif, image/jpeg" onChange={handleMainImageChange}/>
                  </UploadFile>
                  <PreviewList>
                    {renderMainPhoto(selectedMainFile)}
                    {renderMainServerPhoto(selectedServerMainFile)}
                  </PreviewList>
                  <ColRed>
                      가로 720px, 세로 400px (최대 2000px 이하를 권장 드립니다.){' '}
                  </ColRed>
                </div>
              </InputItem>
              <InputItem $ai="flex-start">
                <span>상세 이미지</span>
                <div>
                  <UploadFile>
                      <div>파일명 여기</div>
                      <input type="file" accept="image/png, image/gif, image/jpeg" onChange={handleDetailImageChange}/>
                  </UploadFile>
                  <PreviewList>
                    {renderDetailPhoto(selectedDetailFile)}
                    {renderDetailServerPhoto(selectedServerMainFile)}
                  </PreviewList>
                  <ColRed>
                      가로 720px, 세로 400px (최대 2000px 이하를 권장 드립니다.){' '}
                  </ColRed>
                </div>
              </InputItem>
              <InputItem $wd="100%">
                <span>제목</span>
                <Input type="text" defaultValue={shop.title} placeholder="제목을 입력해 주세요." onChange={(e) => setShop({ ...shop, title: e.target.value })}/>
              </InputItem>
              <InputItem $wd="100%" $ai="flex-start">
                <span>설명</span>
                <Textbox
                    rows="8"
                    placeholder="이벤트 상세 화면에 TEXT로 설명하실 내용을 작성해 주세요. ( 상세 이미지 또는 상세 TEXT 설명 둘 중 하나는 필수 입력입니다.)"
                    defaultValue={shop.description}
                    onChange={(e) => setShop({ ...shop, description: e.target.value })}
                ></Textbox>
              </InputItem>
              </>}
              <InputItem $wd="100%">
                  <span>광고비</span>
                  <Input type="number" defaultValue={price} onChange={e=>setPrice(e.target.value)} />
              </InputItem>
              <InputItem $wd="100%">
                  <span>이벤트 등록 순서</span>
                  <Input type="number" defaultValue={orderSeq} onChange={e=>setOrderSeq(e.target.value)} />
              </InputItem>
              <InputItem $wd="100%">
                <span>활성화</span>
                <Select defaultValue={actived} onChange={e=>setActived(e.target.value)}>
                  <option value={true} selected={actived == true}>활성화</option>
                  <option value={false} selected={actived == false}>비활성화</option>
                </Select>
              </InputItem>
            </FieldGroup>
            {type==='shop' ? <figure>
            <Mb15 style={{ textAlign: 'center' }}>
                <Fz15>[ 모바일 화면 미리보기 ]</Fz15>
            </Mb15>
            <PreviewWrapper>
                <MyHeader>
                  <Fz18Bold $mb="2rem">{shop.title}</Fz18Bold>
                  <ItemInfoCol2 $mb="1rem">
                    <InfoLabel>이벤트 기간</InfoLabel>
                    <Gap10 />
                    <InfoValue><Moment format="YYYY.MM.DD HH:mm:ss">{startDate}</Moment><br/>~ <Moment format="YYYY.MM.DD HH:mm:ss">{endDate}</Moment></InfoValue>
                  </ItemInfoCol2>
                  <ItemInfoCol2>
                    <InfoLabel>헤어샵</InfoLabel>
                    <Gap10 />
                    <InfoValue>{shop.name}</InfoValue>
                  </ItemInfoCol2>
                </MyHeader>
                <ContainerPadding>
                    {
                      renderDetailPhoto(selectedDetailFile)
                    }
                    {renderDetailServerPhoto(selectedServerMainFile)}
                    {/* <PostImg>
                        <img src="https://talkimg.imbc.com/TVianUpload/tvian/TViews/image/2021/01/20/8fc8a78a-70c1-45d9-98b0-4a70c4629a12.jpg" />
                    </PostImg> */}
                    <p>
                        {
                          shop.description
                        }
                    </p>
                </ContainerPadding>
            </PreviewWrapper>
          </figure> : null}
        </FlxbtFullMb40>
        <FormBorderb />
        <BottomBtnsRight>
            <BtnMdSubmit>저장</BtnMdSubmit>
        </BottomBtnsRight>
        </form>
      </SysContainer>
      {/* 디자이너 검색 모달 */}
      <Modal style={selectDesignerModalStyle} isOpen={IsDesignerModalOpen} onRequestClose={handleDesignerModalClose}>
          <BtnModalClose onClick={handleDesignerModalClose}>
              <img src={icClear} alt="모달 닫기" />
          </BtnModalClose>
          <Fz20Bold $mb="1.538rem">디자이너 검색</Fz20Bold>
          <form onSubmit={handleDesignersSearch}>
            <SearchWrapper>
              <input type="text" placeholder="디자이너 이름 / 연락처 / 아이디를 입력해 주세요" ref={designerSearchInputRef}/>
              <button type="button" onClick={handleDesignersSearch}>
                  <img src={icSearch} alt="찾기" />
              </button>
            </SearchWrapper>
          </form>
          {designerSearchText && !designerIsFetching && designers && designers.docs.length > 0 ? <div style={{height:'300px', overflow:'scroll' }}>
            {designers.docs.map(item=><DesignerItem onClick={()=>setDesigner({
                id: item._id,
                name: item.title,
              })}>
              <RadioBox name="salon" value={item._id} checked={designer.id === item._id} />
              <label>
                  <h5>{item.title}</h5>
                  <p>{item.user?.user_id} / {item.user?.phone}</p>
              </label>
            </DesignerItem>)}
          </div> : designerSearchText ? <div style={{height:'300px'}}>
            <div style={{
              margin: '0',
              position: 'absolute',
              top: '50%',
              left: '50%',
              textAlign: 'center',
              transform: 'translate(-50%, -50%)',
            }}>
              <FaSearch size={20} style={{marginBottom:'10px'}}/><br/> 
              디자이너를 찾을 수 없습니다.
            </div>
          </div> : <div style={{height:'300px'}}>
            <div style={{
              margin: '0',
              position: 'absolute',
              top: '50%',
              left: '50%',
              textAlign: 'center',
              transform: 'translate(-50%, -50%)',
            }}>
              <FaSearch size={20} style={{marginBottom:'10px'}}/><br/>
              디자이너를 검색해주세요.
            </div>
          </div>}
          <ModalAbsolBtn>
            <BtnFullModal type="submit" active={designer.id} disabled={!designer.id} onClick={()=>{
              if(designer.id === ''){
                toast.error('디자이너를 선택해주세요.');
                return;
              }
              handleDesignerModalClose();
            }}>선택</BtnFullModal>
          </ModalAbsolBtn>
      </Modal>
      {/* //매장 검색 모달 */}
      <Modal style={selectShopModalStyle} isOpen={IsShopModalOpen} onRequestClose={handleShopModalClose}>
          <BtnModalClose onClick={handleShopModalClose}>
              <img src={icClear} alt="모달 닫기" />
          </BtnModalClose>
          <Fz20Bold $mb="1.538rem">매장 검색</Fz20Bold>
          <form onSubmit={handleShopsSearch}>
            <SearchWrapper>
              <input type="text" placeholder="상호 / 네이버 예약 ID를 입력해 주세요" ref={shopSearchInputRef}/>
              <button type="button" onClick={handleShopsSearch}>
                  <img src={icSearch} alt="찾기" />
              </button>
            </SearchWrapper>
          </form>
          {shopSearchText && !shopIsFetching && shops && shops.docs.length > 0 ? <div style={{height:'560px', overflow:'scroll' }}>
            {shops.docs.map(item=><ShopItem onClick={()=>setShop({
                ...shop,
                id: item.id,
                name: item.name,
              })}>

                {console.log(item)}
              <RadioBox name="salon" value={item.id} checked={shop.id === item.id} />
              <label>
                  <h5>{item.name}</h5>
                  <p style={{marginBottom:'15px'}}>{item.manager?.user_id} / {item.manager?.phone}</p>
                  {shop.id === item.id && <Map address_map={item.address_map} />}
              </label>
            </ShopItem>)}
          </div> : shopSearchText ? <div style={{height:'350px'}}>
            <div style={{
              margin: '0',
              position: 'absolute',
              top: '50%',
              left: '50%',
              textAlign: 'center',
              transform: 'translate(-50%, -50%)',
            }}>
              <FaSearch size={20} style={{marginBottom:'10px'}}/><br/> 
              매장을 찾을 수 없습니다.
            </div>
          </div> : <div style={{height:'300px'}}>
            <div style={{
              margin: '0',
              position: 'absolute',
              top: '50%',
              left: '50%',
              textAlign: 'center',
              transform: 'translate(-50%, -50%)',
            }}>
              <FaSearch size={20} style={{marginBottom:'10px'}}/><br/>
              매장을 검색해주세요.
            </div>
          </div>}
          <ModalAbsolBtn>
            <BtnFullModal type="submit" active={shop.id} disabled={!shop.id} onClick={()=>{
              if(shop.id === ''){
                toast.error('디자이너를 선택해주세요.');
                return;
              }
              handleShopModalClose();
            }}>선택</BtnFullModal>
          </ModalAbsolBtn>
      </Modal>
    </>
  );
}

const managerInputWrapStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  border: '1px solid #e8eaed',
  backgroundColor: '#fff',
  width: '100%',
}

const selectDesignerModalStyle = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.8)',
    zIndex: 20,
  },
  content: {
    overflowY: 'auto',
    top: '50%',
    bottom: 'unset',
    left: '50%',
    width: '100%',
    maxWidth: 430,
    height: 'auto',
    minHeight: 540,
    transform: 'translate(-50%,-50%)',
    transition: 'left 1s',
    padding: '3.846rem 2.3077rem 2.3077rem',
    border: 0,
    zIndex: 20,
  },
};

const selectShopModalStyle = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.8)',
    zIndex: 20,
  },
  content: {
    overflowY: 'auto',
    top: '50%',
    bottom: 'unset',
    left: '50%',
    width: '100%',
    maxWidth: 800,
    height: 'auto',
    minHeight: 800,
    transform: 'translate(-50%,-50%)',
    transition: 'left 1s',
    padding: '3.846rem 2.3077rem 2.3077rem',
    border: 0,
    zIndex: 20,
  },
};


export default AdvertisementNewForm;
