import React, { useEffect, useState, useRef } from 'react';
import {
  TitleLine,
  SysContainer,
  Input,
  FieldGroup,
  InputItem,
  FormBorderb,
  BottomBtnsRight,
  BtnMd,
  BtnMdSubmit,
  Fz20Bold,
  Mb30,
} from '@/css/admin/style';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateUserMutation, useGetUserQuery } from '@services/user';
import Moment from 'react-moment';

function UserEditForm() {
  const [name, setName] = useState('');
  const [nickName, setNickName] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, error } = useGetUserQuery(id, { refetchOnMountOrArgChange: true });

  const [updateUser, { isLoading: updateIsLoading, error: updateError }] = useUpdateUserMutation();

  useEffect(() => {
    if (data) {
      setName(data.name);
      setPhone(data.phone);
      setNickName(data.nickname);
    }
  }, [data]);

  const handleUpdateUser = async (e) => {
    e.preventDefault();

    try {
      if (confirmPassword && !password) {
        toast.error('비밀번호를 입력해주세요.');
        return;
      }
  
      if (confirmPassword && password !== confirmPassword) {
        toast.error('비밀번호가 일치하지 않습니다.');
        return;
      }

      const data = {
        name,
        nickname: nickName,
        // phone,
      };
      
      if (password) {
        data.password = password;
        data.confirm_password = confirmPassword;
      }

      await updateUser({id, data});
      toast.success('회원정보를 업데이트 하였습니다.');
    } catch (e) {
      console.log(e)
      toast.error('회원정보 업데이트에 실패하였습니다.');
    }
  }

  return (
    <>
      <SysContainer>
          <form onSubmit={handleUpdateUser}>
            <Fz20Bold>일반회원 정보</Fz20Bold>
            <TitleLine />
            <FieldGroup $mb="6.5385rem">
              <Mb30>마지막 접속일 : <Moment format="YYYY.MM.DD HH:mm">{data?.last}</Moment></Mb30>
              <InputItem $wd="100%"/>
              <InputItem>
                  <span>등록일</span>
                  <Moment format="YYYY.MM.DD HH:mm">{data?.created}</Moment>
              </InputItem>
              <InputItem>
                  <span>상태</span>
                  {data?.deleted ? <ColRed>탈퇴</ColRed> : '정상'}
              </InputItem>
              <InputItem>
                  <span>이메일(ID)</span>
                  {data?.user_id}
              </InputItem>
              <InputItem>
                  <span>휴대폰 번호</span>
                  {data?.phone}
                  {/* <Input type="text" ref={inputPhone} defaultValue={data?.phone} onChange={()=>setIsCheckPhone(false)}/> */}
                  {/* <BtnSm $bg="#00bf9d" $col="#fff">
                      중복체크
                  </BtnSm> */}
                  {/* <BtnSm $bg={isCheckPhone?"#fff":"#00BF9D"} $col={isCheckPhone?"#00BF9D":"#fff"} onClick={handleCheckPhone}>
                    {isCheckPhone?"확인완료":"중복체크"}
                  </BtnSm> */}
              </InputItem>
              <InputItem>
                <span>성명</span>
                <Input type="text" defaultValue={name} onChange={e=>setName(e.target.value)}/>
              </InputItem>
              <InputItem>
                <span>닉네임</span>
                <Input type="text" defaultValue={nickName} onChange={e=>setNickName(e.target.value)}/>
              </InputItem>
              <InputItem>
                <span>패스워드</span>
                <Input type="password" onChange={e=>setPassword(e.target.value)}/>
              </InputItem>
              <InputItem>
                <span>패스워드 확인</span>
                <Input type="password" onChange={e=>setConfirmPassword(e.target.value)}/>
              </InputItem>
            </FieldGroup>
            <FormBorderb />

            <BottomBtnsRight>
              <BtnMd $bg="#fff" $bc="#00BF9D" $col="#00BF9D" onClick={()=>navigate('/users')}>
                  목록
              </BtnMd>
              <BtnMdSubmit>저장</BtnMdSubmit>
              {/* <BtnMd $bg="#6946B1">탈퇴</BtnMd> */}
            </BottomBtnsRight>
          </form>
      </SysContainer>
    </>
  );
}

export default UserEditForm;
