import React from 'react';
import Chkbox from '@/components/Chkbox';
import {
    Block50,
    SysContainer,
    Fz30Bold,
    Fz15,
    InputShort,
    BtnSearch,
    BtnMd,
    ListTableWrapper,
    BtnTable,
    BottomBtns,
    Gap30,
    ListInputs,
    ColRed,
    ColPurple,
    ColAccent,
    FlxMiddleMb20,
    ChkAndTxt,
} from '@/css/admin/style';
import icSearch from '@/assets/img/ic_search.svg';

function Qnas() {
    return (
        <SysContainer>
            <Fz30Bold $mb="3.077rem">문의 관리</Fz30Bold>
            <FlxMiddleMb20>
                <Fz15>상태</Fz15>
                <Gap30 />
                <ChkAndTxt>
                    <Chkbox />
                    전체
                </ChkAndTxt>
                <ChkAndTxt>
                    <Chkbox />
                    답변대기
                </ChkAndTxt>
                <ChkAndTxt>
                    <Chkbox />
                    답변완료
                </ChkAndTxt>
            </FlxMiddleMb20>
            <ListInputs>
                <Fz15>제목</Fz15>
                <InputShort placeholder="제목" />
                <Fz15>작성자</Fz15>
                <InputShort placeholder="고객/디자이너명" />
                <BtnSearch>
                    <img src={icSearch} alt="" />
                    검색
                </BtnSearch>
            </ListInputs>
            <Block50>
                <ListTableWrapper>
                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>체크</th>
                                <th>분류</th>
                                <th>상태</th>
                                <th>이름</th>
                                <th>제목</th>
                                <th>등록일</th>
                                <th>조회</th>
                                <th>편집</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>$</td>
                                <td>
                                    <Chkbox />
                                </td>
                                <td>고객</td>
                                <td>
                                    <ColAccent>답변완료</ColAccent>
                                    {/* 메모: 상태에 따라 색상 분기
                                    <ColAccent>답변완료</ColAccent>
                                    <ColRed>답변대기</ColRed> */}
                                </td>
                                <td>김고객</td>
                                <td className="title">서비스 사용 관련 문의 드립니다</td>
                                <td>23.12.18 16:28:25</td>
                                <td>643</td>
                                <td>
                                    <BtnTable>답변하기</BtnTable>
                                    {/* 메모: 상태에 따라 버튼 분기
                                    <BtnTable>답변하기</BtnTable>
                                    <BtnTable>수정</BtnTable> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ListTableWrapper>
            </Block50>
            <BottomBtns>
                <BtnMd>신규 등록</BtnMd>
                <BtnMd $bg="#6946B1">선택 정지</BtnMd>
                <BtnMd $bg="#F33535">선택 삭제</BtnMd>
            </BottomBtns>
        </SysContainer>
    );
}

export default Qnas;
