import React, {useRef, useState, useEffect} from "react";
import Chkbox from "@/components/Chkbox";
import {
  Fz30Bold,
  SysIndexContainer,
  InputSet,
  UpperLabel,
  Block50,
  LinkFull,
  FlxMiddle,
  BtnFullModal,
  Gap10,
  Mb20
} from "@/css/admin/style";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setCredentials } from "@redux/slice/authSlice";
import logoWh from "@/assets/img/logo_wh.svg";
import { useLoginMutation } from "@services/auth";
import { useLogoutMutation } from "@services/auth";

import { toast } from "react-toastify";
import ValidateUtil from "@utils/validateUtil";

function Login() {
  const userRef = useRef()
  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");

  const [login] = useLoginMutation();
  const [logout] = useLogoutMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    userRef.current.focus()
  }, [])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if(!ValidateUtil.email(userId)) {
        userRef.current.focus() 
        throw new Error("이메일 형식이 올바르지 않습니다.")
      };
      
      const userData = await login({ user_id: userId, password: userPw }).unwrap();

      if(userData?.user?.role !== "ADMIN") {
        throw new Error("관리자만 접근 가능합니다.")
      }

      dispatch(setCredentials(userData));
      
      navigate('/users')
    } catch (error) {
      toast(error?.data?.message || error.message, { type: "error" })
    }   
  }
  return (
    <SysIndexContainer>
      <div style={{ backgroundColor: "#00BF9D" }}>
        <img src={logoWh} alt="머리할래" />
      </div>
      <div>
        <form style={{ width: 448 }} onSubmit={handleSubmit}>
          <Fz30Bold $mb="3.846rem">MASTER LOGIN</Fz30Bold>
          <Block50>
              <InputSet $mb="2.3077rem">
                <UpperLabel htmlFor="sysId">ID</UpperLabel>
                <input
                  ref={userRef}
                  type="email"
                  name="sysId"
                  id="sysId"
                  onChange={(e) => setUserId(e.target.value)}
                  placeholder="아이디를 입력해 주세요"
                />
              </InputSet>
              <InputSet>
                <UpperLabel htmlFor="sysId">Password</UpperLabel>
                <input
                  type="password"
                  name="sysPw"
                  id="sysPw"
                  onChange={(e) => setUserPw(e.target.value)}
                  placeholder="비밀번호를 입력해 주세요"
                />
              </InputSet>
          </Block50>
          <Mb20>
            <BtnFullModal 
              type="submit"
              active={userId.length > 0 && userPw.length > 0}
              >Login</BtnFullModal>
          </Mb20>
        </form>
      </div>
    </SysIndexContainer>
  );
}

export default Login;
