import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import arrowDownGray from "@/assets/img/arrow_down_thin_gray.svg";
import icPlus from "@/assets/img/ic_plus.svg";

//mixin CSS 정의
const flxCent = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const inlineFlxCent = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
const grid = css`
  display: -ms-grid;
  display: grid;
`;
const toggleShow = css`
  display: ${({ $visible }) => ($visible ? "block" : "none")};
`;
const whfull = css`
  width: 100%;
  height: 100%;
`;
const rounded = css`
  border-radius: 10px;
  overflow: hidden;
`;
const fz15 = css`
  font-size: 1.154rem;
  letter-spacing: -0.07rem;
`;
const fz15bold = css`
  font-size: 1.154rem;
  font-weight: 700;
  letter-spacing: -0.07rem;
`;
const fz16medium = css`
  font-size: 1.23rem;
  font-weight: 500;
  letter-spacing: -0.07rem;
`;
const fz18medium = css`
  font-size: 1.385rem;
  font-weight: 500;
  letter-spacing: -0.07rem;
`;
const txtcolor = css`
  color: ${(props) => props.color || "#757575"};
`;
const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const horizonCent = css`
  position: absolute;
  left: 50%;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
`;
const VertCent = css`
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
`;
const bordered = css`
    border-width: 1px;
    border-style: solid;
    white-space: nowrap;
    background-color: ${({ $active }) => ($active ? "#00BF9D" : "#FFF")};
    border-color: ${({ $active }) => ($active ? "#00BF9D" : "#e8eaed")};
    color: ${({ $active }) => ($active ? "#FFF" : "#000")};
    }};
`;
const hide = css`
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  line-height: 0;
  font-size: 0;
  text-indent: -9999px;
  text-shadow: none;
  color: transparent;
`;

//여백
const NavHeight = styled.div`
  width: 0;
  height: 4.15385rem;
`;
const Mb1rem = styled.div`
  margin-bottom: 1rem;
`;
const Mb10 = styled.div`
  margin-bottom: 0.77rem;
`;
const Mb15 = styled.div`
  margin-bottom: 1.154rem;
`;
const Mb20 = styled.div`
  margin-bottom: 1.538rem;
`;
const Mb30 = styled.div`
  margin-bottom: 2.3rem;
`;
const Mb40 = styled.div`
  margin-bottom: 3.077rem;
`;
const Spacing10 = styled.div`
  width: 100%;
  height: 0.77rem;
`;
const Spacing20 = styled.div`
  width: 100%;
  height: 1.538rem;
`;
const Spacing30 = styled.div`
  width: 100%;
  height: 2.3077rem;
`;
const Spacing40 = styled.div`
  width: 0;
  height: 3.077rem;
`;
const Spacing36 = styled.div`
  width: 0;
  height: 3rem;
`;
const Spacing50 = styled.div`
  width: 0;
  height: 3.846rem;
`;
const Spacing70 = styled.div`
  width: 0;
  height: 5.385rem;
`;
const Gap10 = styled.div`
  width: 0.77rem;
  height: 0;
`;
const Gap15 = styled.div`
  width: 1.154rem;
  height: 0;
`;
const Gap20 = styled.div`
  width: 1.538rem;
  height: 0;
`;
const Gap30 = styled.div`
  width: 2.3077rem;
  height: 0;
`;
const PadVert10 = styled.div`
  padding: 0.77rem 0;
`;
const PadVert20 = styled.div`
  padding: 1.538rem 0;
`;
const PadVert30 = styled.div`
  padding: 2.3077rem 0;
`;
const Pad20 = styled.div`
  padding: 1.538rem;
`;

//공통 컴포넌트
const ToggleShow = styled.div`
  ${toggleShow}
`;
const ImgCent = styled.img`
  margin: 0 auto;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8eaed;
  margin-top: ${(props) => props.$mt || "0"};
  margin-bottom: ${(props) => props.$mb || "0"};
`;
const BtnFullLine = styled.button.attrs({ type: "button" })`
  width: 100%;
  padding: 1.154rem;
  border-radius: 10px;
  column-gap: 0.4rem;
  ${flxCent};
  ${fz15bold};
  color: #757575 !important;
  ${bordered};
`;
const BtnFullLineGreen = styled(BtnFullLine)`
  padding: 1.193rem;
  border: 1px solid #00bf9d;
  color: #00bf9d !important;
`;
const BtnLineGreenShorter = styled(BtnFullLineGreen)`
  padding: 0.9rem;
`;
const BtnLineStatus = styled.button.attrs({ type: "button" })`
  ${flxCent};
  width: 100%;
  padding: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #fff;
  background-color: #fff;
  color: #757575;
  strong {
    color: #000;
  }
  i {
    padding: 0 2px;
    font-style: normal;
    color: #757575;
  }
`;
const BtnStatusDisabled = styled(BtnLineStatus)`
  border-color: transparent;
  background-color: transparent;
  color: #a1a1a1;
  i {
    color: inherit;
  }
`;
const BtnStatusConfirmed = styled(BtnLineStatus)`
  border-color: #ff3062;
  color: #ff3062;
  background-color: #ffebeb;
  i {
    color: inherit;
  }
`;
const BtnStatusNoshow = styled(BtnLineStatus)`
  border-color: #a1a1a1;
  background-color: #f8f8f8;
`;
const BtnStatusComplete = styled(BtnLineStatus)`
  border-color: #00bf9d;
  color: #00bf9d;
  background-color: #e5f6f3;
  i {
    color: inherit;
  }
`;
const BtnStatusCancel = styled(BtnLineStatus)`
  border-color: #000;
  color: #000;
  background-color: #fff;
  i {
    color: inherit;
  }
`;
const LineGrayBox = styled.div`
  ${flxCent};
  padding: 0.5rem 0.92rem;
  border: 1px solid #a1a1a1;
  border-radius: 8px;
  background-color: #fff;
  font-weight: bold;
`;
const LineGrayBoxHigher = styled(LineGrayBox)`
  padding: 0.95rem 0.92rem;
`;
const LineGrayBoxFull = styled(BtnFullLine)`
  padding: 1.154rem 0.77rem;
  justify-content: flex-start;
`;
const SectionDivider = styled.div`
  width: 100%;
  height: 0.77rem;
  margin: 2.3077rem 0;
  background-color: #f8f8f8;
`;
const SectionDivider40 = styled(SectionDivider)`
  margin: 3.077rem 0;
`;
const SectionDivider50 = styled(SectionDivider)`
  margin: 3.846rem 0;
`;
const TxtDivider = styled.div`
  min-width: 1px;
  width: 2px;
  height: 1.54rem;
  margin: 0 1.92rem;
  background-color: #e8eaed;
`;
const TxtDivider2 = styled(TxtDivider)`
  width: 1px;
  height: 1.538rem;
  margin: 0 1.538rem;
`;
const BtnClearInput = styled.button.attrs({ type: "button" })`
  ${VertCent}
  right:0;
`;
const BtnDelete = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: 0.385rem;
  right: 0.385rem;
  z-index: 2;
`;
const LiStyled = styled.li`
  display: flex;
  margin-bottom: 1.154rem;
  column-gap: 0.6rem;
  & > a {
    display: flex;
    width: 100%;
    margin-bottom: 1.154rem;
    column-gap: 0.6rem;
  }
  & > span:first-of-type {
    color: #000;
  }
`;
const TabItem = styled.div`
  ${inlineFlxCent};
  padding: 0.77rem 1.538rem;
  border-radius: 3rem;
  ${bordered};
  ${fz16medium}
`;
const TimeItem = styled.label`
  & > input[type="radio"] {
    ${hide}
  }
  ${inlineFlxCent};
  padding: 1rem 1.538rem;
  border-radius: 3rem;
  ${bordered};
  ${fz16medium}
  &.disabled {
    background: #f8f8f8;
    color: ##a1a1a1;
    text-decoration: line-through;
    pointer-events: none;
  }
`;
const TimeItem2 = styled(TimeItem)`
  background-color: ${({ $active }) => ($active ? "#FF3062" : "#FFF")};
  border-color: ${({ $active }) => ($active ? "#FF3062" : "#e8eaed")};
`;
const Textbox = styled.textarea`
  width: 100%;
  padding: 1.538rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $active }) => ($active ? "#00BF9D" : "#e8eaed")};
  border-radius: 10px;
  ${fz15};
  color: #000;
  resize: none;
`;
const TextLine = styled.textarea`
  width: 100%;
  resize: none;
  &::placeholder {
    font-size: 1.154rem;
  }
`;
const LinkFull = styled(Link)`
  ${flxCent};
  ${fz18medium};
  // width: 100%;
  padding: 1.154rem;
  border-radius: 10px;
  background-color: ${({ $active }) => ($active ? "#00BF9D" : "#E8EAED")};
  color: ${({ $active }) => ($active ? "#FFF" : "#A1A1A1")};
`;
const AbsolBtmBtn = styled.div`
  ${horizonCent};
  bottom: 8.461rem;
  width: 45%;
`;
const AbsolBtmBtn2 = styled(AbsolBtmBtn)`
  bottom: 3.077rem;
  width: 90%;
`;
const AbsolCent = styled.div`
  ${horizonCent};
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;
const LineCenter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const FullLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.$mb || "0"};
`;
const FullImg = styled.img`
  ${whfull};
`;
const GridImg = styled.img`
  width: 100%;
  min-height: 100%;
`;
const CircleImg = styled.div`
  width: 28.58%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
`;
const TxRight = styled.div`
  text-align: right;
`;
const TxCent = styled.div`
  text-align: center;
`;
const TxLabelRight = styled.p`
  padding-left: 0.77rem;
  white-space: nowrap;
`;
const TxLabelLeft = styled.p`
  padding-right: 0.77rem;
  white-space: nowrap;
`;
const Paragraph = styled.p`
  line-height: 1.4666;
  letter-spacing: -0.07rem;
  font-size: ${(props) => props.fontSize || "1rem"};
  color: ${(props) => props.color || "#757575"};
`;
const MapLayout = styled.div`
  height: 100%;
  // min-height: 90vh;
`;
const BorderBox = styled.div`
  width: 100%;
  padding: 1.538rem;
  border: 1px solid #e8eaed;
  background-color: #fff;
  ${rounded};
`;
const TextBoxWrapper = styled.div`
  position: relative;
  textarea {
    padding-bottom: 2.3077rem;
  }
`;
const TextBoxCount = styled.span`
  position: absolute;
  right: 1.538rem;
  bottom: 1.538rem;
  background-color: #fff;
`;
const InputHidden = styled.input`
  ${hide}
`;
const LinkFlx = styled(Link)`
  display: flex;
`;
const LinkFlxMiddle = styled(Link)`
  display: flex;
  align-items: center;
`;
const LinkFlxbtFull = styled(LinkFlxMiddle)`
  justify-content: space-between;
`;

//레이아웃 정의
const Flx = styled.div`
  display: flex;
`;
const Flxbt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FlxbtFull = styled(Flxbt)`
  width: 100%;
`;
const FlxbtFullMb10 = styled(FlxbtFull)`
  margin-bottom: 0.77rem;
`;
const FlxbtFullMb15 = styled(FlxbtFull)`
  margin-bottom: 1.154rem;
`;
const FlxbtFullMb20 = styled(FlxbtFull)`
  margin-bottom: 1.538rem;
`;
const FlxbtFullMb30 = styled(FlxbtFull)`
  margin-bottom: 2.3077rem;
`;
const FlxbtFullMb40 = styled(FlxbtFull)`
  margin-bottom: 3.077rem;
`;
const FlxbtFullMb50 = styled(FlxbtFull)`
  margin-bottom: 3.846rem;
`;
const FlxMiddle = styled.div`
  display: flex;
  align-items: center;
`;
const FlxMiddleMb10 = styled(FlxMiddle)`
  margin-bottom: 0.77rem;
`;
const FlxMiddleMb20 = styled(FlxMiddle)`
  margin-bottom: 1.538rem;
`;
const FlxMiddleMb30 = styled(FlxMiddle)`
  margin-bottom: 2.3077rem;
`;
const FlxRight = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
`;
const Wrap = styled.div`
  overflow-x: hidden;
  position: relative;
  left: 50%;
  min-width: 320px;
  max-width: 440px;
  height: 100vh;
  // min-height: 100vh;
  // padding-bottom: 5rem;
  transform: translateX(-50%);
  background-color: #fff;
  @media screen and (max-width: 440px) {
    left: unset;
    width: 100%;
    transform: none;
  }
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 5.15385rem;
  // padding-bottom: 6.46rem;
  overflow-y: auto;
`;
const ContainerFit = styled(Container)`
  width: 100%;
  // height: calc(100vh - 0.924rem);
  height: 100vh;
  min-height: unset;
  overflow: hidden;
`;
const ContainerPadding = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const List = styled.div`
  display: flex;
  flex-flow: wrap row;
`;
const ListColumn = styled(List)`
  flex-flow: wrap column;
`;
const ListCol2 = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
`;
const ListCol3 = styled(List)`
  width: 100%;
  row-gap: 1.538rem;
`;
const ListCol4 = styled(List)`
  flex-flow: wrap row;
  & > div,
  & > label {
    width: calc(25% - 0.77rem);
    margin: 0.77rem;
    margin-left: 0;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
const Col2Grid = styled.div`
  ${grid}
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;
const ItemInfoCol2 = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: ${(props) => props.$mb || "unset"};
  padding-bottom: ${(props) => props.$pb || "unset"};
`;
const ItemHalf = styled.div`
  width: 48%;
  flex-flow: wrap row;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-bottom: 2.3rem;
`;
const ItemHalf2 = styled(ItemHalf)`
  display: flex;
  width: 43%;
  margin-bottom: 0;
`;
const ItemDesigner = styled(Flxbt)`
  display: flex !important;
  width: 100%;
  margin-bottom: ${(props) => props.$mb || "2.3rem"};
  align-items: flex-start;
  flex-wrap: wrap;
`;
const PhotoGrid = styled.div`
  ${grid};
  grid-template-columns: repeat(3, 1fr);
  gap: 0.385rem;
`;
const PhotoGridCol2 = styled.div`
  ${grid};
  grid-template-columns: repeat(2, 1fr);
  gap: 1.385rem;
`;
const PhotoItem = styled.div`
  position: relative;
  ${flxCent};
  display: grid;
  aspect-ratio: 1/1;
  overflow: hidden;
`;
const StyleItem = styled.div`
  position: relative;
  width: calc(33.33333333% - 0.77rem);
  margin-right: 0.77rem;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
`;

//텍스트
const Fz15 = styled.span`
  font-size: 1.154rem;
  letter-spacing: -0.07rem;
  ${txtcolor}
`;
const Fz15Bold = styled(Fz15)`
  font-weight: 700;
`;
const Fz16Medium = styled.h4`
  margin-bottom: ${(props) => props.$mb || "0"};
  font-size: 1.23rem;
  font-weight: 500;
  letter-spacing: -0.07rem;
  ${txtcolor}
`;
const Fz18Medium = styled.h3`
  margin-bottom: ${(props) => props.$mb || "0"};
  font-size: 1.385rem;
  font-weight: 500;
  letter-spacing: -0.07rem;
  color: ${(props) => props.color || "#000"};
`;
const Fz18Bold = styled(Fz18Medium)`
  font-weight: 700;
`;
const Fz20Bold = styled.h3`
  margin-bottom: ${(props) => props.$mb || "0"};
  font-size: 1.538rem;
  font-weight: bold;
  color: ${(props) => props.color || "#000"};
`;
const Fz30Bold = styled.h3`
  margin-bottom: ${(props) => props.$mb || "0"};
  font-size: 2.3077rem;
  font-weight: 700;
  color: ${(props) => props.color || "#000"};
`;
const ColAccent = styled.span`
  color: #00bf9d;
`;
const ColAccent2 = styled.span`
  color: #ff3062;
`;
const ColGray1 = styled.span`
  color: #a1a1a1;
`;
const ColGray2 = styled.span`
  color: #555;
`;
const Underline = styled(ColAccent2)`
  text-decoration: underline;
`;
//GNB
const NavBar = styled.nav`
  position: fixed;
  top: 0;
  left: 50%;
  display: flex;
  width: 100%;
  max-width: 440px;
  height: 3.384615rem;
  padding: 0.924rem 1.54rem;
  transform: translateX(-50%);
  justify-content: right;
  background-color: #fff;
  z-index: 103;
  @media screen and (max-width: 992px) {
    // left:50%;
  }
`;
const NavList = styled.ul`
  display: none;
`;
const NavItem = styled.li``;
const NavLogo = styled.li`
  position: absolute;
  top: 0.846rem;
  left: 1.54rem;
`;
const NavLink = styled.a`
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: underline;
  }
`;
const NavTitle = styled.h2`
  ${horizonCent};
  ${fz18medium};
  color: #000;
`;

//하단 메뉴바
const FooterBar = styled.footer`
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 440px;
  padding: 1.154rem 0;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 101;
  @media screen and (max-width: 992px) {
    // left:50%;
  }
`;
const FooterList = styled.ul`
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
`;
const FooterInner = styled.div`
  display: -ms-grid;
  display: grid;
  height: 3.84615rem;
  text-align: center;
`;
const FooterItem = styled.li`
  width: 20%;
`;
const FooterLink = styled.a`
  color: white;
  text-decoration: none;
`;
const FooterImg = styled.div`
  position: relative;
  // padding-bottom: 0.77rem;
  margin: 0 auto;
  padding-bottom: ${({ $active }) => {
    if ($active) {
      return "0";
    }
    return "0.77rem";
  }};
`;
const MenuBadge = styled.span`
  position: absolute;
  top: -0.385rem;
  right: -0.6rem;
  display: inline-flex;
  width: 1.077rem;
  min-width: 1.077rem;
  height: 1.077rem;
  line-height: 1.077rem;
  justify-content: center;
  border-radius: 50%;
  background-color: #ff3062;
  color: #fff;
`;

//배너
const SpotBannerWrapper = styled.div`
  width: 100%;
  height: 30vh;
  margin-bottom: 2rem;
  border-radius: 20px;
  overflow: hidden;
`;
const DcSlideWrapper = styled.div`
  position: relative;
  height: 10rem;
  margin: 0.77rem 0;
  //   opacity: 0.3;
  ${rounded};
  background-color: #fff;
  img {
    opacity: 0.3;
  }
`;
const DcBanner = styled(DcSlideWrapper)`
  height: 13.077rem;
  margin-bottom: 1.538rem;
`;
const Slide = styled.div`
  ${flxCent};
  ${whfull};
`;
const Slide2 = styled.div`
  position: relative;
  display: flex !important;
  row-gap: 1.538rem;
  flex-direction: column;
`;
const ImgGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${whfull};
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;
const SlideTxt = styled.h5`
  ${horizonCent};
  bottom: 1.15rem;
  max-width: 90%;
  font-size: 1.23rem;
  font-weight: 500;
  color: #fff;
  ${ellipsis};
`;

//탭
const TabBlack = styled.button.attrs({ type: "button" })`
  color: ${({ $active }) => {
    if ($active) {
      return "#000";
    }
    return "#A1A1A1";
  }};
`;
const TabAccent2 = styled.button.attrs({ type: "button" })`
  color: ${({ $active }) => {
    if ($active) {
      return "#FF3062";
    }
    return "#757575";
  }};
  font-size: 1.154rem;
`;
const TabAccent = styled.button.attrs({ type: "button" })`
  background-color: ${({ $active }) => ($active ? "#00BF9D" : "#E8EAED")};
  color: ${({ $active }) => ($active ? "#FFF" : "#A1A1A1")};
  font-weight: ${({ $active }) => ($active ? "700" : "400")};
  ${flxCent};
  ${fz15};
  padding: 0.47rem 0.77rem;
  border-radius: 5px;
`;
const TabMap = styled.button`
  width: 28.2%;
  height: 3.846rem;
  padding: 1rem;
  background-color: ${({ $active }) => ($active ? "#FFF" : "#F8F8F8")};
  color: ${({ $active }) => ($active ? "#FF3062" : "#757575")};
  font-weight: ${({ $active }) => ($active ? "700" : "400")};
  ${flxCent};
  font-size: 1.23rem;
`;
const TabService = styled.button.attrs({ type: "button" })`
  ${flxCent};
  ${rounded};
  ${fz15bold};
  width: calc(50% - 0.385rem);
  padding: 1.1538rem;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ $active }) => ($active ? "#00BF9D" : "#E8EAED")};
  color: ${({ $active }) => ($active ? "#00BF9D" : "#757575")};
`;
const IndexTab = styled.div`
  ${flxCent};
  margin-bottom: 3rem;
  font-size: 1.385rem;
  letter-spacing: -0.07rem;
`;
const RsvTab = styled.div`
  ${FlxMiddle};
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 2.3077rem;
  overflow-x: auto;
  & > div {
    margin-right: 0.77rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

//모달
const BtnCloseModal = styled.button.attrs({ type: "button" })`
  position: absolute;
  ${VertCent}
  left:1.538rem;
  z-index: 102;
`;
const BtnResetFilter = styled.button.attrs({ type: "button" })`
  // position: absolute;
  // top:1.538rem;
  // left:1.538rem;
  ${fz15bold};
  color: #757575;
  img {
    margin-right: 0.615rem;
  }
`;
const BtnFullModal = styled.button`
  ${flxCent};
  ${fz18medium};
  width: 100%;
  padding: 1.154rem;
  border-radius: 10px;
  background-color: #00bf9d;
  color: #fff;
  background-color: ${({ $active }) => ($active ? "#00BF9D" : "#E8EAED")};
  border-color: ${({ $active }) => ($active ? "#fff" : "#A1A1A1")};
`;
const BtnHalfModal = styled(BtnFullModal)`
  width: calc(50% - 0.385rem);
  background-color: #00bf9d;
  color: #fff;
`;
const BtnHalfModalBlack = styled(BtnHalfModal)`
  background-color: #000;
`;
const TermModal = styled.div`
  position: relative;
  padding: 0 1.538rem 2.3077rem;
`;

//모달이긴한데 리액트모달이 아닌 검은 배경의 팝업
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;
const PopOver = styled.div`
  ${toggleShow};
  position: fixed;
  top: 0;
  left: 50%;
  ${whfull}
  max-width: 440px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 102;
`;
const PopBox = styled(AbsolCent)`
  width: 90%;
  max-width: 26.92rem;
  min-height: 17rem;
  ${rounded}
  ${fz15} // z-index:101;
`;
const PopInner = styled.div`
  padding: 1.538rem;
  background-color: #fff;
`;
const PopShortMsg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 9.4rem;
  padding: 0.77rem 0;
  margin-bottom: 1.538rem;
  text-align: center;
  span {
    line-height: 1.466;
  }
`;

const SearchTop = styled.div`
  position: relative;
  height: 20.77rem;
  padding: 3.077rem 1.538rem 3.846rem;
  background-color: #00bf9d;
`;
const SearchBottom = styled.div`
  height: calc(100vh - 20.77rem);
  background-color: #fff;
`;
const SchInputWrapper = styled(FlxbtFull)`
  position: relative;
  border:1px solid #E8EAED;
  border-radius:3rem;
  padding: 0.65rem 1.538rem;
  background-color:#fff;
  ${fz15};
  input[type="text]{width:90%;}
`;
//로그인
const BtnLogin = styled.button`
  width: 100%;
  height: 3.692rem;
  margin-bottom: 0.769rem;
  border: 0;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor || "#E8EAED"};
  color: ${(props) => props.color || "#000"};
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
`;
const LoginLogo = styled.div`
  width: 100%;
  text-align: center;
  align-self: center;
  margin-bottom: 3.84rem;
`;
const LoginList = styled.div`
  margin-bottom: 1.538rem;
`;
const AbsolBtmTxt = styled.div`
  ${horizonCent};
  bottom: 3.077rem;
`;

//회원가입
const TermItem = styled.li`
    ${flxCent};
    width:100%;
    padding:0.77rem 0;
    margin-bottom: 1.538rem;
    justify-content:space-between;
    ${fz15};
    color: #000;
    cursor: pointer;
    input[type="checkbox"] {
        position:relative;
        top:0.1rem;
        margin-right:0.77rem;
    }
  };
`;
const JoinField = styled.div`
  margin-bottom: ${(props) => props.$mb || "2.3077rem"};
`;
const InputWrapper = styled(Flxbt)`
  position: relative;
  padding: 0.77rem 0;
  border-bottom: 2px solid #e8eaed;
  &:focus,
  &:focus-visible,
  &:focus-within,
  &:active,
  &:hover {
    border-color: #00bf9d;
  }
`;
const JoinInput = styled.input`
  ${fz18medium}
  width: 70%;
  background-color: #fff;
  color: #000;
  &::placehoder {
    color: #a1a1a1;
  }
`;
const JoinInputPlc15 = styled(JoinInput)`
  font-size: 1.154rem;
`;
const JoinFileLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > input[type="file"] {
    ${hide}
  }
  ${fz15}
  width: 100%;
  background-color: #fff;
  color: #000;
  & > span {
    display: inline-block;
    max-width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & > button {
    pointer-events: none;
  }
`;

const BtnInInputRound = styled.button.attrs({ type: "button" })`
  min-width: 6.92rem;
  padding: 0.62rem;
  border-radius: 3rem;
  letter-spacing: -0.07rem;
  font-weight: 700;
  color: #757575 !important;
  ${flxCent};
  ${bordered};
`;
const DescInput = styled.p`
  padding-top: 1.538rem;
  line-height: 1;
  letter-spacing: -0.054rem;
  color: #a1a1a1;
`;
const WarnInput = styled(DescInput)`
  color: #f33535;
`;
const TimeLeft = styled.span`
  ${VertCent}
  ${fz15}
    right: 0;
  padding: 0.62rem 0;
  color: #ff3062;
`;
const InfoLabel = styled.div`
  flex: 0 0 auto;
  width: 24%;
  ${fz15bold}
  white-space:nowrap;
`;
const InfoValue = styled.div`
  flex: 0 0 auto;
  ${fz15}
  color:#000;
  width: 75%;
`;
const SelectSns = styled.div`
  position: relative;
  width: 17%;
  height: 3.2307rem;
  border-bottom: 2px solid #e8eaed;
  text-align: center;
`;
const SelectSnsCurrent = styled(FlxMiddle)`
  height: 100%;
  padding: 0 0.4615rem;
  column-gap: 0.3846rem;
  color: #a1a1a1;
  cursor: pointer;
  background: url(${arrowDownGray}) no-repeat calc(100% - 0.4615rem) 55%;
`;
const SelectSnsOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  width: 100%;
  flex-direction: column;
  z-index: 102;
  border: 1px solid #e8eaed;
  background-color: #fff;
  label {
    ${flxCent};
    height: 2.8rem;
    border-bottom: 1px solid #e8eaed;
    &:last-of-type {
      border-bottom: 0;
    }
  }
  &.open {
    display: flex;
  }
`;
const SelectSnsImg = styled.img`
  width: 1.923rem;
  aspect-ratio: 1/1;
`;

//aside (내비게이션)
const VertDivider = styled(TxtDivider)`
  width: 1px;
  height: 4.6153rem;
  // margin: 0 2.3077rem;
`;

//인덱스
const FieldSet = styled.fieldset`
  ${toggleShow};
`;
const RoundImg = styled.div`
  position: relative;
  ${flxCent};
  height: 8.46rem;
  margin-bottom: 1.154rem;
  ${rounded}
`;
const RoundProfile = styled(RoundImg)`
  width: 28.58%;
  aspect-ratio: 1/1;
  margin-bottom: 0;
  ${rounded}
`;
const FixedLike = styled.button.attrs({ type: "button" })`
  position: absolute;
  bottom: 0.38rem;
  right: 0.615rem;
  z-index: 2;
`;
const Stars = styled.div`
  display: inline-flex;
  column-gap: 0.23rem;
  color: #ffbb00;
`;
const RateScore = styled(ColGray1)`
  ${fz18medium}
`;
const FooterBg = styled.div`
  padding: 2.3rem 0 3rem;
  background-color: #f8f8f8;
`;
const ShopAddress = styled.h4`
  ${ellipsis};
`;
const DesignerInfo = styled.div`
  width: 65.8%;
`;
const DesignerName = styled(Fz16Medium)`
  max-width: 70%;
  color: #000;
`;
const ProfileTag = styled.span`
  ${inlineFlxCent};
  padding: 0.3rem;
  margin-right: 0.25em;
  border: 1px solid #a1a1a1;
  border-radius: 1em;
  background-color: #fff;
  font-size: 0.923rem;
  font-weight: 500;
  white-space: nowrap;
`;
const ProfileTagGreen = styled(ProfileTag)`
  padding: 0.62rem 0.77rem;
  margin: 0.3rem 0.25rem 0.3rem 0;
  border-color: #e8eaed;
  font-size: 1.154rem;
  color: #00bf9d;
`;

//할인
const DateInfo = styled(Flxbt)`
  padding: 0.923rem 0;
  font-size: 1.154rem;
`;
const BtnToggleAccent = styled.button.attrs({ type: "button" })`
  background-color: ${({ $active }) => ($active ? "#00BF9D" : "#F8F8F8")};
  color: ${({ $active }) => ($active ? "#FFF" : "#757575")};
  ${flxCent};
  ${fz15};
  padding: 0.77rem;
  border: 1px solid #e8eaed;
  border-radius: 3rem;
`;
const DashDivider = styled.div`
  width: 100%;
  height: 0;
  margin-bottom: 2.3rem;
  border-top: 1px dashed #a1a1a1;
  box-sizing: content-box;
  overflow: visible;
`;
const DesignerPrices = styled(ListColumn)`
  width: 100%;
  //   margin-bottom: 1.154rem;
  ${fz15};
`;
const PriceItem = styled(Flxbt)`
  width: 100%;
  padding-top: 1.154rem;
  padding-bottom: 1.154rem;
  margin-bottom: 1.154rem;
  border-bottom: 1px solid #e8eaed;
  flex-wrap: wrap;
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
`;
const OrgPrice = styled.span`
  margin-right: 0.77rem;
  text-decoration: line-through;
  ${txtcolor}
`;
const ConsumerPrice = styled.strong`
  color: #000;
`;

//지역
const MapNav = styled.div`
  position: relative;
  width: 100%;
  border-top: 1px solid #e8eaed;
`;
const MapStep1 = styled.li`
  display: flex;
  flex-wrap: wrap;
`;
const MapStep2ul = styled.ul`
  position: absolute;
  display: ${({ $active }) => ($active ? "flex" : "none")};
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 28.2%;
  min-height: 100%;
  max-height: 45rem;
  // 원래는 41rem
  overflow-y: auto;
  flex-direction: column;
  li {
    display: flex;
    width: 100%;
    height: 3.846rem;
    min-height: 3.846rem;
    padding: 1rem 1.538rem;
    align-items: center;
    ${fz15}
  }
`;
const BtnAroundFilter = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: 1.538rem;
  right: 1.538rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: #ff3062;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
  z-index: 101;
`;
const HalfField = styled.div`
  width: 40%;
`;
const AroundList = styled.div`
  padding-top: 2.3077rem;
  .slick-slider {
    padding-bottom: 2.3077rem;
    margin-bottom: 2.3077rem;
    border-bottom: 1px solid #e8eaed;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
//디자이너 상세
const DesignerProfile = styled.div`
  ${fz15}
  margin-bottom: 4rem;
`;
const DetailDcBanner = styled.div`
  height: 15.385rem;
  overflow: hidden;
`;
const DetailTab = styled(FlxbtFull)``;
const TabThird = styled.button.attrs({ type: "button" })`
  ${flxCent}
  ${fz16medium}
    width:33.33333333%;
  flex: 0 0 auto;
  padding: 1.154rem 0.4rem;
  border-top: ${({ $active }) => {
    if ($active) {
      return "1px solid #00BF9D";
    }
    return "1px solid #E8EAED";
  }};
  border-bottom: ${({ $active }) => {
    if ($active) {
      return "1px solid #fff";
    }
    return "1px solid #E8EAED";
  }};
  background: ${({ $active }) => {
    if ($active) {
      return "linear-gradient(to bottom, #00BF9D 0%, #00BF9D 3.85%, #fff 3.85%, #fff 100%)";
    }
    return "#F8F8F8";
  }};
`;
const DetailContents = styled.div`
  ${toggleShow};
  padding: 2.3077rem 0;
  ${fz15}
`;
const OpenHoursValue = styled(Flxbt)`
  width: 85%;
  flex: 0 0 auto;
  color: #000;
`;
const PhotoItemBorder = styled(PhotoItem)`
  border: 1px solid #e8eaed;
`;
const PriceItemBorder = styled(PriceItem)`
  margin-bottom: 0;
  border-bottom: 0;
  border-top: 1px solid #e8eaed;
  &:last-of-type {
    padding-bottom: 1.154rem;
    border-bottom: 1px solid #e8eaed;
  }
`;
const ItemReview = styled.div`
  padding: 2.3077rem 0;
  border-bottom: 1px solid #e8eaed;
`;
const ReviewDate = styled.span`
  ${fz15}
  color: #A1A1A1;
`;
const ReviewStars = styled(Stars)`
  padding: 1.154rem 0;
  color: #000;
`;
const ModalHeader = styled.div`
  position: absolute;
  top: 0;
  ${flxCent}
  width: 100%;
  height: 4.15rem;
  background-color: #fff;
  z-index: 101;
`;
const ModalPhoto = styled.div`
  position: relative;
  width: 100%;
  max-height: 68vh;
`;
const BtnModalPhoto = styled.button.attrs({ type: "button" })`
  position: absolute;
  ${VertCent};
  ${flxCent}
  width:3.077rem;
  height: 3.077rem;
  background-color: rgba(0, 0, 0, 0.1);
`;
const BtnModalPhotoLeft = styled(BtnModalPhoto)`
  left: 0.77rem;
`;
const BtnModalPhotoRight = styled(BtnModalPhoto)`
  right: 0.77rem;
`;
const Block = styled.div`
  padding-bottom: 3.846rem;
  ${fz15}
`;

//마이페이지
const MyHeader = styled(FlxbtFull)`
  padding: 2.3077rem 1.538rem;
  margin-bottom: 2.3077rem;
  border-bottom: 1px solid #e8eaed;
`;
const FloatSetting = styled(Flx)`
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  width: 6rem;
  background-color: #fff;
  border: 1px solid #e8eaed;
  border-bottom: 0;
  flex-direction: column;
  z-index: 2;
  ${fz15};
  ${toggleShow};
  button {
    width: 100%;
    padding: 1rem;
    ${flxCent};
    border-bottom: 1px solid #e8eaed;
  }
`;
const NoticeTitle = styled.h5`
  width: 100%;
  max-width: 24rem;
  ${fz15};
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const NoticeLabel = styled.span`
  ${inlineFlxCent};
  padding: 0.3rem;
  margin-right: 1.154rem;
  border-radius: 3rem;
  background-color: #00bf9d;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
`;
const ArchiveThumb = styled.div`
  ${flxCent}
  ${rounded}
    height:15.385rem;
`;
const PostImg = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 1rem 0;
  img {
    width: 100%;
  }
`;
const RadioLabel = styled.label`
  display: flex;
  width: 100%;
  align-items: center;
  ${fz15};
  color: #000;
`;

//내 예약
const HistoryList = styled.div`
  width: 100%;
  // min-height:100%;
  min-height: 48vh;
  padding: 2.3077rem 0;
  background-color: #f8f8f8;
  border-top: 1px solid #e8eaed;
`;
const HistoryBox = styled(BorderBox)`
  margin-bottom: 1.538rem;
`;
const BtnLineGray = styled.button.attrs({ type: "button" })`
  ${flxCent};
  padding: 0.5rem 0.92rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  white-space: nowrap;
  background-color: #fff;
  border-color: ${({ $active }) => ($active ? "#00BF9D" : "#757575")};
  color: ${({ $active }) => ($active ? "#00BF9D" : "#757575")};
  font-weight: 700;
`;
const FileFullLabel = styled.label`
  width: 100%;
  padding: 0.95rem;
  border-radius: 10px;
  column-gap: 0.4rem;
  ${flxCent};
  ${fz15bold};
  color: #757575 !important;
  ${bordered};
`;
const FileSquareLabel = styled.label`
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid #e8eaed;
  background: url(${icPlus}) no-repeat center/auto #fff;
`;
const StyleInput = styled.input.attrs({ type: "text" })`
  width: 100%;
  padding: 1.154rem 0;
  ${fz15};
  color: #000;
  border-bottom: 2px solid #e8eaed !important;
  &::placeholder {
    color: #a1a1a1;
  }
`;

//상품 메뉴 등록
const Select = styled.select`
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  background: url(${arrowDownGray}) no-repeat calc(100% - 1.154rem) center;
`;
const SettingOptions = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  width: 100%;
  max-width: 80px;
  flex-direction: column;
  z-index: 102;
  border: 1px solid #e8eaed;
  background-color: #fff;
  button {
    ${flxCent};
    height: 2.8rem;
    border-bottom: 1px solid #e8eaed;
    &:last-of-type {
      border-bottom: 0;
    }
  }
  &.open {
    display: flex;
  }
`;
//휴무일 관리
const BtnToggleAccent2 = styled(BtnToggleAccent)`
  min-width: 6.154rem;
  padding: 1rem;
  background-color: #fff;
  color: #000;
  background-color: ${({ $active }) => ($active ? "#00BF9D" : "#f8f8f8")};
  border-color: ${({ $active }) => ($active ? "#00BF9D" : "#E8EAED")};
  color: ${({ $active }) => ($active ? "#FFF" : "#a1a1a1")};
  ${fz16medium};
`;
//디자이너 예약 관리
const RsvStatusGray = styled.span`
  ${flxCent};
  padding: 0.5rem 0.77rem;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  border-radius: 3rem;
  border-color: #a1a1a1;
  background-color: #f8f8f8;
  color: #a1a1a1;
  font-size: 1rem;
`;
const RsvStatusPink = styled(RsvStatusGray)`
  border-color: #ff3062;
  background-color: #ffebeb;
  color: #ff3062;
`;
const RsvStatusGreen = styled(RsvStatusGray)`
  border-color: #00bf9d;
  background-color: #e5f6f3;
  color: #00bf9d;
`;
const TableWrapper = styled.div`
  ${fz15};
  padding-top: 1.538rem;
  background-color: #fff;
  min-width: 26.923rem;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th,
  td {
    border-right: 1px solid #e8eaed;
    border-bottom: 1px solid #e8eaed;
  }
  th {
    min-width: 5rem;
    font-weight: 400;
    color: #000;
  }
  td {
    padding: 0.385rem;
    &:has(button:disabled) {
      background-color: #f8f8f8;
    }
  }
  thead th {
    padding: 1rem;
    border-bottom: 2px solid #00bf9d;
    &:last-of-type {
      border-right: 0;
    }
  }
`;

// FAQ
const FaqItem = styled.ul`
  ${fz15};
  button,
  a {
    width: 90%;
    padding: 2.3077rem 0 1.538rem;
    margin: 0 auto;
    border-bottom: 1px solid #e8eaed;
    &.on {
      border: 0;
    }
  }
  a {
    flex-wrap: wrap;
    h4 {
      width: 100%;
      margin-bottom: 1.154rem;
    }
  }
`;
const FaqContent = styled(Pad20)`
  ${toggleShow};
  background-color: #f8f8f8;
`;
const QnaAnswerHeader = styled.div`
  padding: 0 1.538rem 2.3077rem;
  margin-bottom: 2.3077rem;
  border-bottom: 1px solid #e8eaed;
`;
const ShortMap = styled.div`
    width: 100%;
    height: 18.5rem;
`;
export {
  AbsolBtmBtn,
  AbsolBtmBtn2,
  AbsolBtmTxt,
  AbsolCent,
  ArchiveThumb,
  AroundList,
  PhotoItemBorder,
  Block,
  BorderBox,
  BtnAroundFilter,
  BtnClearInput,
  BtnCloseModal,
  BtnDelete,
  BtnFullLine,
  BtnFullLineGreen,
  BtnFullModal,
  BtnHalfModal,
  BtnHalfModalBlack,
  BtnInInputRound,
  BtnLineGray,
  BtnLineGreenShorter,
  BtnLineStatus,
  BtnStatusCancel,
  BtnStatusComplete,
  BtnStatusConfirmed,
  BtnStatusDisabled,
  BtnStatusNoshow,
  BtnLogin,
  BtnResetFilter,
  BtnModalPhoto,
  BtnModalPhotoLeft,
  BtnModalPhotoRight,
  BtnToggleAccent,
  BtnToggleAccent2,
  CircleImg,
  Col2Grid,
  ColAccent,
  ColAccent2,
  ColGray1,
  ColGray2,
  ConsumerPrice,
  Container,
  ContainerFit,
  ContainerPadding,
  DashDivider,
  DateInfo,
  DcBanner,
  DcSlideWrapper,
  DescInput,
  DesignerInfo,
  DesignerName,
  DesignerPrices,
  DesignerProfile,
  DetailDcBanner,
  DetailContents,
  DetailTab,
  Divider,
  FaqItem,
  FaqContent,
  FieldSet,
  FileFullLabel,
  FileSquareLabel,
  FixedLike,
  FloatSetting,
  Flx,
  Flxbt,
  FlxbtFull,
  FlxbtFullMb10,
  FlxbtFullMb15,
  FlxbtFullMb20,
  FlxbtFullMb30,
  FlxbtFullMb40,
  FlxbtFullMb50,
  FlxMiddle,
  FlxMiddleMb10,
  FlxMiddleMb20,
  FlxMiddleMb30,
  FlxRight,
  FooterBar,
  FooterBg,
  FooterImg,
  FooterInner,
  FooterItem,
  FooterLink,
  FooterList,
  FullImg,
  FullLine,
  Fz15,
  Fz15Bold,
  Fz16Medium,
  Fz18Bold,
  Fz18Medium,
  Fz20Bold,
  Fz30Bold,
  Gap10,
  Gap15,
  Gap20,
  Gap30,
  GridImg,
  HalfField,
  HistoryBox,
  HistoryList,
  ImgCent,
  ImgGradient,
  IndexTab,
  InfoLabel,
  InfoValue,
  InputHidden,
  InputWrapper,
  ItemDesigner,
  ItemHalf,
  ItemHalf2,
  ItemInfoCol2,
  ItemReview,
  JoinField,
  JoinFileLabel,
  JoinInput,
  JoinInputPlc15,
  LineCenter,
  LineGrayBox,
  LineGrayBoxHigher,
  LineGrayBoxFull,
  LinkFlx,
  LinkFlxbtFull,
  LinkFlxMiddle,
  LinkFull,
  List,
  ListCol2,
  ListCol3,
  ListCol4,
  ListColumn,
  LiStyled,
  LoginList,
  LoginLogo,
  MapLayout,
  MapNav,
  MapStep1,
  MapStep2ul,
  Mb1rem,
  Mb10,
  Mb15,
  Mb20,
  Mb30,
  Mb40,
  MenuBadge,
  ModalHeader,
  ModalPhoto,
  MyHeader,
  NavBar,
  NavHeight,
  NavItem,
  NavLink,
  NavList,
  NavLogo,
  NavTitle,
  NoticeLabel,
  NoticeTitle,
  OpenHoursValue,
  OrgPrice,
  Overlay,
  Pad20,
  PadVert10,
  PadVert20,
  PadVert30,
  Paragraph,
  PhotoGrid,
  PhotoGridCol2,
  PhotoItem,
  PopBox,
  PopInner,
  PopOver,
  PopShortMsg,
  PostImg,
  PriceItem,
  PriceItemBorder,
  ProfileTag,
  ProfileTagGreen,
  QnaAnswerHeader,
  RadioLabel,
  RateScore,
  ReviewDate,
  ReviewStars,
  RoundImg,
  RoundProfile,
  RsvStatusGray,
  RsvStatusGreen,
  RsvStatusPink,
  RsvTab,
  SchInputWrapper,
  SearchTop,
  SearchBottom,
  SectionDivider,
  SectionDivider40,
  SectionDivider50,
  Select,
  SelectSns,
  SelectSnsCurrent,
  SelectSnsImg,
  SelectSnsOptions,
  SettingOptions,
  ShopAddress,
  Slide,
  Slide2,
  SlideTxt,
  Spacing10,
  Spacing20,
  Spacing30,
  Spacing40,
  Spacing36,
  Spacing50,
  Spacing70,
  SpotBannerWrapper,
  Stars,
  StyleInput,
  StyleItem,
  TabAccent,
  TabAccent2,
  TabBlack,
  TabItem,
  TableWrapper,
  TabMap,
  TabService,
  TabThird,
  TermItem,
  TermModal,
  Textbox,
  TextBoxCount,
  TextBoxWrapper,
  TextLine,
  TimeItem,
  TimeItem2,
  TimeLeft,
  ToggleShow,
  TxCent,
  TxRight,
  TxtDivider,
  TxtDivider2,
  TxLabelRight,
  TxLabelLeft,
  Underline,
  VertDivider,
  WarnInput,
  Wrap,
  ShortMap
};
