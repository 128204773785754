import { apiService } from "./api";

const archiveApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getArchives: builder.query({
      query: ({ page, limit, searchText }) => ({
        url: `/boards/archive?page=${page}&text=${searchText || ''}&limit=${limit}`,
        method: 'GET',
      })
    }),
    getArchive: builder.query({
      query: id => ({
        url: `/boards/archive/${id}`,
        method: 'GET',
      })
    }),
    createArchive: builder.mutation({
      query: archive => ({
        url: '/boards/archive',
        method: 'POST',
        body: archive,
      })
    }),
    updateArchive: builder.mutation({
      query: ({ id, data }) => ({
        url: `/boards/archive/${id}`,
        method: 'PUT',
        body: data,
      })
    }),
    deleteArchive: builder.mutation({
      query: id => ({
        url: `/boards/archive/${id}`,
        method: 'DELETE',
      })
    })
  }),
});

export const { useGetArchivesQuery, useGetArchiveQuery, useCreateArchiveMutation, useUpdateArchiveMutation, useDeleteArchiveMutation } = archiveApi;