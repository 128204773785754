import { apiService } from "./api";

export const shopApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getShops: builder.query({
      query: ({ page, limit, searchText, searchStatus }) => ({
        url: `/shops?page=${page}&text=${searchText || ''}&status=${searchStatus || ''}&limit=${limit}`,
        method: 'GET',
      })
    }),
    getShop: builder.query({
      query: ( id ) => `/shops/${id}`,
    }),
    createShop: builder.mutation({
      query: (data) => ({
        url: '/shops',
        method: 'POST',
        body: data
      }),
    }),
    updateShop: builder.mutation({
      query: ({id, data}) => ({
        url: `/shops/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteShop: builder.mutation({
      query: ( id ) => ({
        url: `/shops/${id}`,
        method: 'DELETE',
      }),
    }),
    approveShop: builder.mutation({
      query: ( id ) => ({
        url: `/shops/${id}/approve`,
        method: 'PUT',
      }),
    }),
    rejectShop: builder.mutation({
      query: ( id ) => ({
        url: `/shops/${id}/reject`,
        method: 'PUT',
      }),
    }),
  }),
});

export const { useGetShopsQuery, useGetShopQuery, useCreateShopMutation, useUpdateShopMutation, useDeleteShopMutation, useApproveShopMutation, useRejectShopMutation } = shopApi;