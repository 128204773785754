import React, { useState, useEffect } from 'react';
import {
    TitleLine,
    SysContainer,
    Input,
    FieldGroup,
    InputItem,
    FormBorderb,
    BottomBtnsRight,
    BtnMd,
    BtnMdSubmit,
    Fz20Bold,
    Fz15,
    ChkAndTxt,
    EditorWrapper,
} from '@/css/admin/style';
import Chkbox from '@/components/Chkbox';
import FormCKEditor from '@/components/FormCKEditor';
import { useUpdateNoticeMutation, useGetNoticeQuery } from '@services/notice';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function NoticeEditForm() {
    const [top, setTop] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [category, setCategory] = useState([]);

    const navigate = useNavigate();

    const { id } = useParams();

    const { data, isLoading, error } = useGetNoticeQuery(id, { refetchOnMountOrArgChange: true });

    const [updateNotice, { isLoading: updateIsLoading, error: updateError }] = useUpdateNoticeMutation();

    useEffect(() => {
        if (data) {
            setCategory(data.category || []);
            setTitle(data.title);
            setContent(data.body);
            setTop(data.top);
        }
    }, [data]);

    const handleUpdateNotice = async (e) => {
        e.preventDefault();
        if (!category.length) {
            toast.error('노출 대상을 선택해주세요.');
            return;
        }
        if (!title) {
            toast.error('제목을 입력해주세요.');
            return;
        }
        if (!content) {
            toast.error('내용을 입력해주세요.');
            return;
        }

        try {
            confirmAlert({
                message: '수정하시겠습니까?',
                buttons: [
                    {
                        label: '확인',
                        onClick: async () => {
                            const formData = new FormData();

                            formData.append('category', category);
                            formData.append('title', title);
                            formData.append('body', content);
                            formData.append('top', top);

                            await updateNotice({ id, data: formData });
                            toast.success('수정되었습니다.');
                            navigate('/notices');
                        }
                    },
                    {
                        label: '취소',
                        onClick: () => {}
                    }
                ]
            });
        } catch (err) {
            toast.error('수정에 실패했습니다.');
        }
    }

    const handleList= () => {
        navigate('/notices');
    }

    const handleChangeCategory = (event) => {
        const { value, checked } = event.target;
    
        if (checked) {
          // 체크된 경우, 선택된 항목에 추가
          setCategory((prev) => [...prev, value]);
        } else {
          // 체크가 해제된 경우, 선택된 항목에서 제거
          setCategory((prev) => prev.filter((item) => item !== value));
        }
    };
   
    return (
        <>
            <SysContainer>
                <form onSubmit={handleUpdateNotice}>
                    <Fz20Bold>공지사항</Fz20Bold>
                    <TitleLine />
                    <FieldGroup>
                    <InputItem $wd="100%">
                        <Fz15>대상</Fz15>
                            <ChkAndTxt>
                                <Chkbox
                                    checked={category.includes('manager')}
                                    value="manager"
                                    onChange={handleChangeCategory}
                                />
                                대리점
                            </ChkAndTxt>
                            <ChkAndTxt>
                                <Chkbox
                                    checked={category.includes('designer')}
                                    value="designer"
                                    onChange={handleChangeCategory}
                                />
                                디자이너
                            </ChkAndTxt>
                            <ChkAndTxt>
                                <Chkbox
                                    checked={category.includes('user')}
                                    value="user"
                                    onChange={handleChangeCategory}
                                />
                                고객
                            </ChkAndTxt>
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>공지</span>
                            <Chkbox checked={top} onChange={e=>setTop(!top)}/>
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>제목</span>
                            <Input type="text" defaultValue={title} placeholder={'제목을 입력해 주세요.'} onChange={e=>setTitle(e.target.value)}/>
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>내용</span>
                            <EditorWrapper>
                                <FormCKEditor
                                    data={content}
                                    handleChange={setContent}
                                />
                            </EditorWrapper>
                        </InputItem>
                    </FieldGroup>
                    <FormBorderb />
                    <BottomBtnsRight>
                        <BtnMd $bg="#fff" $bc="#00BF9D" $col="#00BF9D" onClick={()=>handleList()}>
                            목록
                        </BtnMd>
                        <BtnMdSubmit>수정</BtnMdSubmit>
                    </BottomBtnsRight>
                </form>
            </SysContainer>
        </>
    );
}

export default NoticeEditForm;
