import { createGlobalStyle } from 'styled-components';
import Router from '@/routes';
import '@fontsource/noto-sans-kr';
import { useDispatch } from 'react-redux';
import { useRefreshTokenMutation } from '@services/auth';
import { setCredentials, setLogout } from '@redux/slice/authSlice';
import { setInitialized } from '@redux/slice/commonSlice';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = createGlobalStyle`
  *,*::before,*::after {box-sizing: border-box;}
  html,
  body{
      width: 100%;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      word-break:keep-all;
  }
  html{
      font-size: 13px;
      @media screen and (max-width:1800px) {
          font-size:12.5px;
      }
  }
  body{
      min-width: 1000px;
      color: #000;
      line-height: 1;
      letter-spacing:-0.025rem;
      font-family: "Noto Sans KR",system-ui,-apple-system,'Segoe UI',helvetica,sans-serif;
      font-weight: 400;
      -webkit-text-size-adjust: none;
      -webkit-font-smoothing:antialiased;
  }
  a,body,button,blockquote,button,caption,dd,dialog,div,details,dl,dt,fieldset,figure,form,h1,h2,h3,h4,h5,h6,html,img,input,legend,li,ol,p,pre,select,summary,textarea,ul,video{
      padding: 0;
      margin: 0;
  }
  a{
      text-decoration: none;
      color: inherit;
  }
  img, video {vertical-align: middle;}
  li,menu,ol,ul{list-style: none}
  h1,h2,h3,h4,h5,h6,button,input,optgroup,select,textarea{font-size: inherit;font-weight: inherit}
  input[type="text"],
  input[type="password"],
  input[type="checkbox"],
  input[type="radio"],
  input[type="file"],
  input[type="color"],
  textarea{
      -webkit-appearance:none;
      appearance:none;
      border: 0;
      outline:0;
  }
  input::placeholder,
  textarea::placeholder{font-size:inherit;font-family:inherit;}
  button,
  select {
      border: 0;
      outline: 0;
      background: none;
      cursor: pointer;
      line-height:1;
  }
  fieldset {
      border: 0;
      outline: 0;
      background: none;
  }
  strong {font-weight: 700;}
  p {line-height: 1.3;}
  address {font-style:normal;}
  label {cursor:pointer;}

  /*** 페이징 ***/

  /* 페이징 컨테이너 (ReactPaginate 전체 영역) */
  .paging .react-paginate {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
  }
  .paging {
    display:flex;
  justify-content:center;
}
.paging li:has(.paging_first),
.paging li:has(.paging_end){
  margin: 0 4px;
}
.paging_first::before,
.paging_end::before{
  font-family:sans-serif;
  font-size:1.25em;
}
.paging_first::before{content:"<"}
.paging_end::before{content:">"}
.paging li {
  display:flex;
  border-radius: 50%;
  background-color:#fff;
  overflow:hidden;
}
.paging li:focus-visible,
.paging_num:focus-visible, {
  outline:0;
}
.paging li.selected,
.paging li:not(.disabled):hover,
.paging li:not(.disabled):focus-visible{
  background-color:#E5F6F3;
  font-weight:700;
}
.paging_num {
  display:flex;
  width: 2.77rem;
  min-width: 2.77rem;
  height: 2.77rem;
  align-items:center;
  justify-content:center;
  color:#4e5969;
  line-height:0;
  cursor:pointer;
}
.paging li.selected .paging_num,
.paging li:not(.disabled):hover .paging_num,
.paging li:not(.disabled):focus-visible .paging_num{
  color:#00BF9D;
}

  /* 브레이크 (페이지 번호 표시 중단 부분) */
  .paging .paging_break {
    pointer-events:none;
    color: #ccc;
  }
  
  .react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.8);
  }
  .react-confirm-alert {
    width:100%;
    max-width:430px;
  }
  .react-confirm-alert-body {
    width: auto !important;
    padding:3.846rem 2.3077rem 2.3077rem;
    border-radius:4px;
    text-align:center;
    color:#000;
  }
  .react-confirm-alert-button-group {
    margin-top:3rem;
    justify-content:center;
    flex-direction:row-reverse;
  }
  .react-confirm-alert-button-group > button {
    display:flex;
    padding:1.154rem;
    margin-right:0;
    align-items:center;
    justify-content:center;
    width:calc(50% - 0.77rem);
    border-radius:10px;
    color:#fff;
    font-size:1.23rem;
    font-weight:500;
  }
  .react-confirm-alert-button-group > button[label="확인"],
  .react-confirm-alert-button-group > button[label="예"] {
    background-color:#00BF9D;
  }
  .react-confirm-alert-button-group > button[label="취소"],
  .react-confirm-alert-button-group > button[label="아니요"] {
    margin-right:0.77rem;
    background-color:#F33535;
  }
`;
function App() {
    const [refreshToken] = useRefreshTokenMutation();
    const dispatch = useDispatch();

    useEffect(() => {
        refreshToken()
            .unwrap()
            .then((data) => {
              if(data.user.role !== 'ADMIN'){
                dispatch(setLogout());
                return;
              }
              dispatch(setCredentials(data));
            })
            .catch((err) => {
                dispatch(setLogout());
            })
            .finally(() => {
                dispatch(setInitialized(false));
            });
    }, []);

    return (
        <>
            <GlobalStyle />
            <Router />
            <ToastContainer />
        </>
    );
}

export default App;
