import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Chkbox from '@/components/Chkbox';
import {
    Block50,
    SysContainer,
    Fz30Bold,
    Fz15,
    InputShort,
    BtnSearch,
    BtnMd,
    ListTableWrapper,
    BtnTable,
    BottomBtns,
    SlashTxt,
    Gap30,
    ListInputs,
    ColRed,
    ColPurple,
    ColAccent,
    FlxMiddleMb20,
    ChkAndTxt,
    Fz15Bold,
    ModalAbsolBtn,
    BtnFullModal,
    Mb20,
    ItemInfoCol2,
    InfoLabel,
    Gap10,
    InfoValue,
    Paragraph,
    SlideImg,
    Mb30,
    TdBtn,
    TdSm,
} from '@/css/admin/style';
import icSearch from '@/assets/img/ic_search.svg';
import icPhoto from '@/assets/img/ic_photo.svg';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import {
    useGetReviewsQuery,
    useUpdateReviewActiveMutation,
    useUpdateReviewInactiveMutation,
    useDeleteReviewMutation,
} from '@services/review';
import config from '@config';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function ReviewList() {
    const searchInput = React.createRef();
    const [checkList, setCheckList] = useState([]);

    const [searchText, setSearchText] = useState(null);
    const [searchStatus, setSearchStatus] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [page, setPage] = useState(1);

    const [pageLimit, setPageLimit] = useState(10);

    const { data, isLoading, error, refetch } = useGetReviewsQuery(
        { page, limit: pageLimit, searchText, searchStatus },
        { refetchOnMountOrArgChange: true }
    );
    const [updateReviewActive] = useUpdateReviewActiveMutation();
    const [updateReviewInactive] = useUpdateReviewInactiveMutation();
    const [deleteReview] = useDeleteReviewMutation();

    const handleSearchClick = () => {
        const text = searchInput.current.value;
        setPage(1);
        setSearchText(text);
    };

    const handlePageClick = (e) => {
        const { selected } = e;
        setPage(selected + 1);
    };

    const handleSearchStatus = (status) => {
        setPage(1);
        setSearchStatus(status);
        refetch();
    };

    const handleDelete = async () => {
        if (checkList.length === 0) {
            toast.error('삭제할 리뷰를 선택해주세요.');
            return;
        }

        confirmAlert({
            message: '리뷰를 삭제하시겠습니까?',
            buttons: [
                {
                    label: '예',
                    onClick: async () => {
                        try {
                            await Promise.all(checkList.map((id) => deleteReview(id)));
                            toast.success('리뷰를 삭제하였습니다.');
                            refetch();
                        } catch (e) {
                            toast.error('리뷰 삭제에 실패하였습니다.');
                        }
                    },
                },
                {
                    label: '아니요',
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: 'overlay-custom-class-name',
        });
    };

    const handleActive = async () => {
        if (checkList.length === 0) {
            toast.error('노출할 리뷰를 선택해주세요.');
            return;
        }

        confirmAlert({
            message: '리뷰를 노출하시겠습니까?',
            buttons: [
                {
                    label: '예',
                    onClick: async () => {
                        try {
                            await Promise.all(checkList.map((id) => updateReviewActive(id)));
                            toast.success('리뷰를 노출하였습니다.');
                            refetch();
                        } catch (e) {
                            toast.error('리뷰 노출에 실패하였습니다.');
                        }
                    },
                },
                {
                    label: '아니요',
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: 'overlay-custom-class-name',
        });
    };

    const handleInactive = async () => {
        if (checkList.length === 0) {
            toast.error('숨길 리뷰를 선택해주세요.');
            return;
        }

        confirmAlert({
            message: '리뷰를 숨기시겠습니까?',
            buttons: [
                {
                    label: '예',
                    onClick: async () => {
                        try {
                            await Promise.all(checkList.map((id) => updateReviewInactive(id)));
                            toast.success('리뷰를 숨겼습니다.');
                            refetch();
                        } catch (e) {
                            toast.error('리뷰 숨김에 실패하였습니다.');
                        }
                    },
                },
                {
                    label: '아니요',
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: 'overlay-custom-class-name',
        });
    };

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal(selectdItem) {
        setSelectedItem(selectdItem);
        setIsOpen(true);
    }
    function closeModal() {
        setSelectedItem(null);
        setIsOpen(false);
    }
    return (
        <>
            <SysContainer>
                <Fz30Bold $mb="3.077rem">리뷰 관리</Fz30Bold>
                <FlxMiddleMb20>
                    <Fz15>상태</Fz15>
                    <Gap30 />
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === ''}
                            onChange={() => {
                                handleSearchStatus('');
                            }}
                        />
                        전체
                    </ChkAndTxt>
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === 'delete'}
                            onChange={() => {
                                handleSearchStatus('delete');
                            }}
                        />
                        삭제
                    </ChkAndTxt>
                    {/* <ChkAndTxt>
                        <Chkbox />
                        승인대기
                    </ChkAndTxt> */}
                    <ChkAndTxt>
                        <Chkbox
                            checked={searchStatus === 'active'}
                            onChange={() => {
                                handleSearchStatus('active');
                            }}
                        />
                        노출
                    </ChkAndTxt>
                    <ChkAndTxt $mr="0">
                        <Chkbox
                            checked={searchStatus === 'inactive'}
                            onChange={() => {
                                handleSearchStatus('inactive');
                            }}
                        />
                        숨김
                    </ChkAndTxt>
                </FlxMiddleMb20>
                {/* <ListInputs>
                    <Fz15>디자이너 이름</Fz15>
                    <InputShort placeholder="디자이너 명" />
                    <Fz15>고객 이름</Fz15>
                    <InputShort placeholder="고객명" />
                    <BtnSearch>
                        <img src={icSearch} alt="" />
                        검색
                    </BtnSearch>
                </ListInputs> */}
                <Block50>
                    <ListTableWrapper>
                        <table>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>체크</th>
                                    <th>상태</th>
                                    <th>고객 이름</th>
                                    <th>시술 상품</th>
                                    <th>디자이너</th>
                                    <th>시술일</th>
                                    <th>별점</th>
                                    <th>등록일</th>
                                    <th>이미지</th>
                                    {/* <th>상태</th> */}
                                    <th>내용</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.docs.length ? (
                                    data.docs.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>
                                                    {data.totalDocs -
                                                        (page - 1) * pageLimit -
                                                        index}
                                                </td>
                                                <TdSm>
                                                    {!item.deleted ? (
                                                        <Chkbox
                                                            checked={checkList.includes(item.id)}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setCheckList([
                                                                        ...checkList,
                                                                        item.id,
                                                                    ]);
                                                                } else {
                                                                    setCheckList(
                                                                        checkList.filter(
                                                                            (id) => id !== item.id
                                                                        )
                                                                    );
                                                                }
                                                            }}
                                                            disabled={item.deleted}
                                                        />
                                                    ) : null}
                                                </TdSm>
                                                <td>
                                                    {item.deleted ? (
                                                        <ColRed>삭제</ColRed>
                                                    ) : item.actived ? (
                                                        <ColAccent>노출</ColAccent>
                                                    ) : (
                                                        <ColPurple>숨김</ColPurple>
                                                    )}
                                                </td>
                                                <td>{item.user.name}</td>
                                                <SlashTxt>
                                                    {item.reservation?.menus.map((menu, index) => {
                                                        return <span key={index}>{menu.name}</span>;
                                                    })}
                                                </SlashTxt>
                                                <td>{item.reservation.designer.title}</td>
                                                <td>
                                                    {item.reservation.date} {item.reservation.time}
                                                </td>
                                                <td>{item.rating}</td>
                                                <td>
                                                    <Moment format="YY.MM.DD">
                                                        {item.created}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    {item.images.length > 0 ? (
                                                        <>
                                                            포토 리뷰{' '}
                                                            <img src={icPhoto} alt="이미지" />
                                                        </>
                                                    ) : null}
                                                </td>
                                                {/* <td>
                                        메모: 상태에 따라 색상 분기
                                        요청
                                        <ColAccent>승인</ColAccent>
                                        <ColPurple>보이기</ColPurple>
                                        <ColRed>감추기</ColRed>
                                    </td> */}
                                                <TdBtn>
                                                    <BtnTable onClick={() => openModal(item)}>
                                                        내용보기
                                                    </BtnTable>
                                                </TdBtn>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="11">
                                            {isLoading ? (
                                                <ClipLoader
                                                    color={'#00bf9d'}
                                                    size={35}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            ) : (
                                                '데이터가 없습니다.'
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </ListTableWrapper>
                    {!isLoading && (
                        <ReactPaginate
                            className="paging"
                            breakLabel="..."
                            nextLabel={<span className="paging_end pl30"></span>}
                            onPageChange={handlePageClick}
                            forcePage={page - 1}
                            pageRangeDisplayed={3}
                            pageCount={data?.totalPages}
                            previousLabel={<span className="paging_first pl30"></span>}
                            renderOnZeroPageCount={null}
                            activeLinkClassName="paging_num paging_current"
                            pageLinkClassName="paging_num"
                            breakLinkClassName="paging_num"
                            previousLinkClassName="paging_num"
                            nextLinkClassName="paging_num"
                            breakClassName="paging_break"
                        />
                    )}
                </Block50>
                <BottomBtns>
                    <BtnMd onClick={handleActive}>선택 보이기</BtnMd>
                    <BtnMd $bg="#6946B1" onClick={handleInactive}>
                        선택 숨기기
                    </BtnMd>
                    <BtnMd $bg="#F33535">선택 삭제</BtnMd>
                </BottomBtns>
            </SysContainer>
            {/* 리뷰 내용보기 모달 */}
            <Modal style={ReviewListtyle} isOpen={modalIsOpen} onRequestClose={closeModal}>
                <Mb20>
                    <Fz15Bold>{selectedItem?.reservation?.designer?.title}</Fz15Bold>
                </Mb20>
                <ItemInfoCol2 $mb="1.154rem">
                    <InfoLabel>작성일</InfoLabel>
                    <Gap10 />
                    <InfoValue>
                        <Moment format="YY.MM.DD">{selectedItem?.created}</Moment>
                    </InfoValue>
                </ItemInfoCol2>
                <ItemInfoCol2 $mb="1.154rem">
                    <InfoLabel>시술일</InfoLabel>
                    <Gap10 />
                    <InfoValue>
                        {selectedItem?.reservation?.date} {selectedItem?.reservation?.time}
                    </InfoValue>
                </ItemInfoCol2>
                <ItemInfoCol2 $mb="1.154rem">
                    <InfoLabel>시술 상품</InfoLabel>
                    <Gap10 />
                    <InfoValue>
                        {selectedItem?.reservation?.menus.map((menu, index) => {
                            return (
                                <>
                                    {`${index > 0 ? ' / ' : ''}`} {menu.name}
                                </>
                            );
                        })}
                    </InfoValue>
                    {/* <InfoValue>펌 / 매직스트레이트</InfoValue> */}
                </ItemInfoCol2>
                <ItemInfoCol2 $mb="2.3077rem">
                    <InfoLabel>평가</InfoLabel>
                    <Gap10 />
                    <InfoValue>{selectedItem?.rating}점</InfoValue>
                </ItemInfoCol2>
                {selectedItem?.images?.length > 0 ? (
                    <Mb30>
                        <Slider {...sliderSetting}>
                            {selectedItem?.images.map((img, index) => {
                                return (
                                    <SlideImg key={index}>
                                        <img
                                            src={`${config.API_SERVER_HOST}/files/review/${img.filename}`}
                                            alt={`리뷰 이미지${index + 1}`}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = require('@/assets/img/altimg.png');
                                            }}
                                        />
                                    </SlideImg>
                                );
                            })}
                        </Slider>
                    </Mb30>
                ) : null}
                <Paragraph>{selectedItem?.content}</Paragraph>
                <ModalAbsolBtn>
                    <BtnFullModal type="button" onClick={closeModal} active>
                        닫기
                    </BtnFullModal>
                </ModalAbsolBtn>
            </Modal>
            {/* //리뷰 내용보기 모달 */}
        </>
    );
}
const sliderSetting = {
    slidesToShow: 1.5,
    slidesToScroll: 1,
    initialSlide: 0.5,
};
const ReviewListtyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,.8)',
        zIndex: 20,
    },
    content: {
        overflowY: 'auto',
        overflowX: 'hidden',
        top: '50%',
        bottom: 'unset',
        left: '50%',
        width: '100%',
        maxWidth: 430,
        height: 'auto',
        transform: 'translate(-50%,-50%)',
        transition: 'left 1s',
        padding: '3.077rem 1.538rem 8.46rem',
        border: 0,
        zIndex: 20,
    },
};
export default ReviewList;
