import { Link } from 'react-router-dom';
import { FlxColumn, Snb, NavFlxbtFull } from '../css/admin/style';
import logoWh from '@/assets/img/logo_wh.svg';
import arrowRight from '@/assets/img/arrow_right_thin_green.svg';
import { useSelector } from 'react-redux';
import { selectUser } from '@redux/slice/authSlice';
import { menus } from '@/routes';

export default function SideMenu() {
  const user = useSelector(selectUser);
  return (
    <Snb>
      <Link to="/">
          <img src={logoWh} alt="머리할래" />
      </Link>
      <FlxColumn as="ul">
        {
          menus.map((menu, index) => {
            if (!menu.active || !menu.roles.includes(user?.role)) return null;

            return (
              <li key={index}>
                <NavFlxbtFull to={menu.path}>
                  <h5>{menu.name}</h5>
                  <img src={arrowRight} alt="이동" />
                </NavFlxbtFull>
              </li>
            )
          })
        }
      </FlxColumn>
    </Snb>
  );
}
