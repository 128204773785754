import { apiService } from "./api";

export const authApi = apiService.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: '/auth/authenticate',
        method: 'POST',
        body: credentials,
      })
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      })
    }),
    refreshToken: builder.mutation({
      query: () => ({
        url: '/auth/refresh-token',
        method: 'POST',
      })
    }),
    me: builder.query({
      query: () => ({
        url: '/auth/me',
        method: 'GET',
      })
    }),
    checkEmail: builder.query({
      query: email => ({
        url: `/auth/${email}/check`,
        method: 'GET',
      })
    }),
    checkPhone: builder.query({
      query: phone => ({
        url: `/auth/phone/${phone}/check`,
        method: 'GET',
      })
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useRefreshTokenMutation, useMeQuery, useCheckEmailQuery, useCheckPhoneQuery } = authApi;