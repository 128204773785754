import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setToken, setUser, setLogout, setLastActivity } from "../redux/slice/authSlice";

import config from "@config";

const baseQuery = fetchBaseQuery({
  baseUrl: config.API_SERVER_HOST,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error?.status === 401) {
    // send refresh token to get new access token
    const refreshResponse = await fetch(`${config.API_SERVER_HOST}/auth/refresh-token`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (refreshResponse.ok) {
      const { accessToken, user } = await refreshResponse.json();

      if(!['ADMIN'].includes(user.role)) {
        const logoutResponse = await fetch(`${config.API_SERVER_HOST}/auth/logout`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        });
        
        if(logoutResponse.ok){
          api.dispatch(setLogout());
          return;
        }
      }
      
      // store new access token
      api.dispatch(setToken(accessToken));
      // store new user info
      api.dispatch(setUser(user));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(setLogout());
    }
  } else if (result.error?.status === 498){
    api.dispatch(setLogout());  
  }

  api.dispatch(setLastActivity(Date.now()))

  return result;
}

export const apiService = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({}),
});