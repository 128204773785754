import React, { useState } from 'react';
import {
    TitleLine,
    SysContainer,
    Input,
    FieldGroup,
    InputItem,
    FormBorderb,
    BottomBtnsRight,
    BtnMd,
    BtnMdSubmit,
    Fz20Bold,
    EditorWrapper,
    UploadFile,
    ColRed,
    PreviewList,
    PhotoItem,
    GridImg,
    BtnDelete
} from '@/css/admin/style';
import icClear from '@/assets/img/ic_clear.svg';
import Chkbox from '@/components/Chkbox';
import FormCKEditor from '@/components/FormCKEditor';
import { useCreateArchiveMutation } from '@services/archive';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function ArchiveNewForm() {
  const [top, setTop] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedMainFile, setSelectedMainFile] = useState(null);

  const navigate = useNavigate();

  const [createArchive] = useCreateArchiveMutation();

  const renderMainPhoto = (source) => {
    return source && <PhotoItem>
        <GridImg src={URL.createObjectURL(source)} alt="" key={source.name} />
        <BtnDelete>
          <img src={icClear} alt="삭제" onClick={e=>handleMainImageDelete()}/>
        </BtnDelete>
      </PhotoItem>
  }

  console.log('selectedMainFile')

  const handleMainImageChange = (e) => {
    setSelectedMainFile(e.target.files[0]);
  }
  
  const handleMainImageDelete = () => {
    setSelectedMainFile(null);
  }

  const handleCreateArchive = async (e) => {
      e.preventDefault();
      if (!title) {
          toast.error('제목을 입력해주세요.');
          return;
      }
      if (!content) {
          toast.error('내용을 입력해주세요.');
          return;
      }
      try {
          confirmAlert({
              message: '등록하시겠습니까?',
              buttons: [
                  {
                      label: '확인',
                      onClick: async () => {
                          const formData = new FormData();
                          formData.append('title', title);
                          formData.append('body', content);
                          formData.append('file', selectedMainFile);
                          formData.append('top', top);

                          await createArchive(formData);
                          toast.success('등록되었습니다.');
                          navigate('/archives');
                      }
                  },
                  {
                      label: '취소',
                      onClick: () => {}
                  }
              ]
          });
      } catch (err) {
          toast.error('등록에 실패했습니다.');
      }
  }

  const handleList= () => {
      navigate('/archives');
  }

    return (
        <>
            <SysContainer>
              <form onSubmit={handleCreateArchive}>
                    <Fz20Bold>아카이브</Fz20Bold>
                    <TitleLine />
                    <FieldGroup>
                        <InputItem $wd="100%">
                            <span>공지</span>
                            <Chkbox checked={top} onChange={e=>setTop(!top)}/>
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>제목</span>
                            <Input type="text" defaultValue={title} placeholder={'제목을 입력해 주세요.'} onChange={e=>setTitle(e.target.value)}/>
                        </InputItem>
                        <InputItem $wd="100%">
                            <span>내용</span>
                            <EditorWrapper>
                              <FormCKEditor
                                 data={content}
                                 handleChange={setContent}
                              />
                            </EditorWrapper>
                        </InputItem>
                        <InputItem $wd="100%" $ai="flex-start">
                            <span>대표 이미지</span>
                            <div>
                                <UploadFile>
                                    <div style={{width:'100%'}}>{selectedMainFile?.name}</div>
                                    <input type="file" accept="image/png, image/gif, image/jpeg" onChange={handleMainImageChange}/>
                                </UploadFile>
                                <PreviewList>
                                  {renderMainPhoto(selectedMainFile)}
                                </PreviewList>
                                <ColRed>
                                    가로 720px, 세로 400px (최대 2000px 이하를 권장 드립니다.){' '}
                                </ColRed>
                            </div>
                        </InputItem>
                    </FieldGroup>
                    <FormBorderb />
                    <BottomBtnsRight>
                      <BtnMd $bg="#fff" $bc="#00BF9D" $col="#00BF9D" onClick={()=>handleList()}>
                            목록
                        </BtnMd>
                        <BtnMdSubmit>등록</BtnMdSubmit>
                    </BottomBtnsRight>
                </form>
            </SysContainer>
        </>
    );
}

export default ArchiveNewForm;
