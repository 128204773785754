import React, { useState } from 'react';
import Chkbox from '@/components/Chkbox';
import {
    Block50,
    SysContainer,
    Fz30Bold,
    InputShort,
    BtnSearch,
    BtnMd,
    ListTableWrapper,
    BtnTable,
    BottomBtns,
    Gap30,
    FlxMiddleMb30,
    TdSm,
    TdBtn,
} from '@/css/admin/style';
import icSearch from '@/assets/img/ic_search.svg';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useGetArchivesQuery, useDeleteArchiveMutation } from '@services/archive';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function Archives() {
    const searchInput = React.createRef();

    const [checkList, setCheckList] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);

    const navigate = useNavigate();

    const { data, isLoading, error, refetch } = useGetArchivesQuery(
        { page, limit: pageLimit, searchText },
        { refetchOnMountOrArgChange: true }
    );
    const [deleteArchive] = useDeleteArchiveMutation();

    console.log(data);

    const handleSearchClick = () => {
        const text = searchInput.current.value;
        setPage(1);
        setSearchText(text);
    };

    const handlePageClick = (e) => {
        const { selected } = e;
        setPage(selected + 1);
    };

    const handleNew = () => {
        navigate('/archives/new');
    };

    const handleDelete = () => {
        if (checkList.length === 0) {
            toast.error('삭제할 공지사항을 선택해주세요.');
            return;
        }

        confirmAlert({
            message: '선택한 공지사항을 삭제하시겠습니까?',
            buttons: [
                {
                    label: '삭제',
                    onClick: async () => {
                        try {
                            checkList.forEach(async (id) => {
                                await deleteArchive(id);
                            });
                            setCheckList([]);
                            refetch();
                        } catch (error) {
                            console.error(error);
                        }
                    },
                },
                {
                    label: '취소',
                    onClick: () => {},
                },
            ],
        });
    };

    const handleEdit = (id) => {
        navigate(`/archives/${id}/edit`);
    };

    return (
        <SysContainer>
            <Fz30Bold $mb="3.077rem">아카이브</Fz30Bold>
            <FlxMiddleMb30 style={{ justifyContent: 'right' }}>
                <InputShort placeholder="제목 / 내용을 입력해 주세요." ref={searchInput} onKeyUp={
                    (e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                          handleSearchClick();
                        }
                    }}/>
                <Gap30 />
                <BtnSearch onClick={handleSearchClick}>
                    <img src={icSearch} alt="" />
                    검색
                </BtnSearch>
            </FlxMiddleMb30>
            <Block50>
                <ListTableWrapper>
                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>체크</th>
                                <th>상태</th>
                                <th>제목</th>
                                <th>등록일</th>
                                <th>조회</th>
                                <th>편집</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.docs.length ? (
                                data.docs.map((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                {data.totalDocs - (page - 1) * pageLimit - index}
                                            </td>
                                            <TdSm style={{ width: '5.2rem' }}>
                                                <Chkbox
                                                    checked={checkList.includes(item.id)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setCheckList([...checkList, item.id]);
                                                        } else {
                                                            setCheckList(
                                                                checkList.filter(
                                                                    (id) => id !== item.id
                                                                )
                                                            );
                                                        }
                                                    }}
                                                    disabled={item.deleted}
                                                />
                                            </TdSm>
                                            <td>{item.top && '공지'}</td>
                                            <td className="title">{item.title}</td>
                                            <td>
                                                <Moment format="YYYY-MM-DD HH:mm:ss">
                                                    {item.createdAt}
                                                </Moment>
                                            </td>
                                            <TdSm>{item.view_count}</TdSm>
                                            <TdBtn>
                                                <BtnTable onClick={() => handleEdit(item.id)}>
                                                    수정
                                                </BtnTable>
                                            </TdBtn>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="7">
                                        {isLoading ? (
                                            <ClipLoader
                                                color={'#00bf9d'}
                                                size={35}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        ) : (
                                            '데이터가 없습니다.'
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </ListTableWrapper>
                {!isLoading && (
                    <ReactPaginate
                        className="paging"
                        breakLabel="..."
                        nextLabel={<span className="paging_end pl30"></span>}
                        onPageChange={handlePageClick}
                        forcePage={page - 1}
                        pageRangeDisplayed={3}
                        pageCount={data?.totalPages}
                        previousLabel={<span className="paging_first pl30"></span>}
                        renderOnZeroPageCount={null}
                        activeLinkClassName="paging_num paging_current"
                        pageLinkClassName="paging_num"
                        breakLinkClassName="paging_num"
                        previousLinkClassName="paging_num"
                        nextLinkClassName="paging_num"
                        breakClassName="paging_break"
                    />
                )}
            </Block50>
            <BottomBtns>
                <BtnMd onClick={handleNew}>신규 등록</BtnMd>
                <BtnMd $bg="#F33535" onClick={handleDelete}>
                    선택 삭제
                </BtnMd>
            </BottomBtns>
        </SysContainer>
    );
}

export default Archives;
