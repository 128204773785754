import React, { useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '@/css/datepicker_custom.css';
import Chkbox from '@/components/Chkbox';
import { Link } from 'react-router-dom';
import {
    Block50,
    SysContainer,
    Fz15,
    InputShort,
    BtnSearch,
    BtnMd,
    Nowrap,
    BannerItem,
    ColRed,
    BottomBtns,
    FlxMiddleMb20,
    Gap30,
    ListInputs,
    FlxbtFullMb15,
    ColAccent,
    ChkAndTxt,
    Gap10,
    Gap20,
    TdBtn,
    BtnTable,
    FlxMiddle,
    FlxMiddleMb10,
    BannerImgWrapper,
    ListTableWrapper,
    SchDatePickWrapper,
} from '@/css/admin/style';
import icSearch from '@/assets/img/ic_search.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAdvertisementsQuery } from '@services/advertisement';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import config from '@config';
import { compareAsc, format as dateFormat } from 'date-fns';
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko);

function AdvertisementList() {
    const searchInput = React.createRef();

    const [searchText, setSearchText] = useState(null);
    const [searchStatus, setSearchStatus] = useState('');
    const [page, setPage] = useState(1);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [pageLimit, setPageLimit] = useState(10);

    const { type } = useParams();

    const { data, isLoading, error, refetch } = useGetAdvertisementsQuery(
        {
            type,
            page,
            limit: pageLimit,
            searchText,
            from_date: startDate ? dateFormat(startDate, 'yyyy-MM-dd') : '',
            to_date: endDate ? dateFormat(endDate, 'yyyy-MM-dd') : '',
            searchStatus,
        },
        { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
    );

    const navigate = useNavigate();

    const handleNew = () => {
        navigate(`/advertisements/${type}/new`);
    };

    const handleSearchStatus = (status) => {
        setPage(1);
        setSearchStatus(status);
    };

    const handleSearchClick = () => {
        const text = searchInput.current.value;
        setSearchText(text);
        refetch();
    };

    const handlePageClick = (e) => {
        const { selected } = e;
        setPage(selected + 1);
    };

    return (
        <SysContainer>
            <FlxMiddleMb20>
                <Fz15>상태</Fz15>
                <Gap30 />
                <ChkAndTxt>
                    <Chkbox
                        checked={searchStatus === ''}
                        onChange={() => {
                            handleSearchStatus('');
                        }}
                    />
                    전체
                </ChkAndTxt>
                <ChkAndTxt>
                    <Chkbox
                        checked={searchStatus === 'prepare'}
                        onChange={() => {
                            handleSearchStatus('prepare');
                        }}
                    />
                    준비중
                </ChkAndTxt>
                <ChkAndTxt>
                    <Chkbox
                        checked={searchStatus === 'ing'}
                        onChange={() => {
                            handleSearchStatus('ing');
                        }}
                    />
                    진행중
                </ChkAndTxt>
                <ChkAndTxt $mr="0">
                    <Chkbox
                        checked={searchStatus === 'end'}
                        onChange={() => {
                            handleSearchStatus('end');
                        }}
                    />
                    종료
                </ChkAndTxt>
            </FlxMiddleMb20>
            <ListInputs>
                <Fz15>기간</Fz15>
                <SchDatePickWrapper>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        locale="ko"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="시작일"
                    />
                </SchDatePickWrapper>
                <Gap10 />~<Gap10 />
                <SchDatePickWrapper>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        locale="ko"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="종료일"
                    />
                </SchDatePickWrapper>
                <Gap30 />
                <Fz15>검색어</Fz15>
                <InputShort placeholder={
                    type === 'shop' ? '매장명' : '디자이너명'
                } ref={searchInput} onKeyUp={
                    (e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                          handleSearchClick();
                        }
                    }}/>
                <BtnSearch onClick={handleSearchClick}>
                    <img src={icSearch} alt="" />
                    검색
                </BtnSearch>
            </ListInputs>
            <Block50 style={{ minWidth: '62.5rem' }}>
                <ListTableWrapper>
                    {type === 'shop' ? (<table>
                        <colgroup>
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="30%" />
                            <col width="10%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th rowSpan={2}>No.</th>
                                <th rowSpan={2}>상태</th>
                                <th colSpan={2}>대리점</th>
                                <th colSpan={2}>매장</th>
                                <th rowSpan={2}>게시기간</th>
                                <th rowSpan={2}>편집</th>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <th>연락처</th>
                                <th>이름</th>
                                <th>연락처</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.docs.length > 0 ? (
                                data.docs.map((item, index) => {
                                    return (
                                        <tr key={item._id}>
                                            <td>
                                                {data.totalDocs - (page - 1) * pageLimit - index}
                                            </td>
                                            <td>
                                                {!item.actived ? (
                                                        <ColRed as="strong">비활성</ColRed>
                                                ) : (
                                                    <>
                                                        {compareAsc(
                                                            new Date(),
                                                            new Date(item.to_date)
                                                        ) === 1 ? (
                                                            <ColAccent as="strong">종료</ColAccent>
                                                        ) : null}
                                                        {compareAsc(
                                                            new Date(),
                                                            new Date(item.from_date)
                                                        ) === 1 &&
                                                        compareAsc(new Date(), new Date(item.to_date)) ===
                                                            -1 ? (
                                                            <ColAccent as="strong">진행중</ColAccent>
                                                        ) : null}
                                                        {compareAsc(
                                                            new Date(),
                                                            new Date(item.from_date)
                                                        ) === -1 ? (
                                                            <ColAccent as="strong">준비중</ColAccent>
                                                        ) : null}
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {item.shop?.manager?.name || ''} 
                                            </td>
                                            <td>
                                                {item.shop?.manager?.phone || ''} 
                                            </td>
                                            <td>
                                                {item.shop?.name || ''} 
                                            </td>
                                            <td>
                                                {item.shop?.tel || ''} 
                                            </td>
                                            <td>
                                                <Moment format="YYYY.MM.DD HH:mm:ss">
                                                    {item.from_date}
                                                </Moment>{' '}
                                                ~{' '}
                                                <Moment format="YYYY.MM.DD HH:mm:ss">
                                                    {item.to_date}
                                                </Moment>{' '}
                                            </td>
                                            <td>
                                                <TdBtn>
                                                    <Link to={`/advertisements/${type}/${item._id}/edit`}>
                                                        <BtnTable>편집</BtnTable>
                                                    </Link>
                                                </TdBtn>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="8">
                                        {isLoading ? (
                                            <ClipLoader
                                                color={'#00bf9d'}
                                                size={35}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        ) : (
                                            '데이터가 없습니다.'
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>):(<table>
                        <colgroup>
                            <col width="5%" />
                            <col width="5%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="25%" />
                            <col width="5%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th rowSpan={2}>No.</th>
                                <th rowSpan={2}>상태</th>
                                <th colSpan={2}>대리점</th>
                                <th colSpan={2}>딜러</th>
                                <th colSpan={2}>디자이너</th>
                                <th rowSpan={2}>게시기간</th>
                                <th rowSpan={2}>편집</th>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <th>연락처</th>
                                <th>이름</th>
                                <th>연락처</th>
                                <th>타이틀</th>
                                <th>연락처</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.docs.length > 0 ? (
                                data.docs.map((item, index) => {
                                    return (
                                        <tr key={item._id}>
                                            <td>
                                                {data.totalDocs - (page - 1) * pageLimit - index}
                                            </td>
                                            <td>
                                                {!item.actived ? (
                                                        <ColRed as="strong">비활성</ColRed>
                                                ) : (
                                                    <>
                                                        {compareAsc(
                                                            new Date(),
                                                            new Date(item.to_date)
                                                        ) === 1 ? (
                                                            <ColAccent as="strong">종료</ColAccent>
                                                        ) : null}
                                                        {compareAsc(
                                                            new Date(),
                                                            new Date(item.from_date)
                                                        ) === 1 &&
                                                        compareAsc(new Date(), new Date(item.to_date)) ===
                                                            -1 ? (
                                                            <ColAccent as="strong">진행중</ColAccent>
                                                        ) : null}
                                                        {compareAsc(
                                                            new Date(),
                                                            new Date(item.from_date)
                                                        ) === -1 ? (
                                                            <ColAccent as="strong">준비중</ColAccent>
                                                        ) : null}
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {item.designer?.manager?.name || ''} 
                                            </td>
                                            <td>
                                                {item.designer?.manager?.phone || ''} 
                                            </td>
                                            <td>
                                                {item.designer?.dealer?.name || ''} 
                                            </td>
                                            <td>
                                                {item.designer?.dealer?.phone || ''} 
                                            </td>
                                            <td>
                                                {item.designer?.title || ''} 
                                            </td>
                                            <td>
                                                {item.designer?.user?.phone || ''} 
                                            </td>
                                            <td>
                                                <Moment format="YYYY.MM.DD HH:mm:ss">
                                                    {item.from_date}
                                                </Moment>{' '}
                                                ~{' '}
                                                <Moment format="YYYY.MM.DD HH:mm:ss">
                                                    {item.to_date}
                                                </Moment>{' '}
                                            </td>
                                            <td>
                                                <TdBtn>
                                                    <Link to={`/advertisements/${type}/${item._id}/edit`}>
                                                        <BtnTable>편집</BtnTable>
                                                    </Link>
                                                </TdBtn>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="9">
                                        {isLoading ? (
                                            <ClipLoader
                                                color={'#00bf9d'}
                                                size={35}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        ) : (
                                            '데이터가 없습니다.'
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>)}
                </ListTableWrapper>
                <ReactPaginate
                    className="paging"
                    breakLabel="..."
                    nextLabel={<span className="paging_end pl30"></span>}
                    onPageChange={handlePageClick}
                    forcePage={page - 1}
                    pageRangeDisplayed={3}
                    pageCount={data?.totalPages}
                    previousLabel={<span className="paging_first pl30"></span>}
                    renderOnZeroPageCount={null}
                    activeLinkClassName="paging_num paging_current"
                    pageLinkClassName="paging_num"
                    breakLinkClassName="paging_num"
                    previousLinkClassName="paging_num"
                    nextLinkClassName="paging_num"
                    breakClassName="paging_break"
                />
            </Block50>
            <BottomBtns>
                <BtnMd onClick={handleNew}>신규 등록</BtnMd>
                {/* <BtnMd $bg="#F33535">선택 삭제</BtnMd> */}
            </BottomBtns>
        </SysContainer>
    );
}

export default AdvertisementList;
