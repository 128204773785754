import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '@ckeditor/ckeditor5-build-classic';
import { useUploadEditorImageMutation } from '@services/board';
import config from '@config';
export default function FormCKEditor({ data, handleChange, setImage }) {
  const [uploadEditorImage] = useUploadEditorImageMutation();

  const contentChangeHandler = editor => {
    handleChange(editor.getData());
  }

  const customUploadAdapter = (loader) => { // (2)
    return {
      upload(){
        return new Promise ((resolve, reject) => {
          const data = new FormData();
          loader.file.then( async  (file) => {
            data.append("name", file.name);
            data.append("file", file);

            const res = await uploadEditorImage(data);

            resolve({
              default: `${config.API_SERVER_HOST}/files/editor/${res.data.filename}`
            });
          })
        })
      }
    }
  }

  function uploadPlugin (editor){
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    }
  }

	return (
    <CKEditor
      language="ko"
      editor={Editor}
      config={{
        extraPlugins: [uploadPlugin],
      }}
      data={data}
      onChange={(event, editor)=>contentChangeHandler(editor)}
      onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }/>
	);
}
