import { apiService } from "./api";

export const reviewApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getReviews: builder.query({
      query: ({ page, limit, searchText, searchStatus }) => ({
        url: `/reviews?page=${page}&text=${searchText || ''}&status=${searchStatus || ''}&limit=${limit}`,
        method: 'GET',
      })
    }),
    getReview: builder.query({
      query: id => ({
        url: `/reviews/${id}`,
        method: 'GET',
      })
    }),
    updateReview: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/reviews/${id}`,
        method: 'PUT',
        body: patch,
      })
    }),
    //active
    updateReviewActive: builder.mutation({
      query: (id) => ({
        url: `/reviews/${id}/active`,
        method: 'PUT'
      })
    }),
    //inactive
    updateReviewInactive: builder.mutation({
      query: (id) => ({
        url: `/reviews/${id}/inactive`,
        method: 'PUT'
      })
    }),
    deleteReview: builder.mutation({
      query: id => ({
        url: `/reviews/${id}`,
        method: 'DELETE',
      })
    }),
  }),
});

export const { useGetReviewsQuery, useGetReviewQuery, useUpdateReviewMutation, useDeleteReviewMutation, useUpdateReviewActiveMutation, useUpdateReviewInactiveMutation } = reviewApi;