import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import DaumPostcodeEmbed from 'react-daum-postcode';
import RadioBox from '@/components/RadioBox';
import icClear from '@/assets/img/ic_clear.svg';
import icSearch from '@/assets/img/ic_search_gray.svg';
import {
    TitleLine,
    SysContainer,
    Input,
    FieldGroup,
    InputItem,
    FormBorderb,
    BottomBtnsRight,
    BtnSm,
    BtnMdSubmit,
    Select,
    UploadFile,
    SearchWrapper,
    Fz20Bold,
    Textbox,
    Mb15,
    FlxbtFull,
    PreviewList,
    PhotoItem,
    GridImg,
    BtnDelete,
    ManagerItem,
    BtnFullModal,
    BtnModalClose,
    ModalAbsolBtn,
    DaumWrapper,
} from '@/css/admin/style';
import { FaSearch } from 'react-icons/fa';
import { useCreateShopMutation } from '@services/shop';
import { useGetManagersQuery } from '@services/manager';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '@redux/slice/authSlice';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { timeTable, dayList } from '@data';
import useDynamicRefs from '@/hooks/useDynamicRefs'

function ShopNewForm() {
    const managerSearchInputRef = React.createRef();
    const [getRef, setRef] = useDynamicRefs();

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isPostSearchShow, setIsPostSearchShow] = useState(false);
    const [selectedManager, setSelectedManager] = useState({
        id: '',
        name: '',
        phone: '',
    });
    const [modalIsOpen, setIsOpen] = useState(false);
    const [managerSearchText, setManagerSearchText] = useState('');
    const [shopInfo, setShopInfo] = useState({
        name: '',
        tel: '',
        address: '',
        address_detail: '',
        homepage: '',
        introduce: '',
        reservation_notice: '',
        note: '',
        services: '',
        email: '',
        // holiday: 'open',
        // holiday_from_time: '20',
        // holiday_to_time: '40',
        monday: 'open',
        monday_from_time: '20',
        monday_to_time: '40',
        tuesday: 'open',
        tuesday_from_time: '20',
        tuesday_to_time: '40',
        wednesday: 'open',
        wednesday_from_time: '20',
        wednesday_to_time: '40',
        thursday: 'open',
        thursday_from_time: '20',
        thursday_to_time: '40',
        friday: 'open',
        friday_from_time: '20',
        friday_to_time: '40',
        saturday: 'open',
        saturday_from_time: '20',
        saturday_to_time: '40',
        sunday: 'open',
        sunday_from_time: '20',
        sunday_to_time: '40',
        naverblog: '',
        instagram: '',
        youtube: '',
        facebook: '',
        kakaochannel: '',
        daumcafe: '',
        manager_id: '',
        manager_name: '',
        naver_id: '',
        naver_key: '',
        approved: false,
        manager_id: '',
        manager_name: '',
        manager_phone: '',
    });
    const navigate = useNavigate();
    const user = useSelector(selectUser);

    const {
        data: managers,
        isLoading,
        isFetching,
        isSuccess,
        refetch,
    } = useGetManagersQuery(
        {
            page: 1,
            limit: 100,
            searchStatus: 'active',
            searchText: managerSearchText,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const handleManagerSearch = (e) => {
        e.preventDefault();
        if (managerSearchInputRef.current.value === '') {
            managerSearchInputRef.current.focus();
            toast.error('검색어를 입력해주세요.');
            return;
        }
        setManagerSearchText(managerSearchInputRef.current.value);
    };

    const [createShop, { loading, error }] = useCreateShopMutation();

    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (loading) {
            return;
        }

        if (shopInfo.name === '') {
            toast.error('상호명을 입력해주세요.');
            return;
        }

        if (shopInfo.address === '') {
            toast.error('주소를 입력해주세요.');
            return;
        }

        let dayOffErrorMsg = '';

        let opening_hour = [];

        for (let i = 0; i < dayList.length; i++) {
            if (shopInfo[dayList[i].day] === 'open') {
                if (
                    shopInfo[`${dayList[i].day}_from_time`] === '' ||
                    shopInfo[`${dayList[i].day}_to_time`] === ''
                ) {
                    dayOffErrorMsg += `${dayList[i].label}의 운영시간을 입력해 주세요.\n`;
                    scrollToRef(getRef(`${dayList[i].day}_section`));
                    break;
                } else if (
                    shopInfo[`${dayList[i].day}_from_time`] >= shopInfo[`${dayList[i].day}_to_time`]
                ) {
                    dayOffErrorMsg += `${dayList[i].label}의 운영시작시간이 종료시간보다 늦을 수 없습니다.\n`;
                    scrollToRef(getRef(`${dayList[i].day}_section`));
                    break;
                }
                opening_hour.push({
                    day: dayList[i].day,
                    type: 'open',
                    from_time: shopInfo[`${dayList[i].day}_from_time`],
                    to_time: shopInfo[`${dayList[i].day}_to_time`],
                });
            } else {
                opening_hour.push({
                    day: dayList[i].day,
                    type: 'dayoff',
                    from_time: '',
                    to_time: '',
                });
            }
        }

        // if (dayOffErrorMsg != '') {
        //     toast.error(dayOffErrorMsg);
        //     return;
        // }

        confirmAlert({
            message: '매장을 추가하시겠습니까?',
            buttons: [
                {
                    label: '예',
                    onClick: () => {
                        try {
                            const formData = new FormData();
                            formData.append('name', shopInfo.name);
                            formData.append('tel', shopInfo.tel);
                            formData.append('address', shopInfo.address);
                            formData.append('address_detail', shopInfo.address_detail);
                            formData.append('homepage', shopInfo.homepage);
                            formData.append('introduce', shopInfo.introduce);
                            formData.append('reservation_notice', shopInfo.reservation_notice);
                            formData.append('note', shopInfo.note);
                            formData.append('services', shopInfo.services);
                            formData.append('email', shopInfo.email);
                            opening_hour.forEach((obj, index) => {
                                Object.keys(obj).forEach((key) => {
                                    if (key === 'from_time' || key === 'to_time') {
                                        if (obj['type'] === 'open') {
                                            obj[key] = timeTable[obj[key]];
                                        }
                                    }
                                    formData.append(`opening_hour[${index}][${key}]`, obj[key]);
                                });
                            });
                            formData.append('naverblog', shopInfo.naverblog);
                            formData.append('instagram', shopInfo.instagram);
                            formData.append('youtube', shopInfo.youtube);
                            formData.append('facebook', shopInfo.facebook);
                            formData.append('kakaochannel', shopInfo.kakaochannel);
                            formData.append('daumcafe', shopInfo.daumcafe);
                            formData.append('manager_id', shopInfo.manager_id);
                            formData.append('naver_id', shopInfo.naver_id);
                            formData.append('naver_key', shopInfo.naver_key);
                            formData.append('approved', shopInfo.approved);
                            selectedFiles.forEach((file) => {
                                formData.append('files', file);
                            });

                            if (selectedFile) {
                                formData.append('main_file', selectedFile);
                            }

                            createShop(formData);

                            toast.success('매장이 추가되었습니다.');
                            navigate('/shops?page=1', { replace: true });
                        } catch (error) {
                            toast.error(error.message);
                        }
                    },
                },
                {
                    label: '아니요',
                    onClick: () => {},
                },
            ],
        });
    };

    const handleMainImageChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    //ignore duplicate files
    const handleSubImageChange = (e) => {
        const files = e.target.files;
        const filesArray = Array.from(files);
        const filteredFiles = filesArray.filter((file) => {
            return selectedFiles.every((item) => item.name !== file.name);
        });
        setSelectedFiles((prevFiles) => prevFiles.concat(filteredFiles));
    };

    const renderMainPhoto = (source) => {
        return (
            selectedFile && (
                <PhotoItem>
                    <GridImg src={URL.createObjectURL(source)} alt="" key={source.name} />
                    <BtnDelete>
                        <img src={icClear} alt="삭제" onClick={(e) => handleMainImageDelete()} />
                    </BtnDelete>
                </PhotoItem>
            )
        );
    };

    const renderSubPhotos = (source) => {
        return Array.from(source).map((file, index) => {
            return (
                <PhotoItem>
                    <GridImg src={URL.createObjectURL(file)} alt="" key={file.name} />
                    <BtnDelete>
                        <img src={icClear} alt="삭제" onClick={(e) => handleSubImageDelete(file)} />
                    </BtnDelete>
                </PhotoItem>
            );
        });
    };

    //delete file from selectedFiles state
    const handleMainImageDelete = () => {
        setSelectedFile(null);
    };

    const handleSubImageDelete = (targetFile) => {
        const filteredFiles = selectedFiles.filter((file) => {
            return file.name !== targetFile.name;
        });
        setSelectedFiles(filteredFiles);
    };

    const handleModalOpen = () => {
        setIsOpen(true);
    };

    const handleModalClose = () => {
        setIsOpen(false);
        setManagerSearchText('');
        setSelectedManager({
            id: '',
            name: '',
            phone: '',
        });
    };

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setShopInfo({
            ...shopInfo,
            address: data.address,
            address_detail: extraAddress,
        });

        setIsPostSearchShow(!isPostSearchShow);
    };

    const handlePostSearch = () => {
        setIsPostSearchShow(!isPostSearchShow);
    };

    return (
        <>
            <SysContainer>
                {/* <FlxMiddleMb60> */}
                {/* <Fz15> */}

                {/* </Fz15> */}
                {/* <TxtDivider2 $mg="0 2.3077rem" /> */}
                {/* <Fz15>매니저 연락처 : -</Fz15> */}
                {/* </FlxMiddleMb60> */}
                <form onSubmit={handleSubmit}>
                    <FlxbtFull>
                        <Fz20Bold>매장 정보</Fz20Bold>
                        {/* <Fz15 color="#757575">
                          네이버 예약 파트너 센터 관련 문의는 1644-5690 / 네이버 N-pay 관련 문의는
                          1588-3819로 문의해 주시기 바랍니다.
                      </Fz15> */}
                    </FlxbtFull>
                    <TitleLine />
                    <FieldGroup>
                        {user.role === 'ADMIN' && (
                            <>
                                <InputItem>
                                    <span>대리점</span>
                                    <div style={managerInputWrapStyle}>
                                        <Input
                                            type="text"
                                            disabled
                                            value={shopInfo.manager_name}
                                            style={{ width: '100%' }}
                                        />
                                        {shopInfo.manager_name && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setShopInfo({
                                                        ...shopInfo,
                                                        manager_id: '',
                                                        manager_name: '',
                                                        manager_phone: '',
                                                    });
                                                }}
                                                style={{ position: 'absolute', right: 5 }}
                                            >
                                                <img src={icClear} alt="삭제" />
                                            </button>
                                        )}
                                    </div>
                                    {/* <Input type="text" disabled value={selectedManager.name}/> */}
                                    <BtnSm $bg="#00bf9d" $col="#fff" onClick={handleModalOpen}>
                                        대리점 검색
                                    </BtnSm>
                                </InputItem>
                                <InputItem>
                                    <span>상태</span>
                                    <Select
                                        onChange={(e) =>
                                            setShopInfo({
                                                ...shopInfo,
                                                approved: e.target.value,
                                            })
                                        }
                                        defaultValue={shopInfo.approved}
                                    >
                                        <option value={false}>대기</option>
                                        <option value={true}>운영</option>
                                    </Select>
                                </InputItem>
                            </>
                        )}
                        {/* <InputItem $wd="100%">
                          <span>예약 시간 단위 설정</span>
                          <ChkAndTxt htmlFor="">
                              <RadioBox />
                              30분
                          </ChkAndTxt>
                          <ChkAndTxt htmlFor="">
                              <RadioBox />
                              1시간
                          </ChkAndTxt>
                      </InputItem>
                      <InputItem>
                          <span>당일 예약 설정</span>
                          <ChkAndTxt htmlFor="">
                              <RadioBox />
                              당일 가능
                          </ChkAndTxt>
                          <ChkAndTxt htmlFor="" $mr="0">
                              <RadioBox />
                              당일 불가능
                          </ChkAndTxt>
                      </InputItem>
                      <InputItem>
                          <span>네이버 당일 예약 이용시간</span>
                          <div>
                              <Select $mb="0.77rem">
                                  <option>1시간 전</option>
                              </Select>
                              ~ 까지 예약 접수를 받습니다.
                          </div>
                      </InputItem> */}
                        {/* </FieldGroup>
              </form> */}
                        {/* <Fz20Bold>매장 기본 정보</Fz20Bold>
              <TitleLine /> */}

                        {/* <FieldGroup> */}
                        <InputItem $wd="100%">
                            <span>상호</span>
                            <Input
                                type="text"
                                value={shopInfo.name}
                                placeholder="매장 상호명을 입력해 주세요."
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        name: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                        <InputItem>
                            <span>기본주소</span>
                            <Input
                                type="text"
                                value={shopInfo.address}
                                placeholder="매장 기본주소를 주소검색을 통해 입력해 주세요."
                            />
                            <BtnSm $bg="#00bf9d" $col="#fff" onClick={handlePostSearch}>
                                주소검색
                            </BtnSm>
                            {isPostSearchShow && (
                                <DaumWrapper>
                                    <DaumPostcodeEmbed onComplete={handleComplete} />
                                </DaumWrapper>
                            )}
                        </InputItem>
                        <InputItem>
                            <span>상세주소</span>
                            <Input
                                type="text"
                                placeholder="매장 상세주소를 입력해 주세요."
                                value={shopInfo.address_detail}
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        address_detail: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                        {/* <InputItem>
                          <span>사업자 상호</span>
                          <Input type="text" placeholder="" />
                      </InputItem>
                      <InputItem>
                          <span>사업자 등록번호</span>
                          <Input type="text" placeholder="" />
                      </InputItem> */}
                        <InputItem>
                            <span>대표 전화</span>
                            <Input
                                type="text"
                                placeholder="매장 대표전화를 입력해 주세요."
                                value={shopInfo.tel}
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        tel: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                        <InputItem>
                            <span>대표 이메일</span>
                            <Input
                                type="email"
                                placeholder="매장 대표 이메일주소를 입력해 주세요."
                                value={shopInfo.email}
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        email: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                        <InputItem $wd="100%" $ai="flex-start">
                            <span>운영시간</span>
                            <div style={{ width: 'calc(100% - 12rem)' }}>
                                <ul>
                                    {dayList.map((item, index) => (
                                        <Mb15
                                            as="li"
                                            ref={setRef(`${item.day}_section`)}
                                            key={index}
                                        >
                                            {item.label}&nbsp;&nbsp;
                                            <Select
                                                ref={setRef(item.day)}
                                                onChange={(e) => {
                                                    setShopInfo({
                                                        ...shopInfo,
                                                        [item.day]: e.target.value,
                                                        [`${item.day}_from_time`]: '',
                                                        [`${item.day}_to_time`]: '',
                                                    });
                                                }}
                                                style={{ width: 'auto', minWidth: '10rem' }}
                                            >
                                                <option value="open">시간입력</option>
                                                <option value="dayoff">휴무</option>
                                            </Select>
                                            {shopInfo[item.day] == 'open' && (
                                                <>
                                                    <Select
                                                        ref={setRef(`${item.day}_from_time`)}
                                                        onChange={(e) =>
                                                            setShopInfo({
                                                                ...shopInfo,
                                                                [`${item.day}_from_time`]:
                                                                    e.target.value,
                                                            })
                                                        }
                                                        style={{
                                                            width: 'auto',
                                                            minWidth: '10rem',
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <option value="">시작</option>
                                                        {timeTable.map((time, index) => {
                                                            return (
                                                                <option
                                                                    value={index}
                                                                    selected={
                                                                        shopInfo[
                                                                            `${item.day}_from_time`
                                                                        ] == index
                                                                    }
                                                                >
                                                                    {time}
                                                                </option>
                                                            );
                                                        })}
                                                    </Select>
                                                    &nbsp;~&nbsp;
                                                    <Select
                                                        ref={setRef(`${item.day}_to_time`)}
                                                        onChange={(e) =>
                                                            setShopInfo({
                                                                ...shopInfo,
                                                                [`${item.day}_to_time`]:
                                                                    e.target.value,
                                                            })
                                                        }
                                                        style={{ width: 'auto', minWidth: '10rem' }}
                                                    >
                                                        <option value="">종료</option>
                                                        {timeTable.map((time, index) => {
                                                            return (
                                                                <option
                                                                    value={index}
                                                                    selected={
                                                                        shopInfo[
                                                                            `${item.day}_to_time`
                                                                        ] == index
                                                                    }
                                                                >
                                                                    {time}
                                                                </option>
                                                            );
                                                        })}
                                                    </Select>
                                                </>
                                            )}
                                        </Mb15>
                                    ))}
                                </ul>
                            </div>
                        </InputItem>
                        <InputItem $wd="100%" $ai="flex-start">
                            <span>대표 사진 업로드</span>
                            <div style={{ width: 'calc(100% - 12rem)' }}>
                                <UploadFile>
                                    <div>
                                        {selectedFile ? (
                                            <> {selectedFile.name} </>
                                        ) : (
                                            <>파일을 선택해 주세요</>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        onChange={handleMainImageChange}
                                    />
                                </UploadFile>
                                <PreviewList>{renderMainPhoto(selectedFile)}</PreviewList>
                            </div>
                        </InputItem>
                        <InputItem $wd="100%" $ai="flex-start">
                            <span>매장 사진 업로드</span>
                            <div style={{ width: 'calc(100% - 12rem)' }}>
                                <UploadFile>
                                    <div>
                                        {selectedFiles.length > 0 ? (
                                            <>
                                                {selectedFiles
                                                    .slice(selectedFiles.length - 2)
                                                    .map((file, index) => (
                                                        <React.Fragment key={file.name}>
                                                            {`${index > 0 ? ', ' : ''}${file.name}`}
                                                        </React.Fragment>
                                                    ))}
                                                {selectedFiles.length - 2 > 0 ? (
                                                    <React.Fragment>
                                                        {` 외 ${selectedFiles.length - 2}개의 파일`}
                                                    </React.Fragment>
                                                ) : null}
                                            </>
                                        ) : (
                                            <>파일을 선택해 주세요</>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        onChange={handleSubImageChange}
                                        multiple
                                    />
                                </UploadFile>
                                <PreviewList>{renderSubPhotos(selectedFiles)}</PreviewList>
                            </div>
                        </InputItem>
                        <InputItem $wd="100%" $ai="flex-start">
                            <span>매장 소개글</span>
                            <Textbox
                                rows="8"
                                placeholder="매장 소개글을 입력해 주세요"
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        introduce: e.target.value,
                                    })
                                }
                            >
                                {shopInfo.introduce}
                            </Textbox>
                        </InputItem>
                        <InputItem $wd="100%" $ai="flex-start">
                            <span>
                                예약시 주의 사항
                                <br />
                                공통 메시지
                            </span>
                            <Textbox
                                rows="8"
                                placeholder="공통 메세지를 등록해 주세요"
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        reservation_notice: e.target.value,
                                    })
                                }
                            >
                                {shopInfo.reservation_notice}
                            </Textbox>
                        </InputItem>
                        <InputItem $wd="100%" $ai="flex-start">
                            <span>매장 편의 사항 소개</span>
                            <Textbox
                                rows="8"
                                placeholder="편의 사항 소개글을 등록해 주세요"
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        services: e.target.value,
                                    })
                                }
                            >
                                {shopInfo.services}
                            </Textbox>
                        </InputItem>
                        <InputItem>
                            <span>네이버블로그</span>
                            <Input
                                type="text"
                                placeholder="매장 네이버블로그 계정이 있다면 주소입력"
                                defaultValue={shopInfo.naverblog}
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        naverblog: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                        <InputItem>
                            <span>인스타그램</span>
                            <Input
                                type="text"
                                placeholder="매장 인스타그램 계정이 있다면 주소입력"
                                defaultValue={shopInfo.instagram}
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        instagram: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                        <InputItem>
                            <span>유튜브</span>
                            <Input
                                type="text"
                                placeholder="매장 유튜브 계정이 있다면 주소입력"
                                defaultValue={shopInfo.youtube}
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        youtube: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                        <InputItem>
                            <span>페이스북</span>
                            <Input
                                type="text"
                                placeholder="매장 페이스북 계정이 있다면 주소입력"
                                defaultValue={shopInfo.facebook}
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        facebook: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                        <InputItem>
                            <span>카카오채널</span>
                            <Input
                                type="text"
                                placeholder="매장 카카오채널이 있다면 주소입력"
                                defaultValue={shopInfo.kakaochannel}
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        kakaochannel: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                        <InputItem>
                            <span>다음카페</span>
                            <Input
                                type="text"
                                placeholder="매장 다음카페가 있다면 주소 입력"
                                defaultValue={shopInfo.daumcafe}
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        daumcafe: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                    </FieldGroup>
                    <Fz20Bold>네이버 예약 연동정보</Fz20Bold>
                    <TitleLine />
                    <FieldGroup>
                        <InputItem>
                            <span>네이버 예약 생성 ID</span>
                            <Input
                                type="text"
                                defaultValue={shopInfo.naver_id}
                                placeholder="네이버 예약 생성 ID를 입력해 주세요."
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        naver_id: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                        <InputItem>
                            <span>네이버 예약 KEY</span>
                            <Input
                                type="text"
                                defaultValue={shopInfo.naver_key}
                                placeholder="네이버 예약 KEY를 입력해 주세요."
                                onChange={(e) =>
                                    setShopInfo({
                                        ...shopInfo,
                                        naver_key: e.target.value,
                                    })
                                }
                            />
                        </InputItem>
                    </FieldGroup>
                    <FormBorderb />
                    <BottomBtnsRight>
                        <BtnMdSubmit>저장</BtnMdSubmit>
                    </BottomBtnsRight>
                </form>
                {/* 메모: 시안에 목록, 등록/수정, 삭제 버튼이 있는 고정 하단바가 있는데 form태그 하나에 다 묶어야할까요? 저장이 따로 있어서 각자 그룹짓긴했는데 */}
            </SysContainer>
            {/* 대리점 검색 모달 */}
            <Modal
                style={selectManagerModalStyle}
                isOpen={modalIsOpen}
                onRequestClose={handleModalClose}
            >
                <BtnModalClose onClick={handleModalClose}>
                    <img src={icClear} alt="모달 닫기" />
                </BtnModalClose>
                <Fz20Bold $mb="1.538rem">대리점 검색</Fz20Bold>
                <form onSubmit={handleManagerSearch}>
                    <SearchWrapper>
                        <input
                            type="text"
                            placeholder="대리점명 / 담당자 이름,연락처,아이디를 입력해 주세요"
                            ref={managerSearchInputRef}
                        />
                        <button type="button" onClick={handleManagerSearch}>
                            <img src={icSearch} alt="찾기" />
                        </button>
                    </SearchWrapper>
                </form>
                {managerSearchText && !isFetching && managers && managers.docs.length > 0 ? (
                    <div style={{ height: '300px', overflow: 'scroll' }}>
                        {managers.docs.map((manager) => (
                            <ManagerItem
                                onClick={() =>
                                    setSelectedManager({
                                        id: manager._id,
                                        region: manager.region,
                                        name: manager.name,
                                        phone: manager.phone,
                                    })
                                }
                            >
                                <RadioBox
                                    name="salon"
                                    value={manager._id}
                                    checked={selectedManager.id === manager._id}
                                />
                                <label>
                                    <h5>{manager.name}</h5>
                                    <p>
                                        {manager.user_id} / {manager.phone}
                                    </p>
                                </label>
                            </ManagerItem>
                        ))}
                    </div>
                ) : managerSearchText ? (
                    <div style={{ height: '300px' }}>
                        <div
                            style={{
                                margin: '0',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                textAlign: 'center',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <FaSearch size={20} style={{ marginBottom: '10px' }} />
                            <br />
                            대리점을 찾을 수 없습니다.
                        </div>
                    </div>
                ) : (
                    <div style={{ height: '300px' }}>
                        <div
                            style={{
                                margin: '0',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                textAlign: 'center',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <FaSearch size={20} style={{ marginBottom: '10px' }} />
                            <br />
                            대리점을 검색해주세요.
                        </div>
                    </div>
                )}
                <ModalAbsolBtn>
                    <BtnFullModal
                        type="submit"
                        active={selectedManager.id}
                        disabled={!selectedManager.id}
                        onClick={() => {
                            if (selectedManager.id === '') {
                                toast.error('대리점을 선택해주세요.');
                                return;
                            }
                            setShopInfo({
                                ...shopInfo,
                                region: selectedManager.region,
                                manager_id: selectedManager.id,
                                manager_name: selectedManager.name,
                                manager_phone: selectedManager.phone,
                            });
                            handleModalClose();
                        }}
                    >
                        선택
                    </BtnFullModal>
                </ModalAbsolBtn>
            </Modal>
            {/* //대리점 검색 모달 */}
            <Modal>
                <Fz20Bold $mb="1.538rem">매장 검색</Fz20Bold>
                <SearchWrapper>
                    <input type="text" placeholder="근무 헤어샵명을 입력해 주세요" />
                    <button type="button">
                        <img
                            src="https://images.unsplash.com/photo-1669238309841-992f3b67eeb2?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            alt="찾기"
                        />
                    </button>
                </SearchWrapper>
            </Modal>
        </>
    );
}

const managerInputWrapStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '8px',
    border: '1px solid #e8eaed',
    backgroundColor: '#fff',
    width: '100%',
};

const selectManagerModalStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,.8)',
        zIndex: 20,
    },
    content: {
        overflowY: 'auto',
        top: '50%',
        bottom: 'unset',
        left: '50%',
        width: '100%',
        maxWidth: 430,
        height: 'auto',
        minHeight: 540,
        transform: 'translate(-50%,-50%)',
        transition: 'left 1s',
        padding: '3.846rem 2.3077rem 2.3077rem',
        border: 0,
        zIndex: 20,
    },
};

export default ShopNewForm;
