import { apiService } from "./api";

export const designerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getDesigners: builder.query({
      query: ({ page, limit, searchText, searchStatus }) => ({
        url: `/designers?page=${page}&text=${searchText || ''}&status=${searchStatus || ''}&limit=${limit}`,
        method: 'GET',
      })
    }),
    getDesigner: builder.query({
      query: id => ({
        url: `/designers/${id}`,
        method: 'GET',
      })
    }),
    createDesigner: builder.mutation({
      query: desinger => ({
        url: '/designers',
        method: 'POST',
        body: desinger,
      })
    }),
    updateDesigner: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/designers/${id}`,
        method: 'Put',
        body: patch,
      })
    }),
    updateDesignerDealer: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/designers/${id}/dealer`,
        method: 'Put',
        body: patch,
      })
    }),
    deleteDesigner: builder.mutation({
      query: id => ({
        url: `/designers/${id}`,
        method: 'DELETE',
      })
    }),
    approveDesigner: builder.mutation({
      query: id => ({
        url: `/designers/${id}/approve`,
        method: 'PUT',
      })
    }),
    disapproveDesigner: builder.mutation({
      query: id => ({
        url: `/designers/${id}/disapprove`,
        method: 'PUT',
      })
    }),
    applyFreeSubscription: builder.mutation({
      query: ({id, ...body}) => ({
        url: `/designers/${id}/subscription/free`,
        method: 'POST',
        body: body
      })
    })
  }),
});

export const { useGetDesignersQuery, useGetDesignerQuery, useApproveDesignerMutation, useDisapproveDesignerMutation, useApplyFreeSubscriptionMutation, useUpdateDesignerDealerMutation } = designerApi;