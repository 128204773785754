import { Navigate, useRoutes } from 'react-router-dom';
import Layout from '@screens/Layout';

import Login from '@screens/Login';
import Basic from '@screens/Basic';
import UserList from '@screens/User/UserList';
import UserEditForm from '@screens/User/UserEditForm';
import ManagerList from '@screens/Manager/ManagerList';
import ManagerEditForm from '@screens/Manager/ManagerEditForm';
import ManagerNewForm from '@screens/Manager/ManagerNewForm';
import DesignerList from '@screens/Designer/DesignerList';
import LicenseHistory from '@screens/License/LicenseHistory';
import ShopList from '@screens/Shop/ShopList';
import ShopNewForm from '@screens/Shop/ShopNewForm';
import ShopEditForm from '@screens/Shop/ShopEditForm';
import AdvertisementList from '@screens/Advertisement/AdvertisementList';
import AdvertisementNewForm from '@screens/Advertisement/AdvertisementNewForm';
import AdvertisementEditForm from '@screens/Advertisement/AdvertisementEditForm';
import ReviewList from '@screens/Review/ReviewList';
import NoticeList from '@screens/Notice/NoticeList';
import NoticeNewForm from '@screens/Notice/NoticeNewForm';
import NoticeEditForm from '@screens/Notice/NoticeEditForm';
import ArchiveList from '@screens/Archive/ArchiveList';
import ArchiveNewForm from '@screens/Archive/ArchiveNewForm';
import ArchiveEditForm from '@screens/Archive/ArchiveEditForm';
import QnaList from '@screens/Qna/QnaList';
import QnaEditForm from '@screens/Qna/QnaEditForm';
import FaqList from '@screens/Faq/FaqList';
import FaqNewForm from '@screens/Faq/FaqNewForm';
import FaqEditForm from '@screens/Faq/FaqEditForm';
import Terms from '@screens/Terms';
import PrivateRoute from '@components/PrivateRoute';
import { useSelector } from 'react-redux';
import { selectIsInitialized } from '@redux/slice/commonSlice';
import { selectuser } from '@redux/slice/authSlice';
import { selectUser } from './redux/slice/authSlice';

export const menus = [
  {
    name: '기본설정',
    path: '/basic',
    roles: ['ADMIN'],
    active: false
  },
  {
    name: '일반회원 관리',
    path: '/users',
    roles: ['ADMIN'],
    active: true,
  },
  {
    name: '대리점 관리',
    path: '/managers',
    roles: ['ADMIN'],
    active: true,
  },
  {
    name: '디자이너 관리',
    path: '/designers',
    roles: ['ADMIN'],
    active: true,
  },
  {
    name: '라이센스 이력 및 정산 관리',
    path: '/license-history',
    roles: ['ADMIN'],
    active: true,
  },
  {
    name: '매장 관리',
    path: '/shops',
    roles: ['ADMIN'],
    active: true,
  },
  // {
  //   name: '광고 관리',
  //   path: '/advertisements',
  //   roles: ['ADMIN'],
  //   active: true,
  // },
  {
    name: '메인 광고 관리',
    path: '/advertisements?typ=main',
    roles: ['ADMIN'],
    active: false,
  },
  {
    name: '제휴 광고 관리',
    path: '/advertisements/alliance',
    roles: ['ADMIN'],
    active: false,
  },
  {
    name: '디자이너 광고 관리',
    path: '/advertisements/designer',
    roles: ['ADMIN'],
    active: true,
  },
  {
    name: '매장 광고 관리',
    path: '/advertisements/shop',
    roles: ['ADMIN'],
    active: true,
  },
  {
    name: '리뷰 관리',
    path: '/reviews',
    roles: ['ADMIN'],
    active: true,
  },
  {
    name: '공지사항',
    path: '/notices',
    roles: ['ADMIN'],
    active: true,
  },
  {
    name: '아카이브',
    path: '/archives',
    roles: ['ADMIN'],
    active: true,
  },
  // {
  //   name: '문의관리',
  //   path: '/qnas',
  //   roles: ['ADMIN'],
  //   active: true,
  // },
  {
    name: 'FAQ',
    path: '/faqs',
    roles: ['ADMIN'],
    active: true,
  },
  {
    name: '약관 내용 관리',
    path: '/terms',
    roles: ['ADMIN'],
    active: false,
  },
]

function Router() {
  const isInitialized = useSelector(selectIsInitialized);
  const user = useSelector(selectUser);

  return useRoutes([
    !isInitialized && {
      path: '/login',
      element: !user ? <Login /> : <Navigate to={menus.filter(r=>r.active).find(r=>r.roles.includes(user?.role))?.path} />,
    },
    !isInitialized && {
      path: '/',
      element:  <PrivateRoute><Layout /></PrivateRoute>,
      children: [
        { path: '', element: <Navigate to={menus.filter(r=>r.active).find(r=>r.roles.includes(user?.role))?.path} /> },
        {
          path: 'users',
          element: <UserList />,
        },
        {
          path: 'users/:id/edit',
          element: <UserEditForm />,
        },
        {
          path: 'managers',
          element: <ManagerList />,
        },
        {
          path: 'managers/new',
          element: <ManagerNewForm />,
        },
        {
          path: 'managers/:id/edit',
          element: <ManagerEditForm />,
        },
        {
          path: 'designers',
          element: <DesignerList />,
        },
        {
          path: 'license-history',
          element: <LicenseHistory />,
        },
        {
          path: 'shops',
          element: <ShopList />,
        },
        {
          path: 'shops/new',
          element: <ShopNewForm />,
        },
        {
          path: 'shops/:id/edit',
          element: <ShopEditForm />,
        },
        {
          path: 'advertisements/:type',
          element: <AdvertisementList />,
        },        {
          path: 'advertisements/:type/new',
          element: <AdvertisementNewForm />,
        },
        {
          path: 'advertisements/:type/:id/edit',
          element: <AdvertisementEditForm />,
        },
        {
          path: 'reviews',
          element: <ReviewList />,
        },
        {
          path: 'notices',
          element: <NoticeList />,
        },
        {
          path: 'notices/new',
          element: <NoticeNewForm />,
        },
        {
          path: 'notices/:id/edit',
          element: <NoticeEditForm />,
        },
        {
          path: 'archives',
          element: <ArchiveList />,
        },
        {
          path: 'archives/new',
          element: <ArchiveNewForm />,
        },
        {
          path: 'archives/:id/edit',
          element: <ArchiveEditForm />,
        },,
        {
          path: 'qnas',
          element: <QnaList />,
        },
        {
          path: 'qnas/:id/edit',
          element: <QnaEditForm />,
        },
        {
          path: 'faqs',
          element: <FaqList />,
        },
        {
          path: 'faqs/new',
          element: <FaqNewForm />,
        },
        {
          path: 'faqs/:id/edit',
          element: <FaqEditForm />,
        },
        // {
        //   path: 'terms',
        //   element: <Terms />,
        // },
      ],
    },
    !isInitialized && { path: '*', element: <Navigate to='/users' /> }
  ]);
}

export default Router;
