import { configureStore } from "@reduxjs/toolkit";
import { apiService } from "../services/api";
import commonReducer from "./slice/commonSlice";
import authReducer from "./slice/authSlice";

export const store = configureStore({
  reducer: {
    [apiService.reducerPath]: apiService.reducer,
    common: commonReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiService.middleware),
});