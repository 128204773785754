import { apiService } from "./api";

const deshboardApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getCount: builder.query({
      query: () => ({
        url: '/dashboard/count',
        method: 'GET',
      })
    }),
  }),
});

export const { useGetCountQuery } = deshboardApi;